import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { MdAdd } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import Table from "../../../components/table/Table";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  get_cylinder_purchase_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { DeleteIcon, Radio } from "../../../utils/utils";
import PageLoading from "../../../components/PageLoading";
import SmallModal from "../../../components/modal/SmallModal";
import {
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import {
  get_equipment_list,
  get_staff_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import { cylinder_purchase_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import moment from "moment";
import NoData from "../../../components/NoData";
import {
  CVOList,
  EquipmentList,
} from "../../OtherComponent/StaticLists/staticLists";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { DateValidator2 } from "../../../utils/validation";
import { DropInput } from "../../../components/inputs/DropInput";
import { get_home_data } from "../../profile/UsersSlice";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";

const table_header = [
  "PRODUCT",
  "GST%",
  "ONE WAY/TWO WAY LOAD",
  "UNIT RATE ",
  "QUANTITY",
  "TAXABLE AMOUNT",
  "GST AMOUNT	",
  "TCS/TDS",
  "AMOUNT",
  "TRUCK NUMBER",
  "DRIVER NAME",
  "GODOWN SELECTION",
  "RECIEVED BY",
  "ACTIONS",
];

const CylinderPurchases = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open2data, setOpen2data] = useState("");
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [Equipmentlick, setEquipmentlick] = useState(false);
  const {
    cylinder_purchase_loading,
    cylinder_purchase_list,
    transition_complete_list,
  } = useSelector((state) => state.transactions);

  console.log("cylinder_purchase_list ========", cylinder_purchase_list);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const { equipment_list, staff_list, vehical_list } = useSelector(
    (state) => state.masterdata
  );
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  const { godown_address_list, day_end_date, home_data } = useSelector(
    (state) => state.profile
  );
  const { user_id, user_turnover, eff_date, omc_name, today } = useSelector(
    (state) => state.other
  );

  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_vehical_list());
  }, []);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  }

  const fetchData = () => {
    store.dispatch(
      get_cylinder_purchase_list(obj)
    );
  };
  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const onDelete_item = () => {
    setOpen(false);
    const data = cylinder_purchase_list?.filter((v) => v?.ID == id);

    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    // body.append("id", id);
    // body.append(
    //   "id[]",
    //   data?.[0]?.products?.forEach((v) => v?.ID)
    // );
    data?.[0]?.products?.forEach((product) => {
      if (product && product.ID) {
        body.append("id[]", product.ID);
      }
    });
    cylinder_purchase_api(body, setLoading).then((v) => {
      if (v.success) {
        if (cylinder_purchase_list?.length < 2) {
          setPageNo(pageNo - 1);
          store.dispatch(
            get_cylinder_purchase_list({
              pageNo: pageNo - 1,
              entries: entries,
              setTotalCount: setTotalCount,
              setSearchCount: setSearchCount,
              search: SerchInput,
            })
          );
        } else {
          store.dispatch(
            get_cylinder_purchase_list(obj)
          );
        }

        toast(v.message);
      } else {
        setMess(v.message);
        setOpen1(true);
      }
    });
  };
  const validation_check = () => {
    const arr = [];
    addData.find((v) => {
      if (!v?.p_code || (v?.p_code && v?.p_code?.length == 0)) {
        arr.push("Please Select a Product");
      }
      if (!v?.load || (v?.load && v?.load?.length == 0)) {
        arr.push("Please Select a Load Type");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length == 0)) {
        arr.push("Please Enter Unit Rate");
      }
      if (!v?.quantity || (v?.quantity && v?.quantity == 0)) {
        arr.push("Please Select Quantity");
      }
      if (
        !v?.tax_amount ||
        !v?.gst_amount ||
        !v?.amount ||
        !v?.tcs_tds ||
        (v?.tax_amount && v?.tax_amount?.length == 0) ||
        (v?.gst_amount && v?.gst_amount?.length == 0) ||
        (v?.amount && v?.amount?.length == 0) ||
        (v?.tcs_tds && v?.tcs_tds?.length == 0) ||
        !addDataHead?.inv_amount ||
        (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
      ) {
        arr.push("Please Click on Calculate first");
      }
      if (
        !v?.truck_number ||
        (v?.truck_number && v?.truck_number?.length == 0)
      ) {
        arr.push("Please Enter Truck Number");
      }
      if (!v?.emp_code || (v?.emp_code && v?.emp_code?.length == 0)) {
        arr.push("Please Select Received By");
      }
      if (!v?.godown || (v?.godown && v?.godown?.length == 0)) {
        arr.push("Please Select Godown Address");
      }
    });
    return arr;
  };
  const validation_check1 = async () => {
    const arr = [];
    if (
      !addDataHead?.name ||
      (addDataHead?.name && addDataHead?.name?.length == 0)
    ) {
      arr.push("Please Select OMC Plant");
    }
    if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && addDataHead?.ref_no?.trim()?.length == 0)
    ) {
      arr.push("Please Enter Invoice Number");
    } else if (addDataHead?.ref_no?.trim()?.length < 8) {
      arr.push("Invoice Number must contain atleast 8 Characters");
    }
    const data = await DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice Date cant be below  as Day end Date ${day_end_date}`,
      "Invoice Date cant be a future date"
    );
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      arr.push("Please Enter Invoice Date");
    } else if (data != true) {
      arr.push(...data);
    }
    if (
      !addDataHead?.stock_date ||
      (addDataHead?.stock_date && addDataHead?.stock_date?.length == 0)
    ) {
      arr.push("Please Enter Stock Receipt Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.stock_date).format("DD/MM/YYYY"),
        today,
        moment(addDataHead?.inv_date).format("DD/MM/YYYY")
      ) != true &&
      addDataHead?.inv_date?.length == 10
    ) {
      arr.push(
        ...DateValidator2(
          moment(addDataHead?.stock_date).format("DD/MM/YYYY"),
          today,
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          "INVOICE DATE must be earlier than STOCK RECEIPT DATE.",
          "STOCK RECEIPT DATE cant be a future date."
        )
      );
    }
    return arr;
  };
  const add_items = async (type) => {
    const errorData = [
      ...(await validation_check1()),
      ...(await validation_check()),
    ];
    if (
      errorData?.length == 0 &&
      Number(home_data?.bank[0]?.ACC_CB) < Number(addDataHead.inv_amount) &&
      !type
    ) {
      setOpen3(true);
      return;
    } else {
      setOpen3(false);
    }
    if (errorData?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("omc_id", addDataHead?.name);
        body.append("ref_no", addDataHead?.ref_no?.trim());
        body.append("inv_date", addDataHead?.inv_date);
        body.append("recp_date", addDataHead?.stock_date);
        body.append("connection_type", addDataHead?.c_type);
        body.append(
          "tcs_flag",
          addDataHead?.type == "TDS"
            ? "1"
            : addDataHead?.type == "TCS"
            ? "2"
            : "0"
        );
        addData.forEach((v) => {
          body.append("procode[]", v?.p_code);
          body.append("gst[]", v?.p_gst);
          body.append("load_type[]", v?.load);
          body.append("unit_rate[]", v?.unit_rate);
          body.append("quantity[]", v?.quantity);
          body.append("tax_amount[]", v?.tax_amount);
          body.append("amount[]", v?.amount);
          body.append("gst_amount[]", v?.gst_amount);
          body.append("truck_number[]", v?.truck_number);
          body.append(
            "truck_number_id[]",
            vehical_list?.filter(
              (val) => v?.truck_number == val?.VEHICLE_NO
            )?.[0]?.ID || 0
          );
          body.append("driver_name[]", v?.driver_name);
          body.append("tds[]", v?.tcs_tds);
          body.append("godown[]", v?.godown);
          body.append("received_by[]", v?.emp_code);
        });

        cylinder_purchase_api(body, setLoading).then((res) => {
          if (res.success) {
            setIsAdd(false);
            store.dispatch(
              get_cylinder_purchase_list(obj)
            );
            setAddData([]);
            setAddDataHead({});
            toast(res.message);
            store.dispatch(get_home_data(user_id));
          } else {
            setMess(res.message);
            setOpen1(true);
          }
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={errorData} />);
      setOpen1(true);
    }
  };
  const calculate = () => {
    if (!addDataHead.name) {
      setMess("Please select OMC");
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) =>
          !v.load ||
          (v.load && v.load.length == 0) ||
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          !v.quantity ||
          (v.quantity && v.quantity.length == 0)
      )
    ) {
      setMess(`Please Select ONE WAY or TWO WAY LOAD
Please Enter UNIT RATE.
Please Enter QUANTITY.`);
      setOpen1(true);
      return;
    }
    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data?.unit_rate);
      const quantity = Number(data?.quantity);
      const p_gst = Number(data?.p_gst);

      const tax_amount = unit_rate * quantity;
      const gst_amount = tax_amount * (p_gst / 100);
      const tds_amt = addDataHead?.type == "NA" ? 0 : tax_amount * (0.1 / 100);
      const amount =
        addDataHead?.type == "TDS"
          ? gst_amount + tax_amount - tds_amt
          : addDataHead?.type == "TCS"
          ? gst_amount + tax_amount + tds_amt
          : gst_amount + tax_amount;

      setAddData((prevData) => {
        return prevData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              tax_amount: tax_amount.toFixed(2),
              gst_amount: gst_amount.toFixed(2),
              amount: amount.toFixed(2),
              tcs_tds: Number(tds_amt).toFixed(2),
            };
          }
          return item;
        });
      });
      total += amount;
    });
    setAddDataHead({ ...addDataHead, inv_amount: Number(total).toFixed(2) });
  };

  return (
    <div className="container">
      <PageHeading
        title={"CYLINDERS PURCHASE INVOICE ENTRY"}
        right={
          <>
            <Button
              title={"ADD"}
              icon={<MdAdd size={20} color="#fff" />}
              onClick={() => {
                setIsAdd(true);
                setAddData((prevData) => [...prevData, {}]);

                setAddDataHead({});
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/8_iHCWoYhGA",
                "https://youtu.be/JxgC-j3W6ak",
              ]}
            />
          </>
        }
      />
      <Pagination3
        top={true}
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {cylinder_purchase_loading ? (
          <PageLoading />
        ) : cylinder_purchase_list?.length == 0 ? (
          <NoData mt={20} />
        ) : (
          <>
            <div className="grid">
              {cylinder_purchase_list?.map((v, i) => (
                <div className="card" key={i}>
                  <div>
                    <div className="card-heading">{v?.cvo_name}</div>
                    <RowTxt title={"Invoice No."} value={v?.INV_REF_NO} />
                    <RowTxt
                      title={"Invoice Date."}
                      value={moment(v?.invoice_date).format("DD-MMM-YYYY")}
                    />
                    <RowTxt
                      title={"Stock Recieve Date"}
                      value={moment(v?.stock_receive_date).format(
                        "DD-MMM-YYYY"
                      )}
                    />
                    <RowTxt
                      title={"TDS/TCS"}
                      value={
                        v?.TCS_FLAG == "1"
                          ? "TDS"
                          : v?.TCS_FLAG == "2"
                          ? "TCS"
                          : "NA"
                      }
                    />
                  </div>
                  <div className="df jcsb mt10">
                    {v?.STATUS_FLAG == "0" ? (
                      <DeleteIcon
                        onClick={() => {
                          setId(v?.ID);
                          setOpen(true);
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 1.05 }}
                      className="flex aic gap10 jce cursor-pointer"
                    >
                      <div
                        className="view-btn"
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                      >
                        View
                      </div>
                      <HiOutlineArrowRight
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                        size={20}
                        color="#1b64af"
                      />
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead([]);
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                onClick={() => setCvoClick(!cvoClick)}
                back
              />
              <Button
                title={"EQUIPMENT"}
                onClick={() => setEquipmentlick(!Equipmentlick)}
                back
              />
            </div>
          }
          title={"CYLINDERS PURCHASE INVOICE ENTRY"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Dropdown
                  width2=""
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    transition_complete_list?.OMC?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.CVO_CAT + "-" + omc_name + "-" + v?.cvo_name}
                      </option>
                    ))
                  )}
                  label={"OMC PLANT"}
                  value={addDataHead.name}
                  onChange={(e) => {
                    const data = transition_complete_list?.OMC?.filter(
                      (v) => v.ID == e.target.value
                    )[0];
                    setAddDataHead({
                      ...addDataHead,
                      name: e.target.value,
                      c_type: e.target.value == "" ? "" : data?.connection_type,
                      dis: e.target.value == "" ? false : true,
                      type: e.target.value != "" ? "TDS" : "",
                      // user_turnover < 3 && data?.ANNUAL_TURNOVER == 0
                      //   ? "NA"
                      //   : user_turnover >= 3 && data?.ANNUAL_TURNOVER == 1
                      //   ? "TDS"
                      //   : user_turnover >= 3 && data?.ANNUAL_TURNOVER == 0
                      //   ? "TDS"
                      //   : user_turnover < 3 && data?.ANNUAL_TURNOVER == 1
                      //   ? "TCS"
                      //   : "NA",
                    });

                    addData.forEach((data, index) => {
                      setAddData((prevData) => {
                        return prevData.map((item, i) => {
                          if (i === index) {
                            return {
                              ...item,
                              tax_amount: "",
                              gst_amount: "",
                              amount: "",
                              tcs_tds: "",
                            };
                          }
                          return item;
                        });
                      });
                    });
                  }}
                />
                <Input
                  label={"INV REF NO"}
                  placeholder={"INV REF NO"}
                  value={addDataHead?.ref_no}
                  onChange={(e) => {
                    const input = e.target.value?.toUpperCase();
                    // const invRegex = /^[A-Z\d!@#$%^&*-=+.,/?><;:'"]{0,8}$/;
                    // if (input.length <= 8 && invRegex.test(input)) {
                    //   setAddDataHead({
                    //     ...addDataHead,
                    //     ref_no: input,
                    //   });
                    // }
                    setAddDataHead({
                      ...addDataHead,
                      ref_no: input,
                    });
                  }}
                />
                <Input
                  label={"INV DATE"}
                  type={"date"}
                  value={addDataHead.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"STOCK RECEIPT DATE"}
                  type={"date"}
                  value={addDataHead.stock_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        stock_date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"INV AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead.inv_amount}
                />
              </div>
              <div className="flex aic gap10 mt10">
                <RadioBtn label={"PURCHASE TYPE"} />
                <Radio
                  disabled={addDataHead?.dis}
                  title={"LOCAL PURCHASE"}
                  show={addDataHead?.c_type == "lp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "lp" })
                  }
                />
                <Radio
                  disabled={addDataHead?.dis}
                  title={"INTER-STATE PURCHASE"}
                  show={addDataHead.c_type == "isp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "isp" })
                  }
                />
              </div>
              <div
                className="flex aic gap10"
                style={{
                  width: "600px",
                  flexWrap: "wrap",
                  rowGap: "0",
                  margin: "1rem 0",
                }}
              >
                <RadioBtn label={"TDS/TCS"} />
                <Radio
                  title={"TDS"}
                  // disabled={addDataHead?.dis}
                  show={addDataHead?.type == "TDS" ? "TDS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TDS");
                  }}
                />
                <Radio
                  // disabled={addDataHead?.dis}
                  title={"TCS"}
                  show={addDataHead?.type == "TCS" ? "TCS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TCS");
                  }}
                />
                <Radio
                  // disabled={addDataHead?.dis}
                  title={"NA"}
                  show={addDataHead?.type == "NA" ? "NA" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("NA");
                  }}
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={table_header}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            options={[
                              <option value={""}>{"SELECT"}</option>,
                            ].concat(
                              uniqueEquipmentList.map((val, ind) => (
                                <>
                                  <option
                                    disabled={
                                      addData?.filter(
                                        (v) => v?.p_code == val.PROD_CODE
                                      )?.length > 0
                                    }
                                    value={val.PROD_CODE}
                                    key={ind}
                                  >
                                    {val.CAT_NAME + " - " + val.product_name}
                                  </option>
                                </>
                              ))
                            )}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        p_code: e.target.value,
                                        p_gst: uniqueEquipmentList.filter(
                                          (val, ind) =>
                                            val.PROD_CODE == e.target.value
                                        )[0]?.GSTP,
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val.p_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val.p_gst}
                            placeholder={"GST%"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option>SELECT</option>
                                <option value={"2"}>TWO WAY LOAD</option>
                                <option value={"1"}>ONE WAY LOAD</option>
                              </>
                            }
                            value={val.load}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, load: e.target.value }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"UNIT RATE"}
                            value={val?.unit_rate}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.unit_rate
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          unit_rate:
                                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tcs_tds: "",
                                          gst_amount: "",
                                          tax_amount: "",
                                          amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"QUANTITY"}
                            value={val.quantity}
                            onChange={(e) => {
                              if (e.target.value?.length < 5) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          quantity:
                                            /^\d+$/.test(e.target.value) ||
                                            e.target.value == ""
                                              ? Number(e.target.value)
                                              : "",
                                          tcs_tds: "",
                                          gst_amount: "",
                                          tax_amount: "",
                                          amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>

                      <td>
                        {
                          <Input
                            placeholder={"TAXABLE AMOUNT"}
                            value={val.tax_amount}
                            disabled={true}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, tax_amount: e.target.value }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"GST AMOUNT"}
                            value={val.gst_amount}
                            disabled={true}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, gst_amount: e.target.value }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TCS/TDS"}
                            value={val?.tcs_tds}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"AMOUNT"}
                            value={val?.amount}
                            disabled={true}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, amount: e.target.value }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        <DropInput
                          placeholder={"TRUCK NUMBER"}
                          value={val?.truck_number}
                          id={"truck"}
                          onChange={(event) => {
                            const input = event.target.value.toUpperCase();
                            if (
                              /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/.test(
                                input
                              ) ||
                              /^[0-9]{0,4}$/.test(input) ||
                              input === ""
                            ) {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, truck_number: input }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          list={vehical_list?.map((v, i) => (
                            <option key={i} value={v?.VEHICLE_NO}>
                              {v?.VEHICLE_NO}
                            </option>
                          ))}
                        />
                      </td>
                      <td>
                        <DropInput
                          id={"driver"}
                          placeholder={"DRIVER NAME"}
                          value={val?.driver_name}
                          onChange={(event) => {
                            const input = event.target.value;
                            if (/^[a-zA-Z0-9 ]*$/.test(input) || input === "") {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, driver_name: input }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          list={staff_list?.map((v, i) => (
                            <option key={i} value={v?.EMP_NAME}>
                              {v?.EMP_NAME}
                            </option>
                          ))}
                        />
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={[
                              <option value={""}>{"SELECT"}</option>,
                            ].concat(
                              godown_address_list?.map((v, i) => (
                                <option key={i} value={v.ID}>
                                  {v.ADDRESS1}
                                </option>
                              ))
                            )}
                            value={val.godown}
                            onChange={(event) => {
                              const input = event.target.value.toUpperCase();
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind ? { ...v, godown: input } : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={[
                              <option value={""}>{"SELECT"}</option>,
                            ].concat(
                              staff_list?.map((v, i) => (
                                <option key={i} value={v?.ID}>
                                  {v?.EMP_NAME}
                                </option>
                              ))
                            )}
                            value={val.emp_code}
                            onChange={(event) => {
                              const input = event.target.value.toUpperCase();
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, emp_code: input }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    const data = validation_check();
                    if (addData?.length <= 3) {
                      data?.length > 0 &&
                        setMess(
                          "Please enter all the values in current row,calculate and then add next row "
                        );
                      data?.length > 0
                        ? setOpen1(true)
                        : setAddData((pre) => [...pre, {}]);
                    } else {
                      setOpen1(true);
                      setMess("you can add only 4 product");
                    }
                  }}
                />
                <Button
                  title={"CALCULATE"}
                  back
                  disable={
                    !addData[addData.length - 1]?.p_code ||
                    !addData[addData.length - 1]?.p_gst
                      ? true
                      : false
                  }
                  onClick={() =>
                    !addData[addData.length - 1]?.p_code ||
                    !addData[addData.length - 1]?.p_gst
                      ? ""
                      : calculate()
                  }
                />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {open2 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={"Are You Sure you want to Change financial Status ?"}
          bname={"SUBMIT"}
          onClick={() => {
            setAddDataHead({ ...addDataHead, type: open2data });
            setOpen2(false);
            setOpen2data("");
            addData.forEach((data, index) => {
              setAddData((prevData) => {
                return prevData.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      tax_amount: "",
                      gst_amount: "",
                      amount: "",
                      tcs_tds: "",
                    };
                  }
                  return item;
                });
              });
            });
          }}
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}

      {Equipmentlick && (
        <EquipmentList
          EquipClick={Equipmentlick}
          setEquipClick={setEquipmentlick}
        />
      )}
      {open3 && (
        <SmallModal
          bname={"SUBMIT"}
          title={"Confirm"}
          isOpen={open3}
          setIsOpen={setOpen3}
          mess={
            "YOUR LOAD ACCOUNT HAVE NO SUFFICIENT BALANCE TO COMPLETE THIS TRANSACTION. DO YOU WANT TO CONTINUE?"
          }
          onClick={() => {
            add_items(true);
          }}
        />
      )}
      {openView && (
        <Modal
          isOpen={openView}
          title={viewItem?.cvo_name}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "ONE WAY/TWO WAY LOAD",
                  "TRUCK NUMBER",
                  "DRIVER NAME",
                  "GODOWN SELECTION",
                  "RECIEVED BY",
                  "UNIT PRICE	",
                  "QUANTITY",
                  "GST%",
                  "TAXABLE AMOUNT	",
                  "TDS/TCS",
                  "IGST",
                  "SGST",
                  "CGST",
                  "TOTAL AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.CAT_Name + "-" + v?.CAT_DESC}</td>
                        <td>
                          {v?.EMR_OR_DELV == "1"
                            ? "ONE WAY LOAD"
                            : "TWO WAY LOAD"}
                        </td>
                        <td>{v?.TRUCK_NUMBER}</td>
                        <td>{v?.DRIVER_NAME}</td>
                        <td>
                          {
                            // godown_address_list?.filter(
                            //   (val) => val?.ID == v?.GODOWN_NAME
                            // )?.[0]?.UNIT_NAME
                            v?.GODOWN_NAME
                          }
                        </td>
                        <td>{v?.EMP_NAME}</td>
                        <td>{Number(v?.UNIT_PRICE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{v?.GSTP}</td>
                        <td>{v?.BASIC_AMOUNT}</td>
                        <td>{Number(v?.TCS_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                        <td>{v?.C_AMOUNT}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7} />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.BASIC_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.TCS_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.C_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {loading && <Loader />}
    </div>
  );
};

export default CylinderPurchases;
