import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import Table from "../../components/table/Table";
import "./trasactionReport.css";
import { useSelector } from "react-redux";
import { get_nc_dbc_report_list } from "./transactionReportSlice";
import store from "../../redux/store";
import { DateValidator2 } from "../../utils/validation";
import moment from "moment";
import { nc_dbc_report_api } from "../../utils/apis";
import { Pagination2 } from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import Loader from "../../components/Loader";
import { downloadExcel, exportPDF } from "../../utils/utils";
import SmallModal from "../../components/modal/SmallModal";
import { ncData } from "../../utils/data";
import { Alerts } from "../../components/Alerts/Alerts";

const NDRTReport = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [nc_dbc_report_item_list, setNc_dbc_report_item_list] = useState([]);
  const [totalData, setTotalData] = useState({});
  const { eff_date, user_id, today } = useSelector((state) => state.other);

  useEffect(() => {
    store.dispatch(get_nc_dbc_report_list());
  }, []);

  const { nc_dbc_report_list } = useSelector(
    (state) => state.transactionReport
  );
  const { firm_name } = useSelector((state) => state.profile);

  const uniqueEquipmentList = nc_dbc_report_list?.productlist.reduce((acc, current) => {
    const duplicate = acc.find(item => item.CAT_NAME === current.CAT_NAME && item.CAT_DESC === current.CAT_DESC);
    if (!duplicate) {
      acc.push(current);
    }
    return acc;
  }, []);

  useEffect(() => {
    if (showData) {
      get_list(addDataPre);
    }
  }, [pageNo, entries]);

  const table_header = [
    "INVOICE NO",
    "TRANSACTION DATE",
    "CONNECTION TYPE  ",
    "PRODUCT",
    "NUMBER OF CONNECTIONS",
    "NUMBER OF CYLINDERS",
    "NUMBER OF REGULATORS",
    "TOTAL SECURITY DEPOSIT",
  ];


  const table_data = (addData, total, ncData) => {
    const data = [
        ...addData?.flatMap((v, i) =>
            v?.details?.map((val, ind) => [
                `${i + 1}.${ind + 1}`,
                v?.SR_NO,
                v?.INV_DATE,
                ncData?.find((f) => f?.value == v?.CONN_TYPE)?.label_data,
                `${val?.CAT_NAME} - ${val?.CAT_DESC}`,
                v?.NO_OF_CONNS,
                v?.NO_OF_CYL,
                v?.NO_OF_REG,
                val?.SECURITY_DEPOSIT,
            ])
        ),
        [
            "",
            "",
            "",
            "",
            "",
            "TOTAL",
            total?.quantity,
            total?.sgst,
            total?.inv_amount,
        ],
    ];

    return data;
};

  // const table_data = (addData, total) => {

  //   const data = [
  //     ...addData?.map((v, i) => (
  //       v?.details?.map((val,ind)=>[
  //           i + 1,
  //           v?.SR_NO,
  //           v?.INV_DATE,
  //           ncData?.filter((f) => f?.value == v?.CONN_TYPE)?.[0]?.label_data,
  //           `${val?.CAT_NAME} - ${val?.CAT_DESC}`,
  //           v?.NO_OF_CONNS,
  //           v?.NO_OF_CYL,
  //           v?.NO_OF_REG,
  //           v?.SECURITY_DEPOSIT,
  //       ])
  //     )),
  //     [
  //       "",
  //       "",
  //       "",
  //       "",
  //       "",
  //       "TOTAL",
  //       total?.quantity,
  //       total?.sgst,
  //       total?.inv_amount,
  //     ],
  //   ];

  //   return data;
  // };

  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - NC  DBC  RC  TV REPORT -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / CONNECTION TYPE SELECTED : : ${
        addData?.custProdDetails
      }`,
      tableName: `${firm_name} - NC  DBC  RC  TV REPORT -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / CONNECTION TYPE SELECTED : : ${
        addData?.custProdDetails
      }`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `${firm_name} - NC  DBC  RC  TV REPORT -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / CONNECTION TYPE SELECTED : : ${
        addData?.custProdDetails
      }`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append(
          "action",
          addData?.action?.length == 0 || !addData?.action
            ? "alldata"
            : addData?.action || ""
        );

        body.append("agencyid", user_id);
        body.append("sdate", addData?.from_date);
        body.append("edate", addData?.to_date);
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        if (addData?.action == "conntype") {
          body.append("conn_type", addData?.connection_type);
        } else if (addData?.action == "product") {
          body.append("prod_cat", addData?.product_code);
        }
        const response = await nc_dbc_report_api(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else {
            setNc_dbc_report_item_list(response?.data || response?.productlist);
            setTotalCount(response?.totalcount);
            setShowData(true);
            setAddDataPre({
              ...addData,
              custProdDetails:
                addData?.action == "conntype"
                  ? response?.conntype
                  : addData?.custProdDetails,
            });
            setTotalData(response?.total);
          }
        } else if (!response.success) {
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      DateValidator2(
        moment(addData?.from_date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.from_date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `From Date Date cant be before ${moment(eff_date).format(
            "DD/MM/YYYY"
          )} `,
          `From Date Date cant be a future date`
        )
      );
    }
    if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (
      DateValidator2(
        moment(addData?.to_date).format("DD/MM/YYYY"),
        today,
        addData?.from_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.to_date).format("DD/MM/YYYY"),
          today,
          addData?.from_date,
          `To Date cant be before ${moment(addData?.from_date).format(
            "DD/MM/YYYY"
          )} `,
          `To Date cant be a future date`
        )
      );
    }
    // if (
    //   (!addData?.product_code ||
    //     (addData?.product_code && addData?.product_code?.length == 0)) &&
    //   (!addData?.connection_type ||
    //     (addData?.connection_type && addData?.connection_type?.length == 0))
    // ) {
    //   data.push("Please Select Either PRODUCT / CONNECTION TYPE");
    // } else
    if (
      addData?.product_code?.length > 0 &&
      addData?.connection_type?.length > 0
    ) {
      data.push("Please Select Either PRODUCT / CONNECTION TYPE");
    }
    return data;
  };

  return (
    <div className="container ">
      <PageHeading title={"NC/DBC/RC/TV REPORT"} right={<BackButton />} />
      <div className="transactionMain">
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            type={"date"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
          />
        </div>

        <div style={{ width: "220px" }}>
          <Dropdown
            label={"PRODUCT"}
            value={addData?.product_code}
            onChange={(e) => {
              const data = uniqueEquipmentList?.filter(
                (v) => v?.ID == e.target.value
              )?.[0];

              setAddData({
                ...addData,
                product_code: e.target.value,
                action:
                  e.target.value != ""
                    ? "product"
                    : addData?.connection_type?.length > 0
                    ? "conntype"
                    : "",
                product_cat: data ? data?.CAT_TYPE : "",
                custProdDetails: data
                  ? data?.CAT_NAME == "DOMESTIC" ||
                    data?.CAT_NAME == "COMMERCIAL" ||
                    data?.CAT_NAME == "REGULATOR"
                    ? `${data?.CAT_NAME} - ${data?.CAT_DESC}`
                    : `${data?.CAT_DESC} - ${data?.PROD_BRAND} - ${data?.PROD_NAME}`
                  : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                {uniqueEquipmentList?.map((v, i) => (
                  <option key={i} value={v?.ID}>
                    {v?.CAT_NAME} - {v?.CAT_DESC}
                  </option>
                ))}
              </>
            }
          />
        </div>
        <div style={{ width: "220px" }}>
          <Dropdown
            label={"CONNECTION TYPE"}
            value={addData?.connection_type}
            onChange={(e) => {
              setAddData({
                ...addData,
                connection_type: e.target.value,
                action:
                  e.target.value != ""
                    ? "conntype"
                    : addData?.product_code?.length > 0
                    ? "product"
                    : "",
                custProdDetails: "New Product",
              });
            }}
            options={
              <>
                <option value="">SELECT</option>
                {ncData?.map((v, i) => (
                  <React.Fragment key={i}>
                    {v?.dis_data && (
                      <option disabled={true}>
                        ----------------{v?.dis_data}----------------
                      </option>
                    )}
                    <option value={v?.value}>{v?.label_data}</option>
                  </React.Fragment>
                ))}
              </>
            }
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button title={"SEARCH"} 
          // onClick={() => get_list(addData)} 
           onClick={async () => {
          await setShowData(false);
          await setPageNo(1);
          await setEntries(5);
          get_list(addData, "main");
        }}
          />
        </div>
      </div>
      {showData && nc_dbc_report_item_list?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>
            {(addDataPre?.product_code || addDataPre?.connection_type) && (
              <>
                <span style={{ fontSize: "11PX" }}>PRODUCT / CONNECTION TYPE SELECTED:</span>
                <h6 className="PDS">{addDataPre?.custProdDetails}</h6>
              </>
            )}
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {nc_dbc_report_item_list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <Table
              headData={table_header}
              body={
                <>
                  {nc_dbc_report_item_list?.map((v, i) => (
                    <tr key={i}>
                      <td>{v?.SR_NO}</td>
                      <td>{moment(v?.INV_DATE || v?.TV_DATE).format("DD-MMM-YYYY")}</td>
                      <td>
                        {
                          ncData?.filter((f) => f?.value == v?.CONN_TYPE)?.[0]
                            ?.label_data || v?.CONN_TYPE
                        }
                      </td>
                      <td>
                        {v?.CAT_NAME} - {v?.CAT_DESC}
                      </td>
                      <td>{v?.NO_OF_CONNS || "-"}</td>
                      <td>{v?.NO_OF_CYL}</td>
                      <td>{v?.NO_OF_REG}</td>
                      <td>{v?.SECURITY_DEPOSIT}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>Total</td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {totalData?.sgst}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}></td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {totalData?.inv_amount}
                    </td>
                  </tr>
                </>
              }
            />
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}
      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default NDRTReport;
