import React from "react";
import styles from "./tax.module.css";
import Indane from "../../../assets/Indane.png";
import qrcode from "../../../assets/qrcode.jpeg";
import { useSelector } from "react-redux";

const EInvoice = ({ previewRef }) => {
  const { profile_data } = useSelector((state) => state.profile);
  console.log("profile_data ===",profile_data);

  return (
    <div
      className={styles.main_div_container}
      style={{ margin: 0, width: "100%", borderBottom: "none" }}
    >
      <div className={styles.env_1}>
        <div className={styles.env_1_data}>
          <img src={Indane} alt="sdsd" style={{ maxWidth: "150px" }} />
        </div>
        <div className={styles.env_1_data_2}>
          <div className={styles.comp_details}>
            {profile_data?.name?.toUpperCase()}
          </div>
          <div className={styles.comp_details}>   {profile_data.ADDRESS}, {profile_data?.LOCATION},
          {profile_data.DISTRICT}, {profile_data?.PINCODE}</div>
          {/* <div className={styles.comp_details}>Medchal</div> */}
          <div className={styles.comp_details}> GSTIN/UIN: {profile_data?.gst}</div>
          <div className={styles.comp_details}>PH:{profile_data?.mobile},{profile_data?.landline}</div>
          <div className={styles.comp_details}>E-Mail : {profile_data?.email}</div>
        </div>
        <div className={styles.env_1_data_3}>
          <img src={qrcode} alt="sdsd" style={{ maxWidth: "100px" }} />
        </div>
      </div>
    </div>
  );
};

export default EInvoice;

// import React from "react";
// import styles from "./tax.module.css";
// import Indane from "../../../assets/Indane.png";

// const EInvoice = ({ previewRef }) => {
//   return (
//     <div ref={previewRef} className={styles.env_main}>
//       <div className={styles.out_side}>
//         <div>30/07/2024, 18:47 </div>
//         <div style={{ fontWeight: "bold" }}>E-INVOICE</div>
//       </div>
//       <div className={styles.main_div_container}>
//         <div className={styles.env_1}>
//           <div className={styles.env_1_data}>
//             <img src={Indane} alt="sdsd" style={{ maxWidth: "150px" }} />
//           </div>
//           <div className={styles.env_1_data_2}>
//             <div className={styles.comp_details}>
//               SRI RAMALINGESWARA ENTERPRISES
//             </div>
//             <div className={styles.comp_details}>36AGNPR7325Q1ZF</div>
//             <div className={styles.comp_details}>Medchal</div>
//           </div>
//           <div className={styles.env_1_data_3}>
//             <img src={Indane} alt="sdsd" style={{ maxWidth: "150px" }} />
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div className={styles.env_2_1} style={{ borderBottom: "none" }}>
//             1.e-Invoice Details
//           </div>
//           <div className={styles.env_2_2}>
//             <div>
//               IRN :
//               1e1a881c5a8b64330ccb7232d821e65e0d2570daf0203af891061371828022dc
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>Ack. No : 112421099909157</div>
//               <div>Ack. Date : 17-JUL-2024</div>
//             </div>
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div className={styles.env_2_1} style={{ borderBottom: "none" }}>
//             1.e-Invoice Details
//           </div>
//           <div className={styles.env_2_2}>
//             <div className={styles.env_2_2_2}>
//               <div>Supply Type Code : B2B</div>
//               <div>Document No : SI-24-0668</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>Place of Supply : TELANGANA</div>
//               <div></div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>Document Type : INV </div>
//               <div>Document Date : 17-JUL-2024</div>
//             </div>
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div className={styles.env_2_1} style={{ borderBottom: "none" }}>
//             1.e-Invoice Details
//           </div>
//           <div className={styles.env_2_2}>
//             <div className={styles.env_2_2_2}>
//               <div>Seller:</div>
//               <div>Purchaser:</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>GSTIN : 36AGNPR7325Q1ZF</div>
//               <div>GSTIN : 36AGNPR7325Q1ZF</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>SRI RAMALINGESWARA ENTERPRISES</div>
//               <div>SRI RAMALINGESWARA ENTERPRISES</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>3-56/9/7, MAIN ROAD KEESARA 501301 TELANGANA</div>
//               <div>3-56/9/7, MAIN ROAD KEESARA 501301 TELANGANA</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>9553226856</div>
//               <div>9553226856</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>jaganmohanreddy.kalluri@gmail.com</div>
//               <div>jaganmohanreddy.kalluri@gmail.com</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>Dispatcher: Same as above</div>
//               <div> Shipping: GSTIN : 36AAACI6620F1ZV</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div></div>
//               <div>INDIAN IMMUNOLOGICALS LTD</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div></div>
//               <div>
//                 survey No.281-284&321, Biotech Park Phase-III, Karakpatla
//                 Village, Mulugu Mandal. 502281 TELANGANA
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div className={styles.env_2_1} style={{ borderBottom: "none" }}>
//             4.Goods Details
//           </div>
//           <div
//             className={styles.env_2_1}
//             style={{ borderTop: "none", padding: "3px 1rem " }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                   <th className={styles.env_th_td}>hello</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                   <td className={styles.env_th_td}>hello</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div className={styles.env_6_1}>
//             <div
//               style={{
//                 flex: 2,
//                 border: "none",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "space-between",
//               }}
//             >
//               <div>
//                 TOTAL INVOICE AMOUNT (In Words) : THREE LAKH THIRTY FIVE
//                 THOUSAND SEVENTY NINE RUPEES ONLY
//               </div>
//               <div className={styles.env_6_1_2}>
//                 <div>Bank Details:</div>
//                 <div className={styles.env_6_1_2_1}>
//                   <table>
//                     <tr>
//                       <th>Bank Name </th>
//                       <th>Account No </th>
//                       <th> IFSC Code</th>
//                       <th> Branch Name</th>
//                     </tr>
//                     <tr>
//                       <td>ICICI BANK LIMITED</td>
//                       <td>179305006691</td>
//                       <td>ICIC0001793</td>
//                       <td>GHATKESAR</td>
//                     </tr>
//                   </table>
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 flex: 1,
//                 border: "none",
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               <div className={styles.env_6_1_2_1}>
//                 <table>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       TAXABLE: 283965.20{" "}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       IGST: 0.00
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       CGST: 25556.87
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       SGST: 25556.87
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       DISCOUNT: 0.00
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       style={{
//                         textAlign: "left",
//                         padding: "0 4px",
//                         fontSize: "14px",
//                       }}
//                     >
//                       TOTAL: 335079.00
//                     </td>
//                   </tr>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className={styles.env_2}>
//           <div>
//             <div className={styles.env_2_2_2}>
//               <div>Generated By : 36AGNPR7325Q1ZF</div>
//               <div>E-sign</div>
//             </div>
//             <div className={styles.env_2_2_2}>
//               <div>Print Date : 30-JUL-2024 18:47:38</div>
//               <div>Digitally Signed by NIC-IRP on: 17-JUL-2024 11:49:59</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       https://mylpgbooks.com/ebs/EInvAndEWayBillPopupControlServlet?actionId=985&sitype=1&sr
//       _ no=SI-24-0668
//     </div>
//   );
// };

// export default EInvoice;


  {/* {e_inv ? (
                <>
                  <div className={styles.bold_text}>1.e-Invoice Details</div>
                  <div className={styles.norm_text}>
                    IRN :
                    1e1a881c5a8b64330ccb7232d821e65e0d2570daf0203af891061371828022dc
                  </div>
                  <div className={styles.norm_text}>
                    Ack. No : 112421099909157
                  </div>
                  <div className={styles.norm_text}>
                    Ack. Date : 17-JUL-2024
                  </div>
                  
                </>
              ) : (
                <>
                  <div className={styles.bold_text}>{profile_data?.name}</div>
                  <div className={styles.norm_text}>
                    {profile_data.ADDRESS}, {profile_data?.LOCATION},
                    {profile_data.DISTRICT}, {profile_data?.PINCODE}
                  </div>
                  <div className={styles.norm_text}>
                    PH:{profile_data?.mobile},{profile_data?.landline}
                  </div>
                  <div className={styles.norm_text}>
                    GSTIN/UIN: {profile_data?.gst}
                  </div>
                  <div className={styles.norm_text}>
                    State Name : Telangana, Code : {profile_data?.state}
                  </div>
                  <div className={styles.norm_text}>
                    E-Mail : {profile_data?.email}
                  </div>
                </>
              )} */}