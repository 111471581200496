import React, { useEffect, useRef, useState } from "react";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import { IoArrowBack } from "react-icons/io5";
import Input from "../../../components/inputs/Input";
import Modal from "../../../components/modal/Modal";
import Dropdown from "../../../components/inputs/Dropdown";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import {
  get_equipment_list,
  get_nc_dbc_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import Table from "../../../components/table/Table";
import { DeleteIcon } from "../../../utils/utils";
import { DateValidator2, gst_calculate } from "../../../utils/validation";
import moment from "moment";
import Loader from "../../../components/Loader";
import SmallModal from "../../../components/modal/SmallModal";
import PageLoading from "../../../components/PageLoading";
import NoData from "../../../components/NoData";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import {
  get_otv_ttv_data_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { otv_ttv_data_API } from "../../../utils/apis";
import { DropInput } from "../../../components/inputs/DropInput";
import { Alerts } from "../../../components/Alerts/Alerts";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { UOMData } from "../../../utils/data";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
// const tax_invoice_table_header = [
//   "S No.",
//   "PRODUCT",
//   "HSN/SAC",
//   "QTY",
//   "UOM",
//   "RATE",
//   "IMPREST AMOUNT",
//   "ADMIN CHARGES",
//   "TAXABLE",
//   "GST %	",
//   "TOTAL",
// ];

const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  { title: "Admin Amount", cols: false },
  { title: "Imprest Amount", cols: false },
  { title: "Amount", cols: false },
];

export const OTVTTVTV = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [addDataHead, setAddDataHead] = useState({});
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [DeleteId, setDeleteId] = useState("");
  const [viewItem, setViewItem] = useState({});
  const [openView, setOpenView] = useState(false);
  console.log("viewItem ===", viewItem);
  const { staff_list, equipment_list } = useSelector(
    (state) => state.masterdata
  );
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  const { user_id, today } = useSelector((state) => state.other);
  const { godown_address_list } = useSelector((state) => state.profile);
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  const { otv_ttv_data_list, otv_ttv_data_loading, transition_complete_list } =
    useSelector((state) => state.transactions);
  const { day_end_date } = useSelector((state) => state.profile);

  useEffect(() => {
    store.dispatch(get_staff_list());
    store.dispatch(get_nc_dbc_list());
    store.dispatch(get_equipment_list());
    store.dispatch(get_transition_complete_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_otv_ttv_data_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const handleView = (item) => {
    setViewItem(item);
    setOpenView(true);
  };

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      const response = await otv_ttv_data_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          otv_ttv_data_list?.length < 2 && pageNo != "1" ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_otv_ttv_data_list({
            page:
              otv_ttv_data_list?.length < 2 && pageNo != "1"
                ? pageNo - 1
                : pageNo,
            limit: entries,
            setTotalCount: setTotalCount,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate_new = (type) => {
    const data = [];
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      data.push("Please enter INVOICE DATE");
    } else if (
      DateValidator2(
        moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Invoice Date Cant be below as a day end date ${day_end_date}`
        )
      );
    }
    if (
      (type == "invoicenumber" || !type) &&
      (!addDataHead?.customer_name ||
        (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0))
    ) {
      data.push("Please Enter CUSTOMER NO/NAME and then fetch data");
    }

    if (
      (type == "fetchgst" || !type) &&
      (!addDataHead?.product_code ||
        (addDataHead?.product_code && addDataHead?.product_code?.length == 0))
    ) {
      data.push("Please Select Product");
    }
    return data;
  };
  const fetch_deposit = async () => {
    const data = validate_new("fetchgst");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchgst");
        body.append("agencyid", user_id);
        body.append("invoice_date", addDataHead?.inv_date);
        body.append("PROD_CODE", addDataHead?.product_code);
        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            imprest_gst_p: 18,
            admin_charge_gst: 18,
            unit_rate: response?.product?.[0]?.BASE_PRICE,
            p_gst: response?.product?.[0]?.GSTP,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const fetch_invoice = async () => {
    const data = validate_new("invoicenumber");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.inv_date);
        body.append("customer", addDataHead?.customer_id);
        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            inv_no: response?.invoice_number,
            inv_dis: true,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const validate_calculate = () => {
    const data = [...validate_new()];

    if (
      !addDataHead?.imprest_amt ||
      (addDataHead?.imprest_amt && addDataHead?.imprest_amt?.length == 0)
    ) {
      data.push("Please Enter Imprest Amount ");
    }
    if (
      !addDataHead?.cylinder_no ||
      (addDataHead?.cylinder_no && addDataHead?.cylinder_no?.length == 0)
    ) {
      data.push("Please Enter Cylinder Quantity");
    } else if (addDataHead?.cylinder_no > 2 || addDataHead?.cylinder_no < 0) {
      data.push("NO. OF CYL Must be 1 or 2");
    }
    if (
      !addDataHead?.regulator_no ||
      (addDataHead?.regulator_no && addDataHead?.regulator_no?.length == 0)
    ) {
      data.push("Please Enter Regulator Quantity");
    } else if (addDataHead?.regulator_no > 2) {
      data.push("NO. OF REGULATORS Must Be 0 or 1");
    }
    if (
      !addDataHead?.cylinder_deposit ||
      (addDataHead?.cylinder_deposit &&
        addDataHead?.cylinder_deposit?.length == 0)
    ) {
      data.push("Please Enter Cylinder Deposit");
    } else if (
      addDataHead?.cylinder_deposit > 1000000 ||
      addDataHead?.cylinder_deposit <= 0
    ) {
      data.push("Please Enter Valid CYLINDER DEPOSIT and then CALCULATE");
    }
    if (
      !addDataHead?.regulator_deposit ||
      (addDataHead?.regulator_deposit &&
        addDataHead?.regulator_deposit?.length == 0)
    ) {
      data.push("Please enter REGULATOR DEPOSIT.Enter 0 if not available.");
    } else if (
      addDataHead?.regulator_no == 0 &&
      addDataHead?.cylinder_deposit > 0
    ) {
      data.push("REGULATOR DEPOSIT must be 0 for Zero NO OF REGULATORS.");
    } else if (
      addDataHead?.regulator_deposit > 100000 ||
      addDataHead?.cylinder_deposit <= 0
    ) {
      data.push("REGULATOR DEPOSIT must be grater than 0.");
    }
    if (
      !addDataHead?.admin_charge ||
      (addDataHead?.admin_charge && addDataHead?.admin_charge?.length == 0)
    ) {
      data.push("Please select Admin Charges.");
    }
    return data;
  };

  const validate = () => {
    const data = [...validate_calculate()];
    if (
      !addDataHead?.inv_no ||
      (addDataHead?.inv_no && addDataHead?.inv_no?.length == 0)
    ) {
      data.push("Please Fetch Invoice Number");
    }
    if (
      !addDataHead?.staff_code ||
      (addDataHead?.staff_code && addDataHead?.staff_code?.length == 0)
    ) {
      data.push("Please Select Staff Name");
    }

    if (
      !addDataHead?.inv_amount ||
      (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
    ) {
      data.push("Please Click on Calculate Button First");
    } else if (addDataHead?.inv_amount == 0) {
      data.push("Invoice Amount Always greater then zero");
    }
    if (
      godown_address_list?.length > 1 &&
      (!addDataHead?.godown ||
        (addDataHead?.godown && addDataHead?.godown?.length === 0))
    ) {
      data.push("Please Select Dispatch Godown Address");
    }

    return data;
  };

  const calculate = async () => {
    const data = validate_calculate();
    if (data?.length == 0) {
      // const p_gst = Number(addDataHead?.p_gst);
      // const unit_rate = Number(addDataHead?.unit_rate);
      // const imprest_gst_p = Number(addDataHead?.imprest_gst_p);
      // const imprest_amt = Number(addDataHead?.imprest_amt);
      // const admin_charge_gst = Number(addDataHead?.admin_charge_gst);
      // const admin_charge = Number(addDataHead?.admin_charge);
      // const cylinder_no = Number(addDataHead?.cylinder_no);

      // const basic_amount = unit_rate * cylinder_no;
      // const gst_amount = (basic_amount * p_gst) / 100;

      // const imprest_gst_amt = (imprest_amt * imprest_gst_p) / 100;
      // const admin_gst_amt = (admin_charge * admin_charge_gst) / 100;

      // const total =
      //   // basic_amount +
      //   // gst_amount +
      //   imprest_amt + imprest_gst_amt + admin_charge + admin_gst_amt;

      // const total_gst_amt = admin_gst_amt;

      const imprest_amt = Number(addDataHead?.imprest_amt);
      const cylinder_deposit = Number(addDataHead?.cylinder_deposit);
      const regulator_deposit = Number(addDataHead?.regulator_deposit);
      const admin_charge = Number(addDataHead?.admin_charge);
      const gst_amount = Number(addDataHead?.gst_amount);

      console.log(
        "imprest_amt,cylinder_deposit,regulator_deposit,,admin_charge,,gst_amount,",
        imprest_amt,
        cylinder_deposit,
        regulator_deposit,
        admin_charge,
        gst_amount
      );

      const total =
        Number(cylinder_deposit) +
        Number(regulator_deposit) -
        (Number(imprest_amt) + Number(admin_charge) + Number(gst_amount));

      setAddDataHead({
        ...addDataHead,
        inv_amount: total,
        payment_terms: "Cash",
        igst: addDataHead?.c_type == "isp" ? Number(gst_amount) : 0,
        sgst: addDataHead?.c_type != "isp" ? Number(gst_amount / 2) : 0,
        cgst: addDataHead?.c_type != "isp" ? Number(gst_amount / 2) : 0,
      });
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const add_items = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("SR_NO", addDataHead?.inv_no);
        body.append("TV_DATE", addDataHead?.inv_date);
        body.append(
          "godown_id",
          addDataHead?.godown || godown_address_list?.[0]?.ID || 0
        );
        // body.append("CUSTOMER_NO", addDataHead?.customer_id);
        body.append("CUSTOMER_NO", addDataHead?.customer_id || "");
        body.append("CUSTOMER_NAME", addDataHead?.customer_name);
        body.append("STAFF_ID", addDataHead?.staff_code);
        body.append("TV_AMOUNT", addDataHead?.inv_amount);
        body.append("TV_CAT", addDataHead?.category);
        body.append("PROD_CODE", addDataHead?.product_code);
        body.append("NO_OF_CYL", addDataHead?.cylinder_no);
        body.append("NO_OF_REG", addDataHead?.regulator_no);
        body.append("CYL_DEPOSIT", addDataHead?.cylinder_deposit);
        body.append("REG_DEPOSIT", addDataHead?.regulator_deposit);
        body.append("ADMIN_CHARGES", addDataHead?.admin_charge);
        body.append("IMP_AMOUNT", addDataHead?.imprest_amt);
        body.append("IGST_AMOUNT", addDataHead?.igst);
        body.append("CGST_AMOUNT", addDataHead?.cgst);
        body.append("SGST_AMOUNT", addDataHead?.sgst);
        body.append("PAYMENT_TERMS", addDataHead?.payment_terms);
        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setIsAdd(false);
          toast(response.message);
          setAddDataHead({});
          store.dispatch(get_otv_ttv_data_list(obj));
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"OTV / TTV /TV"}
        right={
          <>
            <AddButton
              onClick={() => {
                setIsAdd(true);
                setAddDataHead({ ...addDataHead, payment_terms: "Cash" });
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/_vkck33H-zE",
                "https://youtu.be/0iV0CiU67xc",
              ]}
            />
          </>
        }
      />

      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {otv_ttv_data_loading ? (
          <PageLoading />
        ) : otv_ttv_data_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {otv_ttv_data_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.CUSTOMER || v?.CUSTOMER_NAME}{" "}
                  </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.CUSTOMER_NAME}-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.TV_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Invoice Amt."} value={v?.TV_AMOUNT} />
                  <RowTxt title={"Godown Address "} value={v?.GODOWN_NAME} />
                  <RowTxt title={"Staff Name"} value={v?.STAFF_NAME} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <DeleteIcon
                      onClick={() => {
                        setOpen(true);
                        setDeleteId(v.ID);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    onClick={() => handleView(v)}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"OTV / TTV /TV"}
          body={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div className="flex aic gap10 credit-limit">
                  <Input
                    label={"INVOICE NUMBER"}
                    placeholder={"INVOICE NUMBER"}
                    value={addDataHead.inv_no}
                    disabled={true}
                  />
                  <Input
                    label={"INVOICE DATE"}
                    placeholder={"INVOICE DATE"}
                    type={"date"}
                    value={addDataHead?.inv_date}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddDataHead({
                          ...addDataHead,
                          inv_date: e.target.value,
                        });
                      }
                    }}
                  />
                  <Dropdown
                    width2=""
                    label={"STAFF NAME"}
                    value={addDataHead?.staff_code}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        staff_code: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        {staff_list?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.EMP_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <DropInput
                    label={"CUST NO / CUST NAME"}
                    id="custvend"
                    placeholder={"CUST NO / CUST NAME"}
                    value={addDataHead?.customer_name}
                    onChange={(e) => {
                      const data = transition_complete_list?.Customer?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setAddDataHead({
                        ...addDataHead,
                        // customer_name: data[0]?.cvo_name,
                        // customer_id: e.target.value,
                        customer_name:
                          data?.length > 0 ? data[0]?.cvo_name : e.target.value,
                        customer_id: data?.length > 0 ? e.target.value : "",
                        c_type: data[0]?.connection_type,
                      });
                    }}
                    list={transition_complete_list?.Customer?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                  />
                  <Dropdown
                    width2=""
                    label={"CATEGORY"}
                    value={addDataHead?.category}
                    onClick={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        category: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        <option value="DOMESTIC"> DOMESTIC</option>
                      </>
                    }
                  />
                  <Input
                    label={"INVOICE AMOUNT"}
                    placeholder={"INVOICE AMOUNT"}
                    disabled={true}
                    value={addDataHead?.inv_amount}
                  />
                  {godown_address_list?.length > 1 && (
                    <Dropdown
                      width2=""
                      label={"Dispatch Godown"}
                      value={addDataHead?.godown}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          godown: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>
                          {godown_address_list?.map((s, i) => (
                            <option key={i} value={s?.ID}>
                              {s?.UNIT_NAME}
                            </option>
                          ))}
                        </>
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    maxHeight: "20%",
                    overflow: "scroll",
                    display: "flex",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Table
                    headData={[
                      "PRODUCT",
                      "NUMBER OF CYLINDERS",
                      "NUMBER OF REGULATORS",
                      "CYLINDER DEPOSIT",
                      "REGULATOR DEPOSIT",
                      "IMPREST AMOUNT	",
                      "ADMIN CHARGES",
                      "GST AMOUNT",
                      "PAYMENT TERMS",
                    ]}
                    body={
                      <tr>
                        <td>
                          <Dropdown
                            value={addDataHead?.product_code}
                            onChange={(e) => {
                              const data = uniqueEquipmentList?.filter(
                                (v) =>
                                  v?.CAT_NAME == "DOMESTIC" &&
                                  v?.PROD_CODE == e.target.value
                              )?.[0];
                              setAddDataHead({
                                ...addDataHead,
                                product_code: e.target.value,
                                p_gst: data ? data?.GSTP : "",
                                imprest_amt: "",
                                gst_amount: "",
                                igst: "",
                                cgst: "",
                                sgst: "",
                                inv_amount: "",
                              });
                            }}
                            options={
                              <>
                                <option value=""> SELECT</option>
                                {uniqueEquipmentList
                                  ?.filter((v) => v?.CAT_NAME == "DOMESTIC")
                                  ?.map((v, i) => (
                                    <option key={i} value={v?.PROD_CODE}>
                                      {v?.CAT_NAME} - {v?.product_name}
                                    </option>
                                  ))}
                              </>
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.cylinder_no}
                            onChange={(e) => {
                              if (e.target.value?.length < 5) {
                                setAddDataHead({
                                  ...addDataHead,
                                  cylinder_no:
                                    /^\d+$/.test(e.target.value) ||
                                    e.target.value == ""
                                      ? e.target.value
                                      : "",
                                });
                              }
                            }}
                            placeholder={"No. of Cylinders"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.regulator_no}
                            onChange={(e) => {
                              if (e.target.value?.length < 5) {
                                setAddDataHead({
                                  ...addDataHead,
                                  regulator_no:
                                    /^\d+$/.test(e.target.value) ||
                                    e.target.value == ""
                                      ? e.target.value
                                      : "",
                                });
                              }
                            }}
                            placeholder={"No. of Regulators"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.cylinder_deposit}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.cylinder_deposit
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  cylinder_deposit:
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                  inv_amount: "",
                                });
                              }
                            }}
                            placeholder={"Cylinder Deposit"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.regulator_deposit}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.cylinder_deposit
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  regulator_deposit:
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                  inv_amount: "",
                                });
                              }
                            }}
                            placeholder={"Regulator Deposit"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.imprest_amt}
                            placeholder={"IMPREST Amount"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 8 &&
                                  /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.imprest_amt
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  imprest_amt:
                                    /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                  igst: "",
                                  cgst: "",
                                  sgst: "",
                                  inv_amount: "",
                                });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Dropdown
                            value={addDataHead?.admin_charge}
                            onChange={(e) => {
                              setAddDataHead({
                                ...addDataHead,
                                admin_charge: e.target.value,
                                gst_amount: Number(e.target.value) * (18 / 100),
                                igst: "",
                                cgst: "",
                                sgst: "",
                                inv_amount: "",
                              });
                            }}
                            options={
                              <>
                                <option value="">SELECT</option>
                                <option value="100">
                                  TV ADMIN CHARGES ( Rs. 100.00/-)
                                </option>
                                <option value="250">
                                  TV CYLINDER COLLECTION CHARGES FROM CUSTOMER
                                  DOORSTEP ( Rs. 250.00/-)
                                </option>
                                <option value="0">NILL ( Rs. 0.00/-)</option>
                              </>
                            }
                          />
                        </td>

                        <td>
                          <Input
                            value={addDataHead?.gst_amount}
                            placeholder={"GST Amount"}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder={"PAYMENT TERMS"}
                            disabled={true}
                            value={addDataHead?.payment_terms}
                          />
                        </td>
                      </tr>
                    }
                  />
                </div>
                <div
                  className="flex aic gap10"
                  style={{ marginTop: 10, justifyContent: "flex-end" }}
                >
                  <Button
                    title={"FETCH INVOICE NO"}
                    onClick={fetch_invoice}
                    back
                  />
                  {/* <Button
                    title={"FETCH DEPOSITS AND CHARGES"}
                    onClick={fetch_deposit}
                    back
                  /> */}
                  <Button title={"CALCULATE"} onClick={calculate} back />
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </>
          }
        />
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CUSTOMER_NAME}-${viewItem?.SR_NO}`}
            />
          }
          isOpen={openView}
          title={viewItem?.CUSTOMER_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "NUMBER OF CYLINDERS",
                  "NUMBER OF REGULATORS",
                  "CYLINDER DEPOSIT",
                  "REGULATOR DEPOSIT	",
                  "IMPREST AMOUNT",
                  "ADMIN CHARGES	",
                  "IGST AMOUNT",
                  "CGST AMOUNT",
                  "SGST AMOUNT",
                  "PAYMENT TERMS",
                ]}
                body={
                  <tr>
                    <td>{viewItem?.PRODUCT_NAME}</td>
                    <td>{viewItem?.NO_OF_CYL}</td>
                    <td>{viewItem?.NO_OF_REG}</td>
                    <td>{viewItem?.CYL_DEPOSIT}</td>
                    <td>{viewItem?.REG_DEPOSIT}</td>
                    <td>{viewItem?.ADMIN_CHARGES}</td>
                    <td>{viewItem?.IMP_AMOUNT}</td>
                    <td>{viewItem?.IGST_AMOUNT}</td>
                    <td>{viewItem?.CGST_AMOUNT}</td>
                    <td>{viewItem?.SGST_AMOUNT}</td>
                    <td>Cash</td>
                  </tr>
                }
              />
            </>
          }
        ></Modal>
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice2
          previewRef={previewRef}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.RC_DATE,
            // CVO_NAME: viewItem?.CUSTOMER_NAME,
            // CVO_ADDRESS: viewItem?.CUST_ADDR,
            // B_STATE: "Not Getting in the list",
            // CVO_TIN: viewItem?.CUST_GSTIN || "NA",
            // SHIP_CVO_NAME: viewItem?.CUSTOMER_NAME,
            // SHIP_CVO_ADDRESS: viewItem?.CUST_ADDR,
            // SHIP_B_STATE: "Not Getting in the list", // Currently not getting in the APIs
            // SHIP_CVO_TIN: viewItem?.CUST_GSTIN || "NA",
            // CUST_ID: viewItem?.CUSTOMER_NO,
            // SECURITY_DEPOSIT: (Number(viewItem?.CYL_DEPOSIT) + Number(viewItem?.REG_DEPOSIT)).toFixed(2),
            // IMPREST_AMT:viewItem?.IMP_AMOUNT,
            // SERVICE_AMT : (Number(viewItem?.ADMIN_CHARGES) + Number(viewItem?.IGST_AMOUNT) + Number(viewItem?.CGST_AMOUNT) + Number(viewItem?.SGST_AMOUNT)).toFixed(2),
            // REFUNDED_AMT : (Number(viewItem?.CYL_DEPOSIT) + Number(viewItem?.REG_DEPOSIT)) - Number(viewItem?.IMP_AMOUNT) - (Number(viewItem?.ADMIN_CHARGES) + Number(viewItem?.IGST_AMOUNT) + Number(viewItem?.CGST_AMOUNT) + Number(viewItem?.SGST_AMOUNT) ).toFixed(2)
            CVO_NAME: viewItem?.CUSTOMER_NAME,
            CVO_ADDRESS: viewItem?.CUST_ADDR,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_TIN: viewItem?.CUST_GSTIN || "NA",
            SHIP_CVO_NAME: viewItem?.CUSTOMER_NAME,
            SHIP_CVO_ADDRESS: viewItem?.CVO_ADDRESS,
            SHIP_B_STATE: viewItem?.STATE_CODE,
            SHIP_B_STATE_NAME: viewItem?.B_STATE_NAME,
            SHIP_CVO_TIN: viewItem?.CUST_GSTIN || "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            EMP_NAME: viewItem?.STAFF_NAME,
          }}
          other_type={"OTV / TTV /TV"}
          table_header={tax_invoice_table_header}
          table_body={
            <>
              <tr className="both" style={{ border: "none" }}>
                <td className="both" style={{ width: "fit-content" }}>
                  1
                </td>
                <td className="both" style={{ width: "fit-content" }}>
                  {`${viewItem?.PRODUCT_NAME}`}
                </td>
                <td className="both" style={{ border: "none" }}>
                  {viewItem?.CSTEH_NO}
                </td>
                <td className="both" style={{ border: "none" }}>
                  {viewItem?.NO_OF_CYL}
                </td>
                <td className="both">{viewItem?.GSTP}</td>
                <td className="both">
                  {Number(
                    Number(viewItem?.BASE_PRICE) +
                      Number(
                        gst_calculate(viewItem?.BASE_PRICE, viewItem?.GSTP)
                      )
                  ).toFixed(2)}
                </td>
                <td className="both" style={{ border: "none" }}>
                  {viewItem?.BASE_PRICE}
                </td>
                <td className="both" style={{ border: "none" }}>
                  {viewItem?.UNITS == "1"
                    ? "NOS"
                    : viewItem?.UNITS == "2"
                    ? "KGS"
                    : viewItem?.UNITS == "3"
                    ? "SET"
                    : UOMData?.filter((val) => val?.UOM == viewItem?.UNITS)?.[0]
                        ?.title || viewItem?.UNITS}
                </td>
                <td className="both">{viewItem?.ADMIN_CHARGES}</td>
                <td className="both" style={{ border: "none" }}>
                  {viewItem?.IMP_AMOUNT}
                </td>
                {/* <td className="both">{viewItem?.ADMIN_CHARGES}</td> */}

                <td className="both">
                  {Number(viewItem?.ADMIN_CHARGES) +
                    Number(viewItem?.IMP_AMOUNT)}
                </td>
              </tr>

              <tr>
                <td className="both"></td>
                <td className="both" style={{ textAlign: "left" }}></td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td
                  className="both boldBorder"
                  style={{ borderTop: "1px solid black !important" }}
                >
                  {/* {viewItem?.SI_AMOUNT} */}
                  {Number(viewItem?.ADMIN_CHARGES) +
                    Number(viewItem?.IMP_AMOUNT)}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OUTPUT IGST
                </td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {viewItem?.IGST_AMOUNT}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OUTPUT CGST
                </td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {/* {viewItem?.products
                    ?.map((v) => v?.CGST_AMOUNT)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)} */}
                  {viewItem?.CGST_AMOUNT}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OUTPUT SGST
                </td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {viewItem?.SGST_AMOUNT}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  DEPOSITES
                </td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {Number(viewItem?.CYL_DEPOSIT) +
                    Number(viewItem?.REG_DEPOSIT)}
                </td>
              </tr>
              <tr>
                <td className="both"></td>
                <td
                  className="both"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  ROUND OFF (Sales)
                </td>
                {Array(8)
                  .fill()
                  .map((v, i) => (
                    <td className="both"></td>
                  ))}
                <td className="both" style={{ fontWeight: "bold" }}>
                  {(
                    Number(viewItem?.TV_AMOUNT).toFixed(0) -
                    Number(viewItem?.TV_AMOUNT)
                  ).toFixed(2)}
                </td>
              </tr>

              <tr style={{ height: "160px" }}>
                {Array(11)
                  .fill()
                  .map(() => (
                    <td className="both"></td>
                  ))}
              </tr>
              <tr>
                <td className="both border_top"></td>
                <td
                  className="both border_top"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td className="both border_top"></td>
                <td className="both border_top"></td>
                <td
                  className="both border_top"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  {viewItem?.products
                    ?.map((v) => v?.NO_OF_CYL)
                    .reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(0)}
                </td>
                {Array(5)
                  .fill()
                  .map((v, i) => (
                    <td className="both border_top"></td>
                  ))}
                <td className="both border_top" style={{ fontWeight: "bold" }}>
                  {Number(viewItem?.TV_AMOUNT).toFixed(0)}
                </td>
              </tr>
            </>
          }
          hsn_data={
            <>
              {viewItem?.hsn_groups?.map((v, i) => (
                <tr key={i}>
                  <td
                    colSpan={3}
                    className={"td_1"}
                    style={{ textAlign: "left" }}
                  >
                    {v?.HSNCODE}
                  </td>
                  <td className={"td_1"}>
                    {Number(v?.TAXABLE_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>{v?.IGST_RATE}%</td>
                  <td className={"td_1"}>
                    {Number(v?.IGST_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>{v?.CGST_RATE}%</td>
                  <td className={"td_1"}>
                    {Number(v?.CGST_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>{v?.SGST_RATE}%</td>
                  <td className={"td_1"}>
                    {Number(v?.SGST_AMOUNT).toFixed(2)}
                  </td>
                  <td className={"td_1"}>
                    {Number(v?.TOTAL_AMOUNT).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={3}
                  className={"td_1"}
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.TAXABLE_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                  {/* {Number(v?.TAXABLE_AMOUNT).toFixed(2)} */}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.IGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.CGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}></td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.SGST_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
                <td className={"td_1"} style={{ fontWeight: "bold" }}>
                  {viewItem?.hsn_groups
                    ?.map((v) => v?.TOTAL_AMOUNT)
                    ?.reduce((a, b) => Number(a) + Number(b), 0)
                    ?.toFixed(2)}
                </td>
              </tr>
            </>
          }
          cols={3}
          Total_tax={viewItem?.hsn_groups
            ?.map((v) => v?.TOTAL_AMOUNT)
            ?.reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          net_total={Number(viewItem?.TV_AMOUNT).toFixed(2) || 0}
          // security_deposite={}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
