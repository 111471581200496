import React, { useEffect, useLayoutEffect } from "react";
import Layout from "./components/layout/Layout";
import "./App.css";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CylinderPurchases from "./screens/transactions/purchase/CylinderPurchases";
import BANPurchases from "./screens/transactions/purchase/BANPurchases";
import Login from "./screens/auth/Login";
import EmployeeLogin from "./screens/auth/EmployeeLogin";
import Profile from "./screens/profile";
import Receipt from "./screens/cash&bank/Receipt";
import Payments from "./screens/cash&bank/Payments";
import BankTransactions from "./screens/cash&bank/BankTransactions";
import CreditNote from "./screens/cash&bank/CreditNote";
import DebitNote from "./screens/cash&bank/DebitNote";
import Signup from "./screens/auth/Signup";
import StatutoryMaster from "./screens/MasterData/StatutoryMaster";
import EquipmentMaster from "./screens/MasterData/productmaster/EquipmentMaster";
import BANMaster from "./screens/MasterData/productmaster/BANMaster";
import ServicesMaster from "./screens/MasterData/productmaster/ServicesMaster";
import AreaCodeMaster from "./screens/MasterData/productmaster/AreaCodeMaster";
import NDPackaging from "./screens/MasterData/productmaster/NDPackaging";
import store from "./redux/store";
import { get_complete_list } from "./redux/slice/completeListSlice";
import PurchaseReport from "./screens/transactionReport/PurchaseReport";
import StockReport from "./screens/transactionReport/StockReport";
import Ledger from "./screens/transactionReport/Ledger";
import SalesReport from "./screens/transactionReport/SalesReport";
import NDRTReport from "./screens/transactionReport/NDRTReport";
import Payables from "./screens/transactionReport/Payables";
import BankBook from "./screens/transactionReport/BankBook";
import Receivables from "./screens/transactionReport/Receivables";
import StafMaster from "./screens/Payroll/StafMaster";
import Laons from "./screens/Payroll/Loans";
import PayRuns from "./screens/Payroll/PayRuns";
import Reports from "./screens/Payroll/Reports";
import { RefilePrice } from "./screens/MasterData/PriceMaster/RefilePrice";
import { BLPGPrice } from "./screens/MasterData/PriceMaster/BLPGPrice";
import { CustVendMaster } from "./screens/MasterData/CustVendMaster/CustVendMaster";
import { VehicalMaster } from "./screens/MasterData/VehicalMaster/VehicalMaster";
import { StaffMaster } from "./screens/MasterData/StaffMaster/StaffMaster";
import { get_master_complete_list } from "./screens/MasterData/masterdataSlice";
import { Quotations } from "./screens/transactions/sales/Quotations";

import DayEnd from "./screens/DayEnd/DayEnd";
import ProAndLoss from "./screens/FinacialReports/ProAndLoss";
import BalanceSheet from "./screens/FinacialReports/BalanceSheet";
import ProfitabilityAnalysis from "./screens/FinacialReports/ProfitabilityAnalysis";
import PartnersData from "./screens/FinacialReports/CapitalAccount/PartnersData";
import PartnersTransaction from "./screens/FinacialReports/CapitalAccount/PartnersTransaction";
import CapitalAccSearch from "./screens/FinacialReports/CapitalAccount/CapitalAccSearch";
import CapitalAccReport from "./screens/FinacialReports/CapitalAccount/CapitalAccReport";
import AssetsManagement from "./screens/FinacialReports/DepreciationAccount/AssetsManagement";
import DepreciationReport from "./screens/FinacialReports/DepreciationAccount/DepreciationReport";
import GSTR1 from "./screens/GST-Return/GSTR1";
import GSTR2 from "./screens/GST-Return/GSTR2";
import GSTR3 from "./screens/GST-Return/GSTR3";
import GSTR9 from "./screens/GST-Return/GSTR9";

import TDS from "./screens/GST-Return/TDS&TCS/TDS";
import TCS from "./screens/GST-Return/TDS&TCS/TCS";
import TDSTCSReturn from "./screens/GST-Return/TDS&TCS/TDSTCSReturn";
import { SetCreditLimit } from "./screens/MasterData/SetCreditLimit/SetCreditLimit";
import { DefectiveReturn } from "./screens/transactions/purchase/DefectiveReturn";
import { PurchaseOrder } from "./screens/transactions/sales/PurchaseOrder";
import { OtherPurchase } from "./screens/transactions/purchase/OtherPurchase";
import { ExpenditureOtherPurchase } from "./screens/OtherComponent/ExpenditureOtherPurchase";
import { DomestiCylSale } from "./screens/transactions/sales/DomestiCylSale";
import { ComercialCylSale } from "./screens/transactions/sales/ComercialCylSale";
import { BANSales } from "./screens/transactions/sales/BANSales";
import { DeliveryChallan } from "./screens/transactions/sales/DeliveryChallan";
import { SalesReturn } from "./screens/transactions/sales/SalesReturn";
import { BankMaster } from "./screens/MasterData/BankMaster/BankMaster";
import { StockAdjustMent } from "./screens/MasterData/StockAdjustment/StockAdjustMent";
import { ForgotPass } from "./screens/auth/ForgotPass";
import { Home } from "./screens/Home/Home";
import { EmailUpdate, ForgotPassword, LoginNew } from "./screens/auth/LoginNew";
import { NCDBCTran } from "./screens/transactions/NCDBC/NCDBCTran";
import { OTVTTVTV } from "./screens/transactions/NCDBC/OTVTTVTV";
import { ITVRC } from "./screens/transactions/NCDBC/ITVRC";
import {
  get_effective_date,
  get_omc,
  get_user_id,
  get_user_turnover,
} from "./redux/slice/SlideSlice";
import { VenderPurchase } from "./screens/transactions/purchase/VenderPurchase";
import PaymentReceived from "./screens/transactionReport/PaymentReceived";
import Cheque from "./screens/cash&bank/Cheque";
import LayoutProfile from "./components/layout/LayoutProfile";
import Ainfo from "./screens/profile/Ainfo";
import InvoiceBillInfo from "./screens/profile/InvoiceBillInfo";
import Settinginfo from "./screens/profile/Settinginfo";
import PPinfo from "./screens/profile/PPinfo";
import Pinfo from "./screens/profile/Pinfo";
import Uinfo from "./screens/profile/Uinfo";
import { logoutAllTabs } from "./utils/Hook/useBroadcastChannel";
import ReceivablesPaymentsDetails from "./screens/transactionReport/ReceivablesDetails";
// import useBroadcastChannel from "./utils/Hook/useBroadcastChannel";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uid = localStorage.getItem("userId");
  const ID = JSON.parse(uid);

  useEffect(() => {
    logoutAllTabs();
    // loginAllTabs()
  }, []);

  useEffect(() => {
    if (ID == null) {
      if (
        location?.pathname != "/forgotpass/login" &&
        location?.pathname != "/email" &&
        location?.pathname != "/loginn"
      ) {
        navigate("/login");
      } else {
        return;
      }
    } else {
      if (location.pathname == "/") {
        navigate("/Home");
      }
    }
  }, []);
  useEffect(() => {
    store.dispatch(get_complete_list());
    store.dispatch(get_master_complete_list());
    store.dispatch(get_effective_date());
    store.dispatch(get_user_turnover());
    store.dispatch(get_user_id());
    store.dispatch(get_omc());
  }, [ID]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <Wrapper>
      <Routes>
        <Route element={<Layout />}>
          <Route path="transactions" element={<Outlet />}>
            <Route path="Cylinder-Purchases" element={<CylinderPurchases />} />
            <Route path="BLPG/ARB/NFR-Purchases" element={<BANPurchases />} />
            <Route path="Defective/Pur/Return" element={<DefectiveReturn />} />
            <Route path="OtherPurchase" element={<OtherPurchase />} />
            <Route path="Quotations" element={<Quotations />} />
            <Route path="venderpurchase" element={<VenderPurchase />} />
            <Route
              path="ExpenditureOtherPurchase"
              element={<ExpenditureOtherPurchase />}
            />
            <Route path="PurchaseSales" element={<PurchaseOrder />} />
            <Route path="DomestiCylSale" element={<DomestiCylSale />} />
            <Route path="CommercialCylSale" element={<ComercialCylSale />} />
            <Route path="ARBSale" element={<BANSales />} />
            <Route path="DeliveryChallan" element={<DeliveryChallan />} />
            <Route path="SalesReturn" element={<SalesReturn />} />
            <Route path="ncdbctrans" element={<NCDBCTran />} />
            <Route path="otvttvtvtrans" element={<OTVTTVTV />} />
            <Route path="itvrctrans" element={<ITVRC />} />
          </Route>
          <Route path="Cash&Bank" element={<Outlet />}>
            <Route path="Receipts" element={<Receipt />} />
            <Route path="Payments" element={<Payments />} />
            <Route path="cheque" element={<Cheque />} />
            <Route path="BankTransactions" element={<BankTransactions />} />
            <Route path="CreditNote" element={<CreditNote />} />
            <Route path="DebitNote" element={<DebitNote />} />
          </Route>
          <Route path="GSTReportS" element={<Outlet />}>
            <Route path="GST1" element={<GSTR1 />} />
            <Route path="GST2" element={<GSTR2 />} />
            <Route path="GST3" element={<GSTR3 />} />
            <Route path="GST9" element={<GSTR9 />} />
          </Route>
          <Route path="TDS&TCS" element={<Outlet />}>
            <Route path="TDS" element={<TDS />} />
            <Route path="TCS" element={<TCS />} />
            <Route path="TDSTCSReturns" element={<TDSTCSReturn />} />
          </Route>
          <Route path="MasterData" element={<Outlet />}>
            <Route path="StatutoryMaster" element={<StatutoryMaster />} />
            <Route path="EquipmentMaster" element={<EquipmentMaster />} />
            <Route path="BLPG/ARB/NFR-Master" element={<BANMaster />} />
            <Route path="ServicesMaster" element={<ServicesMaster />} />
            <Route path="AreaCodeMaster" element={<AreaCodeMaster />} />
            <Route path="NC/DBC-Packaging" element={<NDPackaging />} />
            <Route path="Price-Refill" element={<RefilePrice />} />
            <Route path="BLPG/ARB/NFR-price" element={<BLPGPrice />} />
            <Route path="custVendMaster" element={<CustVendMaster />} />
            <Route path="VehicalMaster" element={<VehicalMaster />} />
            <Route path="StaffMaster" element={<StaffMaster />} />
            <Route path="SetCreditLimit" element={<SetCreditLimit />} />
            <Route path="BankMaster" element={<BankMaster />} />
            <Route path="StockAdjustMent" element={<StockAdjustMent />} />
          </Route>
          <Route path="transactionReport" element={<Outlet />}>
            <Route path="PurchaseReport" element={<PurchaseReport />} />
            <Route path="StockReport" element={<StockReport />} />
            <Route path="Ledger" element={<Ledger />} />
            <Route path="SalesReport" element={<SalesReport />} />
            <Route path="NDRTReport" element={<NDRTReport />} />
            <Route path="Payables" element={<Payables />} />
            <Route path="BankBook" element={<BankBook />} />
            <Route path="Receivables" element={<Receivables />} />
            <Route
              path="ReceivablesDetails"
              element={<ReceivablesPaymentsDetails />}
            />
            <Route path="PaymentReceipts" element={<PaymentReceived />} />
          </Route>
          <Route path="Payroll" element={<Outlet />}>
            <Route path="StaffMaster" element={<StafMaster />} />
            <Route path="PayRuns" element={<PayRuns />} />
            <Route path="Loans" element={<Laons />} />
            <Route path="Reports" element={<Reports />} />
          </Route>
          <Route path="FinacialReport" element={<Outlet />}>
            <Route path="ProLossAccount" element={<ProAndLoss />} />
            <Route path="PartnersData" element={<PartnersData />} />
            <Route
              path="PartnersTransaction"
              element={<PartnersTransaction />}
            />
            <Route path="CapitalAccSearch" element={<CapitalAccSearch />} />
            <Route path="CapitalAccReport" element={<CapitalAccReport />} />
            <Route path="AssetsManagement" element={<AssetsManagement />} />
            <Route path="DepreciationReport" element={<DepreciationReport />} />
            <Route path="BalanceSheet" element={<BalanceSheet />} />
            <Route
              path="ProfitabilityAnalysis"
              element={<ProfitabilityAnalysis />}
            />
          </Route>
          <Route path="DayEndPage" element={<DayEnd />} />
          <Route path="Home" element={<Home />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route element={<LayoutProfile />}>
            <Route path="profile" element={<Outlet />}>
              <Route path="/profile/Ainfo" element={<Ainfo />} />
              <Route path="/profile/Pinfo" element={<Pinfo />} />
              <Route path="/profile/Uinfo" element={<Uinfo />} />
              <Route
                path="/profile/InvoiceBillInfo"
                element={<InvoiceBillInfo />}
              />
              <Route path="/profile/Settinginfo" element={<Settinginfo />} />
              <Route path="/profile/PPinfo" element={<PPinfo />} />
            </Route>
          </Route>
        </Route>
        <Route exact path="login" element={<Login />} />
        <Route path="/loginn" element={<LoginNew />} />
        {/* <Route path="/login/:aid" element={<LoginNew />} /> */}
        {/* <Route path="/email/:aid/:oldemail/:newemail" element={<EmailUpdate />} /> */}
        <Route path="/email" element={<EmailUpdate />} />

        <Route path="register" element={<Signup />} />
        <Route path="forgot" element={<ForgotPass />} />
        <Route path="/forgotpass/login" element={<ForgotPassword />} />
        {/* <Route path="employee-login" element={<EmployeeLogin />} /> */}
      </Routes>
    </Wrapper>
  );
};

export default App;
