import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import moment from "moment";

const userIdString = localStorage.getItem("userId");
const userId = JSON.parse(userIdString)?.aid;

// Profile Data
export const get_profile_data = createAsyncThunk("profile", async (id) => {
  const body = new FormData();
  body.append("action", "profile");
  body.append("agencyid", id);
  const response = await fetch(base_url + "profile.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

// Home Data
export const get_home_data = createAsyncThunk("home", async (id) => {
  const userIdString = localStorage.getItem("userId");
const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("agencyid", id || userId);
  const response = await fetch(base_url + "home.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const get_profile_name = createAsyncThunk("firmname", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.Name?.toUpperCase();
});
export const get_profile_img_url = createAsyncThunk("image", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.image_url;
});
export const get_profile_pin = createAsyncThunk("pin", async () => {
  return JSON.parse(localStorage.getItem("userId"))?.PIN;
});
export const get_pin_data = createAsyncThunk("pin", async (pin) => {
  return pin;
});

const initialState = {
  profile_data: {},
  profile_loading: false,
  showroom_address_list: [],
  godown_address_list: [],
  img_url: "",
  firm_name: "",
  contact_person_name: "",
  pin_data: "",
  home_data: {},
  day_end_date: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(get_profile_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.profile_loading = false;
      if (data.success) {
        state.profile_data = data?.list[0];
        state.showroom_address_list =
          data?.list?.addresslist?.filter((v) => v?.ADDRESS_TYPE == "1") || [];
        state.godown_address_list =
          data?.list?.addresslist?.filter((v) => v?.ADDRESS_TYPE == "2") || [];
        state.img_url = data?.list[0]?.image_url;
        state.contact_person_name = data?.list[0]?.contact_person_name;
        state.pin_data =
          data?.list[0]?.PINNUMBER == 0 ||
          data?.list[0]?.PINNUMBER == "" ||
          data?.list[0]?.PINNUMBER == null
            ? 0
            : 1;
      } else {
        state.profile_data = [];
        state.showroom_address_list = [];
        state.godown_address_list = [];
      }
    });
    builder.addCase(get_profile_data.pending, (state, action) => {
      state.profile_loading = true;
    });
    builder.addCase(get_profile_data.rejected, (state, action) => {
      state.profile_loading = false;
    });

    builder.addCase(get_home_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_data = data;
      state.day_end_date = data.dayend
        ? moment(data.dayend).format("DD/MM/YYYY")
        : "";
    });

    // For Name
    builder.addCase(get_profile_name.fulfilled, (state, action) => {
      const data = action.payload;
      state.firm_name = data;
    });

    // For Name
    builder.addCase(get_profile_img_url.fulfilled, (state, action) => {
      const data = action.payload;
      state.img_url = data;
    });

    // For Pin
    builder.addCase(get_pin_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.pin_data = data;
    });
  },
});

export default profileSlice.reducer;
