import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import TableNew from "../../../components/table/TableWithPagination";
import PageLoading from "../../../components/PageLoading";
import { DeleteIcon, Radio } from "../../../utils/utils";
import SmallModal from "../../../components/modal/SmallModal";
import Table from "../../../components/table/Table";
import Loader from "../../../components/Loader";
import { bankDetails } from "../../../utils/data";
import Dropdown from "../../../components/inputs/Dropdown";
import { Input } from "../../../components/inputs/Inputs";
import { bank_master_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import { checkIFSC, checkNumber } from "../../../utils/validation";
import { Pagination2 } from "../../../components/pagination/pagination";
import { PinVerify } from "../../OtherComponent/PinVerify";
import store from "../../../redux/store";
import { get_bank_master_list } from "../masterdataSlice";
import { useSelector } from "react-redux";
import { alfaNumeric } from "../../../utils/Rejex";
import Button from "../../../components/btn/Button";
import Modal from "../../../components/modal/Modal";
const headData = [
  "BANK NAME",
  "NICK NAME",
  "ACCOUNT TYPE",
  "ACCOUNT NO",
  "BRANCH",
  "IFSC CODE",
  "ADDRESS",
  "OPENING BALANCE",
  "CLOSING BALANCE",
  "CREDIT LIMIT",
  "ACTIONS",
];

export const BankMaster = () => {
  const [showRadio, setShowRadio] = useState("");
  const [addData, setAddData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const { user_id } = useSelector((state) => state.other);

  const [showPinVerify, setShowPinVerify] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [openEd, setOpenEd] = useState(false);
  const [itemData, setItemData] = useState({});
  const [edit, setEdit] = useState(0);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { bank_master_list, bank_master_loading } = useSelector(
    (state) => state.masterdata
  );
  
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = () => {
      if (showRadio != "") {
        handleChangeDefault();
      }
    };
    fetchData();
  }, [showRadio]);

  const handleChangeDefault = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "bankaccount");
      body.append("agencyid", user_id);
      body.append("id", showRadio);
      const response = await bank_master_API(body);
      setShowLoader(false);
      if (response.success) {
        toast(response.message, {
          toastId: "1",
        });
        store.dispatch(get_bank_master_list(obj));
      } else if (!response.success) {
        toast(response.message, {
          toastId: "1",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    store.dispatch(get_bank_master_list(obj));
  }, [entries, pageNo]);

  const handleDeleteProduct = async (deleteid) => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", deleteid);
      const response = await bank_master_API(body);
      setShowLoader(false);
      if (response.success) {
        toast(response.message);
        setShowDeleteAlert(false);
        if (bank_master_list?.length < 2) {
          setPageNo(pageNo - 1);
          store.dispatch(
            get_bank_master_list({
              pageNo: pageNo - 1,
              entries: entries,
              setTotalCount: setTotalCount,
            })
          );
        } else {
          store.dispatch(get_bank_master_list(obj));
        }
      } else if (!response.success) {
        setOpen(true);
        setMess(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddData = () => {
    if (addData.length === 0) {
      setAddData((prevData) => [
        ...prevData,
        {
          id: addData.length + 1,
          bank_code: "",
          account_type: "",
          account_no: "",
          branch: "",
          ifsc_code: "",
          address: "",
          opening_balance: "0.00",
        },
      ]);
    } else {
      if (!validation_check()) {
        setAddData((prevData) => [
          ...prevData,
          {
            id: addData.length + 1,
            bank_code: "",
            account_type: "",
            account_no: "",
            branch: "",
            ifsc_code: "",
            opening_balance: "0.00",
          },
        ]);
      } else {
        setOpen(true);
        setMess(
          "Please enter all the values in current row,calculate and then add next row"
        );
      }
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addData.filter((item) => item.id !== deleteId);
    setAddData(filterData);
    setEdit(addData?.length - 1);
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.nick_name ||
          (v.nick_name && v.nick_name?.trim()?.length == 0) ||
          !v?.bank_code ||
          (v.bank_code && v.bank_code.length == 0) ||
          !v.account_type ||
          (v.account_type && v.account_type.length == 0) ||
          !v.account_no ||
          (v.account_no && v.account_no.length == 0) ||
          !v.branch ||
          (v.branch && v.branch.length == 0) ||
          !v.ifsc_code ||
          (v.ifsc_code && v.ifsc_code.length == 0) ||
          !v.address ||
          (v.address && v.address.length == 0) ||
          !v.opening_balance ||
          (v.opening_balance && v.opening_balance.length == 0)
      )
    ) {
      return true;
    }
  };

  const valiDate = () => {
    const data = [];
    for (let v of addData) {
      if (!v?.bank_code || (v.bank_code && v.bank_code.length == 0)) {
        data.push("Please Select BANK NAME.");
      }
      if (!v?.nick_name || (v.nick_name && v.nick_name?.trim()?.length == 0)) {
        data.push("Please Enter NICK NAME.");
      }
      if (
        !v?.account_type ||
        (v?.account_type && v?.account_type.length === 0)
      ) {
        data.push("Please select ACCOUNT TYPE");
      }
      if (!v?.account_no || (v?.account_no && v?.account_no.length === 0)) {
        data.push("Please Enter Account Number");
      } else if (!checkNumber(v?.account_no)) {
        data.push("Enter valid Account Number");
      } else if (v?.account_no.length <= 10) {
        data.push("ACCOUNT NUMBER Must Contain Atleast Ten Digits");
      }
      if (!v?.branch || (v?.branch && v?.branch.trim()?.length === 0)) {
        data.push("Please Enter BANK BRANCH.Enter NA If Not Available.");
      }
      if (!v?.ifsc_code || (v?.ifsc_code && v?.ifsc_code.length === 0)) {
        data.push("Please Enter IFSC code ");
      } else if (!checkIFSC(v?.ifsc_code)) {
        data.push("Enter valid IFSC code");
      } else if (v?.ifsc_code.length < 11) {
        data.push("IFSC Code Must Contain Atleast 11 Digits");
      }
      if (!v?.address || (v?.address && v?.address?.trim()?.length === 0)) {
        data.push("Please Enter BANK ADDRESS.Enter NA If Not Available.");
      }
      if (
        !v?.opening_balance ||
        (v?.opening_balance && v?.opening_balance.length === 0)
      ) {
        data.push("Please Enter Opening Balance");
      }
    }
    function findDuplicates(arr) {
      let duplicates = [];
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (
            arr[i]?.account_no === arr[j]?.account_no &&
            !duplicates.includes(arr[i]?.account_no)
          ) {
            duplicates.push(arr[i]);
          }
        }
      }
      return duplicates;
    }
    const duplicateData = findDuplicates(addData);
    if (duplicateData?.length > 0) {
      data.push(
        "ACCOUNT NUMBER already exist.Please enter different ACCOUNT NUMBER"
      );
    }
    return data;
  };

  const add_items = async (e) => {
    const data = valiDate();
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addData.forEach((item) => {
          if (item) {
            body.append("nick_name[]", item?.nick_name?.trim());
            body.append("bankcode[]", item?.account_type);
            body.append("bname[]", item?.bank_code);
            body.append("account[]", item?.account_no);
            body.append("branch[]", item?.branch?.trim());
            body.append("ifsccode[]", item?.ifsc_code);
            body.append("opening[]", item?.opening_balance);
            body.append("address[]", item?.address?.trim());
            body.append("default_bank_account[]", 0);
          }
        });
        const responce = await bank_master_API(body);
        setShowLoader(false);
        if (responce.success) {
          setAddData([]);
          store.dispatch(get_bank_master_list(obj));
          toast(responce.message);
          setIsAdd(false);
        } else {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    } else {
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {data.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
      setOpen(true);
    }
  };
  const update_account = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "update");
      body.append("agencyid", user_id);
      body.append("id", editItem?.ID);
      body.append("ob", editItem?.ACC_OB);
      body.append("acc_type", editItem?.ACCOUNT_TYPE);

      const response = await bank_master_API(body);
      setShowLoader(false);
      if (response.success) {
        toast(response.message, {
          toastId: "1",
        });
        store.dispatch(get_bank_master_list(obj));
        setShowEdit(false);
        setEditItem({});
      } else if (!response.success) {
        toast(response.message, {
          toastId: "1",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const update_details = async () => {
    const data = validate2();
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "updatedata");
        body.append("agencyid", user_id);
        body.append("id", itemData?.ID);
        body.append("branch", itemData?.BANK_BRANCH);
        body.append("ifsc", itemData?.BANK_IFSC_CODE);
        body.append("address", itemData?.BANK_ADDR);
        body.append("nick_name", itemData?.NICK_NAME?.trim());
        if (
          itemData?.BANK_CODE == "LOAN" ||
          itemData?.BANK_CODE == "OVER DRAFT"
        ) {
          body.append("credit_limit", itemData?.credit_limit);
        } else {
          body.append("credit_limit", "");
        }
        const responce = await bank_master_API(body);

        setShowLoader(false);
        if (responce.success) {
          toast(responce.message);
          store.dispatch(get_bank_master_list(obj));
          setOpenEd(false);
          setItemData({});
        } else {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {data.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
      setOpen(true);
    }
  };

  const validate2 = () => {
    const data = [];
    if (
      !itemData?.BANK_BRANCH ||
      (itemData?.BANK_BRANCH && itemData?.BANK_BRANCH?.trim()?.length === 0)
    ) {
      data.push("Please Enter BANK BRANCH.Enter NA If Not Available.");
    }
    if (
      !itemData?.BANK_IFSC_CODE ||
      (itemData?.BANK_IFSC_CODE && itemData?.BANK_IFSC_CODE?.length === 0)
    ) {
      data.push("Please Enter IFSC code ");
    } else if (!checkIFSC(itemData?.BANK_IFSC_CODE)) {
      data.push("Enter valid IFSC code");
    } else if (itemData?.BANK_IFSC_CODE?.length < 11) {
      data.push("IFSC Code Must Contain Atleast 11 Digits");
    }
    if (
      !itemData?.BANK_ADDR ||
      (itemData?.BANK_ADDR && itemData?.BANK_ADDR?.trim()?.length === 0)
    ) {
      data.push("Please Enter BANK ADDRESS.Enter NA If Not Available.");
    }
    if (
      (itemData?.BANK_CODE == "LOAN" || itemData?.BANK_CODE == "OVER DRAFT") &&
      (itemData?.credit_limit <= 0 || !itemData?.credit_limit)
    ) {
      data.push("Please Enter CREDIT LIMIT.");
    }
    if (
      !itemData?.NICK_NAME ||
      (itemData?.NICK_NAME && itemData?.NICK_NAME.trim()?.length === 0)
    ) {
      data.push("Please Enter Nick Name.");
    }
    return data;
  };

  const fetch_data = async () => {
    if (
      addData[addData?.length - 1]?.account_no !== "" &&
      addData[addData?.length - 1]?.account_no?.length > 11 &&
      addData[addData?.length - 1]?.account_no?.length < 21
    ) {
      try {
        const body = new FormData();
        body.append("action", "fetchDelBank");
        body.append("agencyid", user_id);
        body.append("acc_no", addData[addData?.length - 1]?.account_no);
        const response = await bank_master_API(body);
        const res = response;
        if (res.success) {
          setEdit(addData?.length);
          const data = res?.data;
          toast(res.message);
          setAddData((prevData) => {
            const updatedData = [...prevData];
            updatedData[addData?.length - 1] = {
              ...updatedData[addData?.length - 1],
              ifsc_code: data?.IFSC_CODE,
              branch: data?.Branch,
              address: data?.Address,
              opening_balance: data?.opening_balance,
              fetch_data: true,
              account_type: data?.account_type,
              bank_code: data?.name,
              nick_name: data?.NICK_NAME,
            };
            return updatedData;
          });
        } else {
          setEdit(addData?.length - 1);
          setAddData((prevData) => {
            const updatedData = [...prevData];
            updatedData[addData?.length - 1] = {
              ...updatedData[addData?.length - 1],
              fetch_data: false,
            };
            return updatedData;
          });
          if (res?.message == "This account number already exitst") {
            setAddData((prevData) => {
              const updatedData = [...prevData];
              updatedData[addData?.length - 1] = {
                ...updatedData[addData?.length - 1],
                account_no: "",
              };
              return updatedData;
            });
            setMess(res?.message);
            setOpen(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  };

  const sort_data_function = useMemo(() => {
    return (item) => {
      if (
        item?.BANK_CODE === "STAR ACCOUNT" ||
        item?.BANK_CODE === "TAR ACCOUNT" ||
        item?.BANK_CODE === "CASH ACCOUNT" ||
        item?.BANK_CODE === "SUSPENSE ACCOUNT" ||
        item?.BANK_CODE === "SUSPENCE"
      ) {
        return true;
      } else {
        return false;
      }
    };
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={"BANK MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setIsAdd(true);
                handleAddData();
              }}
            />
            {isAdd && addData.length != 0 && (
              <SaveButton title={"SAVE"} onClick={() => add_items()} />
            )}
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/3c0I3xKMvPQ",
                "https://youtu.be/labIxXuo5Co",
              ]}
            />
          </>
        }
      />

      {isAdd && addData.length != 0 && (
        <>
          <Table
            mt={30}
            headData={[
              "NICK NAME",
              "BANK NAME",
              "ACCOUNT TYPE",
              "ACCOUNT NO",
              "BRANCH",
              "IFSC CODE",
              "ADDRESS",
              "OPENING BALANCE",
              "ACTIONS",
            ]}
            body={
              <>
                {addData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        value={item?.nick_name}
                        placeholder={"NICK NAME"}
                        onChange={(event) => {
                          if (
                            event.target.value.length <= 20 &&
                            (alfaNumeric.test(event.target.value) ||
                              event.target.value === "")
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                nick_name: event.target.value,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Dropdown
                        disabled={item?.fetch_data}
                        value={item.bank_code}
                        onChange={(e) => {
                          setAddData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              bank_code: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option hidden value="">
                              SELECT
                            </option>
                            {bankDetails?.map((v, i) => (
                              <option value={v.value} key={i}>
                                {v.bankName}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Dropdown
                        disabled={item?.fetch_data}
                        value={item.account_type}
                        onChange={(e) => {
                          setAddData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              account_type: e.target.value,
                            };
                            return updatedData;
                          });
                        }}
                        options={
                          <>
                            <option value="">SELECT</option>
                            <option value="SAVINGS">SAVINGS</option>
                            <option value="CURRENT">CURRENT</option>
                            <option value="LOAN">LOAN</option>
                            <option value="OVER DRAFT">OVER DRAFT</option>
                          </>
                        }
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        placeholder={"ACCOUNT NUMBER"}
                        value={item.account_no}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (
                            /^\d{0,20}$/.test(inputValue) ||
                            inputValue === ""
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                account_no: inputValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                        onBlur={fetch_data}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        value={item.branch}
                        placeholder={"BRANCH"}
                        disabled={item?.fetch_data}
                        onChange={(event) => {
                          if (
                            event.target.value.length <= 20 &&
                            (alfaNumeric.test(event.target.value) ||
                              event.target.value === "")
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                branch: event.target.value,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        disabled={item?.fetch_data}
                        value={item.ifsc_code}
                        placeholder={"IFSC CODE"}
                        onChange={(event) => {
                          const inputValue = event.target.value.toUpperCase();
                          if (
                            /^[a-zA-Z0-9]{0,11}$/.test(inputValue) ||
                            inputValue === ""
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                ifsc_code: inputValue,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        disabled={item?.fetch_data}
                        value={item.address}
                        placeholder={"ADDRESS"}
                        onChange={(event) => {
                          if (
                            (event.target.value.length <= 25 &&
                              alfaNumeric.test(event.target.value)) ||
                            event.target.value === ""
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                address: event.target.value,
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <Input
                        value={item.opening_balance}
                        disabled={item?.fetch_data}
                        placeholder={"OPENING BALANCE"}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (
                            (inputValue?.length < 11 &&
                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                inputValue
                              )) ||
                            event.target.value == 0
                          ) {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                opening_balance:
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )
                                    ? inputValue
                                    : "",
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "250px" }}>
                      <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </>
      )}
      
      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {bank_master_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          totalCount={totalCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
          setSearchInput={setSearchInput}
          SerchInput={SerchInput}
          mt={30}
          nodata={
            !bank_master_loading && bank_master_list.length != 0 ? false : true
          }
          headData={headData}
          body={bank_master_list.map((item, i) => (
            <tr key={i}>
              <td
                style={{
                  alignItems: "center",
                  cursor: sort_data_function(item) === true ? "" : "pointer",
                  display: "flex",
                }}
              >
                {sort_data_function(item) === true ? (
                  ""
                ) : (
                  <Radio
                    show={item?.DA_STATUS == "1" ? true : false}
                    setShow={() => setShowRadio(item?.ID)}
                  />
                )}
                <p
                  onClick={() => {
                    if (sort_data_function(item) === true) {
                    } else {
                      setOpenEd(true);
                      setItemData(item);
                    }
                  }}
                >
                  {(item?.BANK_CODE == "STAR ACCOUNT" && "SV/TV ACCOUNT") ||
                    (item?.BANK_CODE == "TAR ACCOUNT" && "LOAD ACCOUNT") ||
                    (item?.BANK_CODE == "CASH ACCOUNT" && "CASH ACCOUNT") ||
                    (item?.BANK_CODE == "SUSPENSE ACCOUNT " &&
                      "SUSPENCE ACCOUNT") ||
                    bankDetails.filter((v) => v.value == item?.BANK_NAME)[0]
                      ?.bankName}
                </p>
              </td>
              <td>
                {sort_data_function(item) === true
                  ? "NA"
                  : item?.NICK_NAME || "-"}
              </td>
              <td>
                {(item?.BANK_CODE == "STAR ACCOUNT" && "OMC ") ||
                  (item?.BANK_CODE == "TAR ACCOUNT" && "OMC ") ||
                  (item?.BANK_CODE == "CASH ACCOUNT" && "CASH") ||
                  (item?.BANK_CODE == "SUSPENSE ACCOUNT " && "SUSPENCE") ||
                  item?.BANK_CODE}
              </td>
              <td>
                {sort_data_function(item) === true ? "NA" : item?.BANK_ACC_NO}
              </td>
              <td>
                {sort_data_function(item) === true ? "NA" : item?.BANK_BRANCH}
              </td>
              <td>
                {sort_data_function(item) === true
                  ? "NA"
                  : item?.BANK_IFSC_CODE}
              </td>
              <td>
                {sort_data_function(item) === true
                  ? "NA"
                  : item?.BANK_ADDR || "-"}
              </td>
              <td>{item?.ACC_OB}</td>
              <td>{item?.ACC_CB}</td>
              <td>
                {sort_data_function(item) === true ||
                item?.BANK_CODE == "SAVINGS" ||
                item?.BANK_CODE == "CURRENT"
                  ? "NA"
                  : item?.credit_limit}
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                {sort_data_function(item) === true ? (
                  "-"
                ) : (
                  <>
                    {item?.DA_STATUS != "1" ? (
                      <DeleteIcon
                        onClick={() => {
                          setDeleteId(item?.ID);
                          setShowDeleteAlert(true);
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </>
                )}
                {/* {((item.BANK_CODE == "STAR ACCOUNT" ||
                  item.BANK_CODE == "TAR ACCOUNT" ||
                  item.BANK_CODE == "CASH ACCOUNT" ||
                  item.BANK_CODE == "SUSPENSE ACCOUNT ") &&
                  item.ACC_OB == "0.00") ||
                item.ACC_OB == "0" ? (
                  <EditButton
                    onClick={() => {
                      setEditItem(item);
                      setShowEdit(true);
                    }}
                  />
                ) : (
                  ""
                )} */}
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {showEdit && (
        <SmallModal
          okBtnHide={false}
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          style={{ minWidth: "35rem" }}
          okbtn={setShowEdit}
          title={"ENTER BANK BALANCE"}
          mess={
            <div className="pin-ver">
              <div style={{ margin: "1rem 0" }}>
                If you have credit balance put a negative sign.
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  margin: "1rem",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label style={{ width: "40%", fontSize: "14px" }}>
                    Enter Account Balance:
                  </label>
                  <div style={{ width: "60%" }}>
                    <Input
                      placeholder={" Enter Account Balance"}
                      value={editItem?.ACC_OB}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        if (
                          (inputValue?.length < 11 &&
                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                              inputValue
                            )) ||
                          event.target.value == 0 ||
                          !editItem?.ACC_OB
                        ) {
                          setEditItem({
                            ...editItem,
                            ACC_OB: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ width: "30%", padding: "0 5% " }}>
                  <Button onClick={update_account} title={"SUBMIT"} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {openEd && (
        <Modal
          isOpen={openEd}
          setIsOpen={setOpenEd}
          title={"BANK MASTER"}
          header_right={<SaveButton onClick={update_details} />}
          body={
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
                className="credit-limit"
              >
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"BANK NAME"}
                    value={
                      bankDetails?.filter(
                        (v) => v?.value == itemData?.BANK_NAME
                      )[0]?.bankName
                    }
                    disabled={true}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"ACCOUNT TYPE"}
                    value={itemData?.BANK_CODE}
                    disabled={true}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"ACCOUNT NO"}
                    value={itemData?.BANK_ACC_NO}
                    disabled={true}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"NICK NAME"}
                    placeholder={"NICK NAME"}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 20 &&
                        (alfaNumeric.test(e.target.value) ||
                          e.target.value === "")
                      ) {
                        setItemData({
                          ...itemData,
                          NICK_NAME: e.target.value,
                        });
                      }
                    }}
                    value={itemData?.NICK_NAME}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"BRANCH"}
                    placeholder={"BRANCH"}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 20 &&
                        (alfaNumeric.test(e.target.value) ||
                          e.target.value === "")
                      ) {
                        setItemData({
                          ...itemData,
                          BANK_BRANCH: e.target.value,
                        });
                      }
                    }}
                    value={itemData?.BANK_BRANCH}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"IFSC CODE"}
                    placeholder={"IFSC CODE"}
                    onChange={(e) => {
                      const inputValue = e.target.value.toUpperCase();
                      if (
                        /^[a-zA-Z0-9]{0,11}$/.test(inputValue) ||
                        inputValue === ""
                      ) {
                        setItemData({
                          ...itemData,
                          BANK_IFSC_CODE: inputValue,
                        });
                      }
                    }}
                    value={itemData?.BANK_IFSC_CODE}
                  />
                </div>
                <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                  <Input
                    label={"ADDRESS"}
                    placeholder={"ADDRESS"}
                    onChange={(e) => {
                      if (
                        (e.target.value.length <= 25 &&
                          alfaNumeric.test(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        setItemData({ ...itemData, BANK_ADDR: e.target.value });
                      }
                    }}
                    value={itemData?.BANK_ADDR}
                  />
                </div>
                {(itemData?.BANK_CODE == "LOAN" ||
                  itemData?.BANK_CODE == "OVER DRAFT") && (
                  <div style={{ width: "210px", padding: ".15rem .5rem" }}>
                    <Input
                      label={
                        itemData?.BANK_CODE == "OVER DRAFT"
                          ? "SANCTIONED LIMIT"
                          : "CREDIT LIMIT"
                      }
                      placeholder={
                        itemData?.BANK_CODE == "OVER DRAFT"
                          ? "SANCTIONED LIMIT"
                          : "CREDIT LIMIT"
                      }
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length < 12 &&
                            /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                              inputValue
                            )) ||
                          e.target.value == 0 ||
                          !itemData?.credit_limit
                        ) {
                          setItemData({
                            ...itemData,
                            credit_limit: /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                      value={itemData?.credit_limit}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  marginTop: "1rem",
                  gap: ".5rem",
                }}
              ></div>
            </div>
          }
        />
      )}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteProduct(DeleteId)}
          title={"Confirm"}
          mess={"Are You Sure you want to delete"}
        />
      )}

      {showPinVerify && (
        <PinVerify
          showPinVerify={showPinVerify}
          setShowPinVerify={setShowPinVerify}
          setShowLoader={setShowLoader}
          setAlertOpen={setOpen}
          setAlertMess={setMess}
        />
      )}
      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alert"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {ShowLoader && <Loader />}
    </div>
  );
};
