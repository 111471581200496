import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomList from "./CustomList";
import { FiChevronRight } from "react-icons/fi";

export const ProfileList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  return (
    <div >
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"Agency Information"}
        style={{
          backgroundColor: path == "/profile/Ainfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/Ainfo");
        }}
      />
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"Personal Information"}
        style={{
          backgroundColor: path == "/profile/Pinfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/Pinfo");
        }}
      />
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"User Settings"}
        style={{
          backgroundColor: path == "/profile/Uinfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/Uinfo");
        }}
      />
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"E-Invoice and EWay Bill Enabling"}
        style={{
          backgroundColor: path == "/profile/InvoiceBillInfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/InvoiceBillInfo");
        }}
      />
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"Security Settings"}
        style={{
          backgroundColor: path == "/profile/Settinginfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/Settinginfo");
        }}
      />
      <CustomList
        icon={<FiChevronRight size={18} />}
        fd="row-reverse"
        title={"Personal Preferences"}
        style={{
          backgroundColor: path == "/profile/PPinfo" ? "white" : "",
        }}
        onClick={() => {
          navigate("/profile/PPinfo");
        }}
      />
    </div>
  );
};
