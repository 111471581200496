import React, { useState } from 'react';
import PageHeading from '../../../components/PageHeading';
import { BackButton } from '../../../components/btn/Buttons';
import Dropdown from '../../../components/inputs/Dropdown';

const CapitalAccSearch = () => {
  const [financialYear, setFinancialYear] = useState('');
  const [partnerName, setPartnerName] = useState('');

  const handleSearch = () => {
    // Implement search logic here
    console.log(`Searching for financial year: ${financialYear}, partner: ${partnerName}`);
  };

  return (
    <div className="container">
      <PageHeading
        title="CAPITAL ACCOUNT SEARCH"
        right={<BackButton />}
      />
      <div style={{ height: "20px" }}></div>
      <div className="df mt10 gap10">
        <div style={{ width: "180px" }}>
          <Dropdown
            label="FINANCIAL YEAR"
            value={financialYear}
            onChange={e => setFinancialYear(e.target.value)}
            options={
              <>
                <option value="">SELECT</option>
                <option value="2019-20">2019-20</option>
                <option value="2020-21">2020-21</option>
                <option value="2021-22">2021-22</option>
                <option value="2022-23">2022-23</option>
                <option value="2023-24">2023-24</option>
                <option value="2024-25">2024-25</option>
              </>
            }
          />
        </div>
        <div style={{ width: "180px" }}>
          <Dropdown
            label="PARTNER NAME"
            value={partnerName}
            onChange={e => setPartnerName(e.target.value)}
            options={
              <>
                <option value="">SELECT</option>
                <option value="john">John</option>
                <option value="emily">Emily</option>
                <option value="carlos">Carlos</option>
                <option value="sarah">Sarah</option>
                <option value="mike">Mike</option>
                {/* Dynamic options should be rendered here */}
              </>
            }
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <button className="GENERATE-BTN">SEARCH</button>
        </div>
      </div>
    </div>
  );
};

export default CapitalAccSearch;
