import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import Table from "../../components/table/Table";
import { useSelector } from "react-redux";
import "./trasactionReport.css";
import { downloadExcel, downloadExcel1, exportPDF } from "../../utils/utils";
import moment from "moment";
import { DateValidator2 } from "../../utils/validation";
import { stock_report_api } from "../../utils/apis";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import { Pagination2 } from "../../components/pagination/pagination";
import { get_stock_report_list } from "./transactionReportSlice";
import store from "../../redux/store";
import { Alerts } from "../../components/Alerts/Alerts";

const StockReport = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("1");
  const [entries, setEntries] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [stock_report_item_list, setStock_report_item_list] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const { stock_report_list } = useSelector((state) => state.transactionReport);
  const { eff_date, user_id, today } = useSelector((state) => state.other);
  useEffect(() => {
    store.dispatch(get_stock_report_list());
  }, []);
  const { firm_name } = useSelector((state) => state.profile);

  const table_data = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.PRODUCT_NAME,
        v?.INV_NO,
        v?.TRANS_DATE,
        v?.STOCK_FLAG_NAME,
        v?.FULLS,
        v?.CS_FULLS,
        v?.CS_EMPTYS,
        v?.CREATED_DATE,
      ]),
    ];

    return data;
  };

  const table_data_2 = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        // v?.PRODUCT_NAME,
        // v?.OPENING_STOCK,
        v?.TOTAL_PURCHASES,
        v?.TOTAL_PURCHASE_RETURNS,
        v?.TOTAL_SALES,
        v?.TOTAL_SALES_RETURNS,
        v?.CLOSING_STOCK,
        v?.CLOSING_STOCK_EMPTIES,
        v?.TOTAL_STOCK,
      ]),
    ];

    return data;
  };
  const table_data_3 = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.PRODUCT_NAME,
        v?.OPENING_STOCK,
        v?.TOTAL_PURCHASES,
        v?.TOTAL_PURCHASE_RETURNS,
        v?.TOTAL_SALES,
        v?.TOTAL_SALES_RETURNS,
        v?.CLOSING_STOCK,
        v?.CLOSING_STOCK_EMPTIES,
        v?.TOTAL_STOCK,
      ]),
    ];

    return data;
  };
  const table_header_1 = [
    // "OPENING STOCK",
    "PRODUCT NAME",
    "INV NUMBER",
    "INV DATE",
    "TRANSACTION TYPE",
    // "TRANSACTION NAME",
    "QUANTITY",
    "CLOSING STOCK",
    "CLOSING STOCK EMPTIES",
    "created Date",
  ];

  const table_header = [
    // "PRODUCT NAME",
    "OPENING STOCK",
    "TOTAL PURCHASES",
    "TOTAL PURCHASE RETURNS",
    "TOTAL SALES",
    "TOTAL SALES RETURNS",
    "CLOSING STOCK",
    "CLOSING STOCK EMPTIES",
    "TOTAL STOCK",
  ];

  const export_pdf = (addData, data, total) => {
    if (addData?.action == "godown") {
      exportPDF({
        table_data: table_data_3(data, total),
        table_headers: ["S.No.", "Product Name", ...table_header],
        fileName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`,
        tableName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`,
        table2: false,
      });
    } else {
      exportPDF({
        table_data: table_data(data, total),
        table_headers: ["S.No.", ...table_header_1],
        fileName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`,
        tableName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`,
        table2: true,
        table_headers_2: table_header?.filter((v) => v != "OPENING STOCK"),
        table_data_2: table_data_2(totalData, total),
      });
    }
  };

  const export_excel = (addData, data, total) => {
    if (addData?.action == "godown") {
      downloadExcel(
        table_data_3(data, total),
        ["S.No.", "Product Name", ...table_header],
        `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`
      );
    } else {
      downloadExcel1(
        table_data(data, total),
        ["S.No.", ...table_header_1],
        `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
          addData?.from_date
        ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
          "DD/MM/YY"
        )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
          addData?.custProdDetails
        }`,
        table_data_2(totalData, total),
        table_header
      );
    }
  };
  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", addData?.action);
        body.append("agencyid", user_id);
        body.append("sdate", addData?.from_date);
        body.append("edate", addData?.to_date);
        body.append("id", addData?.godown_id || "");
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        if (addData?.action == "godown") {
          body.append("godown_id", addData?.product_code || "");
        } else {
          body.append("prod_code", addData?.product_code || "");
          body.append("prod_cat", addData?.product_cat || "");
        }

        const response = await stock_report_api(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.data || response?.productlist,
              response?.total
            );
          } else {
            // setStock_report_item_list(response?.data || response?.productlist);
            setStock_report_item_list(response?.data);
            setTotalCount(response?.data?.length);
            setTotalData(response?.total);
            setShowData(true);
            setAddDataPre(addData);
          }
        } else if (!response.success) {
          setStock_report_item_list([]);
          setTotalCount(0);
          setTotalData([]);
          setShowData(false);
          setAddDataPre([]);
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      DateValidator2(
        moment(addData?.from_date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.from_date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `From Date Date cant be before ${eff_date} `,
          `From Date Date cant be a future date`
        )
      );
    }
    if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (
      DateValidator2(
        moment(addData?.to_date).format("DD/MM/YYYY"),
        today,
        addData?.from_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.to_date).format("DD/MM/YYYY"),
          today,
          addData?.from_date,
          `To Date cant be before ${moment(addData?.from_date).format(
            "DD/MM/YYYY"
          )} `,
          `To Date cant be a future date`
        )
      );
    }
    if (!addData?.action || (addData?.action && addData?.action?.length == 0)) {
      data.push("Please Select Party Type");
    } else if (
      !addData?.product_code ||
      (addData?.product_code && addData?.product_code?.length == 0)
    ) {
      data.push("Please Select Either PRODUCT / GODOWN");
    } else if (
      addData?.show_godown &&( !addData?.godown_id ||
      (addData?.godown_id && addData?.godown_id?.length == 0))
    ) {
      data.push("Please Select GODOWN ");
    }


    return data;
  };
  return (
    <div className="container ">
      <PageHeading title={"STOCK REPORT"} right={<BackButton />} />
      <div className="transactionMain">
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
            type={"date"}
          />
        </div>

        <div style={{ width: "180px" }}>
          <Dropdown
            label={"Select Party type"}
            value={addData?.action}
            onChange={(e) => {
              setAddData({
                ...addData,
                action: e.target.value,
                product_code: "",
                product_cat: "",
                show_godown: false,
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                <option value={"product"}> PRODUCT WISE</option>
                <option value={"godown"}> GODOWN WISE</option>
              </>
            }
          />
        </div>

        <div style={{ width: "180px" }}>
          {console.log(
            "stock_report_list?.godownlist ===",
            stock_report_list?.godownlist
          )}
          <Dropdown
            label={"PRODUCT / GODOWN"}
            value={addData?.product_code}
            onChange={(e) => {
              // const data = [
              //   ...stock_report_list?.productlist,
              //   ...stock_report_list?.godownlist,
              // ]?.filter((v) => v?.ID == e.target.value)?.[0];

              const data = (
                addData?.action == "product"
                  ? stock_report_list?.productlist
                  : addData?.action == "godown"
                  ? stock_report_list?.godownlist
                  : []
              )?.filter((v) => v?.ID == e.target.value)?.[0];

              console.log("data ===", data);
              setAddData({
                ...addData,
                product_code: e.target.value,
                show_godown: data?.CAT_NAME
                  ? data?.CAT_NAME == "REGULATOR" ||
                    data?.CAT_NAME == "COMMERCIAL" ||
                    data?.CAT_NAME == "DOMESTIC"
                    ? true
                    : false
                  : false,
                godown_id: data
                  ? data?.CAT_NAME == "REGULATOR" ||
                    data?.CAT_NAME == "COMMERCIAL" ||
                    data?.CAT_NAME == "DOMESTIC"
                    ? addData?.godown_id
                    : ""
                  : "",
                product_cat: data ? data?.CAT_TYPE : "",
                custProdDetails: data
                  ? data?.CAT_NAME && data?.CAT_DESC
                    ? `${data?.CAT_NAME} - ${data?.CAT_DESC}`
                    : data?.GODOWN_NAME
                  : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                {(addData?.action == "product"
                  ? stock_report_list?.productlist
                  : addData?.action == "godown"
                  ? stock_report_list?.godownlist
                  : []
                )
                  ?.filter(
                    (obj, index, self) =>
                      index === self.findIndex((t) => t?.ID === obj?.ID)
                  )
                  ?.map((v, i) => (
                    <option value={v?.ID} key={i}>
                      {addData?.action == "product"
                        ? `${v?.CAT_NAME} - ${v?.CAT_DESC}`
                        : `${v?.UNIT_NAME}`}
                    </option>
                  ))}
              </>
            }
          />
        </div>
        {addData?.show_godown && (
          <div style={{ width: "180px" }}>
            <Dropdown
              label={"SELECT GODOWN"}
              value={addData?.godown_id}
              onChange={(e) => {
                const data = [...stock_report_list?.godownlist]?.filter(
                  (v) => v?.ID == e.target.value
                )?.[0];
                setAddData({
                  ...addData,
                  godown_id: e.target.value,
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {stock_report_list?.godownlist
                    ?.filter(
                      (obj, index, self) =>
                        index === self.findIndex((t) => t?.ID === obj?.ID)
                    )
                    ?.map((v, i) => (
                      <option value={v?.ID} key={i}>
                        {v?.UNIT_NAME}
                      </option>
                    ))}
                </>
              }
            />
          </div>
        )}
        {/* <div style={{ width: "180px" }}>
          <Dropdown
            label={"SELECT GODOWN"}
            value={addData?.godown_id}
            onChange={(e) => {
              const data = [...stock_report_list?.godownlist]?.filter(
                (v) => v?.ID == e.target.value
              )?.[0];
              setAddData({
                ...addData,
                product_code: e.target.value,
                show_godown: data
                  ? data?.CAT_NAME == "REGULATOR" ||
                    data?.CAT_NAME == "COMMERCIAL" ||
                    data?.CAT_NAME == "DOMESTIC"
                    ? true
                    : false
                  : false,
                product_cat: data ? data?.CAT_TYPE : "",
                custProdDetails: data
                  ? data?.CAT_NAME && data?.CAT_DESC
                    ? `${data?.CAT_NAME} - ${data?.CAT_DESC}`
                    : data?.GODOWN_NAME
                  : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                {stock_report_list?.godownlist
                  ?.filter(
                    (obj, index, self) =>
                      index === self.findIndex((t) => t?.ID === obj?.ID)
                  )
                  ?.map((v, i) => (
                    <option value={v?.ID} key={i}>
                      {v?.CAT_NAME} - {v?.CAT_DESC}
                    </option>
                  ))}
              </>
            }
          />
        </div> */}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            // onClick={() => get_list(addData)}
            onClick={async () => {
              await setShowData(false);
              await setPageNo(1);
              await setEntries(5);
              get_list(addData, "main");
            }}
          />
        </div>
      </div>
      {showData && stock_report_item_list?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>
              {addDataPre?.action == "godown" ? "GODOWN" : "PRODUCT"} SELECTED:
            </span>
            <h6 className="PDS">{addDataPre?.custProdDetails}</h6>
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {stock_report_item_list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <>
              {addDataPre?.action == "product" && (
                <Table
                  headData={table_header_1}
                  body={
                    <>
                      {stock_report_item_list
                        ?.slice(
                          (pageNo - 1) * entries,
                          (pageNo - 1) * entries + entries
                        )
                        ?.map((v, i) => (
                          <tr key={i}>
                            {/* <td>{v?.FULLS}</td> */}
                            <td>{v?.PRODUCT_NAME}</td>
                            <td>{v?.INV_NO}</td>
                            <td>
                              {v?.TRANS_DATE}
                              {/* {moment().format(
                                "DD-MMM-YYYY"
                              )} */}
                            </td>
                            <td>{v?.STOCK_FLAG_NAME}</td>
                            <td>{v?.FULLS}</td>
                            <td>{v?.CS_FULLS}</td>
                            <td>{v?.CS_EMPTYS}</td>
                            <td>{v?.CREATED_DATE}</td>
                          </tr>
                        ))}
                    </>
                  }
                />
              )}

              <Table
                headData={
                  addDataPre?.action == "godown"
                    ? ["Product Name", ...table_header]
                    : table_header?.filter((v) => v != "OPENING STOCK")
                }
                body={
                  <>
                    {(addDataPre?.action == "godown"
                      ? stock_report_item_list
                      : totalData
                    )
                      ?.slice(
                        (pageNo - 1) * entries,
                        (pageNo - 1) * entries + entries
                      )
                      ?.map((v, i) => (
                        <tr key={i}>
                          {addDataPre?.action == "godown" && (
                            <>
                              <td>{v?.PRODUCT_NAME}</td>
                              <td>{v?.OPENING_STOCK}</td>
                            </>
                          )}

                          <td>{v?.TOTAL_PURCHASES}</td>
                          <td>{v?.TOTAL_PURCHASE_RETURNS}</td>
                          <td>{v?.TOTAL_SALES}</td>
                          <td>{v?.TOTAL_SALES_RETURNS}</td>
                          <td>{v?.CLOSING_STOCK}</td>
                          <td>{v?.CLOSING_STOCK_EMPTIES}</td>
                          <td>{v?.TOTAL_STOCK}</td>
                        </tr>
                      ))}
                  </>
                }
              />
            </>
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}
      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default StockReport;

// const export_pdf = (addData, data, total) => {
//   exportPDF({
//     table_data: table_data(data, total),
//     table_headers: ["S.No.", ...table_header_1],
//     fileName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
//       addData?.from_date
//     ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
//       "DD/MM/YY"
//     )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
//       addData?.custProdDetails
//     }`,
//     tableName: `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
//       addData?.from_date
//     ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
//       "DD/MM/YY"
//     )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
//       addData?.custProdDetails
//     }`,
//     table2: true,
//     table_headers_2: table_header,
//     table_data_2: table_data_2(totalData, total),
//   });
// };

// const export_excel = (addData, data, total) => {
//   downloadExcel1(
//     table_data(data, total),
//     ["S.No.", ...table_header_1],
//     `${firm_name} - STOCK REPORTS -- FROM DATE : ${moment(
//       addData?.from_date
//     ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
//       "DD/MM/YY"
//     )}   :: ${addData?.action == "godown" ? "GODOWN" : "PRODUCT"} : : ${
//       addData?.custProdDetails
//     }`,
//     table_data_2(totalData, total),
//     table_header
//   );
// };
