import React from "react";
import "./sidebar.css";
import TransactionList from "../list/TransactionList";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import CashnBankList from "../list/CashnBankList";
import TransReportList from "../list/TransReportList";
import { MdOutlineWatchLater } from "react-icons/md";
import MasterDataList from "../list/MasterDataList";
import PayRollList from "../list/PayRollList";
import { useLocation, useNavigate } from "react-router-dom";
import FinacialReportsList from "../list/FinacialReportsLinst";
import GstReturnList from "../list/GstReturnList";
import TdsTclList from "../list/TdsTcsList";
import { FiHome } from "react-icons/fi";
// import { useWindowSize } from "../../utils/data";
import store from "../../redux/store";
import { setSidebarWidth } from "../../redux/slice/actionSlice";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../utils/Hook/UseWindowSizeHook";

const Sidebar = () => {
  const windowSize = useWindowSize();
  const { focused } = useSelector((state) => state.actions);
  const location = useLocation();
  const pathName = location?.pathname;
  const navigate = useNavigate();
  const handle_close_drawer = () => {
    if (windowSize?.width < 907) {
      {
        store.dispatch(setSidebarWidth(false));
      }
    }
  };
  return (
    <div className="sidebar-main">
      <div className="sidebar-logo">
        <img src={logoicon} className="s_logo" />
        <img src={logotxt} className="s_logotxt" />
      </div>
      <div
        style={{
          backgroundColor: pathName == "/Home" ? "white" : "",
        }}
        className="list-main mt10"
        onClick={() => {
          navigate("/Home");
          handle_close_drawer();
        }}
      >
        <div className="list-icon">
          <FiHome color="#6e82a5" size={18} />
        </div>
        <div className="list-title fs15">Home</div>
      </div>
      <MasterDataList close={handle_close_drawer} path={pathName} focused={focused} />
      <TransactionList close={handle_close_drawer} path={pathName} focused={focused} />
      <CashnBankList close={handle_close_drawer} path={pathName} focused={focused} />
      <GstReturnList close={handle_close_drawer} path={pathName} focused={focused} />
      <TdsTclList close={handle_close_drawer} path={pathName} focused={focused} />
      <TransReportList close={handle_close_drawer} path={pathName} focused={focused} />
      <FinacialReportsList close={handle_close_drawer} path={pathName} focused={focused} />
      <PayRollList close={handle_close_drawer} path={pathName} focused={focused} />
      <div
        className="list-main mt10"
        style={{
          backgroundColor: pathName == "/DayEndPage" ? "white" : "",
        }}
        onClick={() => {
          navigate("/DayEndPage");
          handle_close_drawer();
        }}
      >
        <div className="list-icon">
          <MdOutlineWatchLater color="#6e82a5" size={18} />
        </div>
        <div className="list-title fs15">Day End</div>
      </div>
    </div>
  );
};

export default Sidebar;
