import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import Dropdown from "../../../components/inputs/Dropdown";
import { DeleteIcon, MediumText } from "../../../utils/utils";
import Table from "../../../components/table/Table";
import { useSelector } from "react-redux";
import { get_service_master_list } from "../masterdataSlice";
import store from "../../../redux/store";
import PageLoading from "../../../components/PageLoading";
import moment from "moment";
import TableNew from "../../../components/table/TableWithPagination";
import Loader from "../../../components/Loader";
import {
  service_master_api,
  service_master_api_second,
} from "../../../utils/apis";
import { toast } from "react-toastify";
import { DateValidator2, HSNValidator } from "../../../utils/validation";
import Input from "../../../components/inputs/Input";
import SmallModal from "../../../components/modal/SmallModal";
import Modal from "../../../components/modal/Modal";
import { UOMData } from "../../../utils/data";
import { Alerts } from "../../../components/Alerts/Alerts";
const headData = [
  "SERVICE NAME",
  "SAC CODE",
  "UOM",
  "CHARGES",
  "GST AMOUNT",
  "EFFECTIVE DATE",
  "ACTIONS",
];

const ServicesMaster = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [gstCount, setGstCount] = useState("");
  const [addData, setAddData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [ID, setID] = useState("");
  const [edit, setEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const {
    service_master_list,
    service_master_loading,
    service_master_percent,
  } = useSelector((state) => state.masterdata);
  const { eff_date, user_id, today } = useSelector((state) => state.other);

  console.log("service_master_list ====",service_master_list);

  useEffect(() => {
    store.dispatch(get_service_master_list());
  }, []);

  const change_service_charge = async () => {
    if (
      !gstCount ||
      (gstCount && gstCount?.length == 0) ||
      serviceData.filter((v, i) => v?.id == gstCount)?.[0]?.value ==
        service_master_percent
    ) {
      setOpen(true);
      setMess("This GST % Allready Applicable");
      return;
    }
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "update");
      body.append("agencyid", user_id);
      body.append("gstp", gstCount);
      const responce = await service_master_api(body);
      setShowLoader(false);
      if (responce.success) {
        toast.success(responce.message);
        store.dispatch(get_service_master_list());
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.serv_name?.trim() ||
          (v?.serv_name?.trim() && v?.serv_name.trim().length == 0) ||
          !v?.hsn ||
          (v?.hsn && v?.hsn.length == 0) ||
          !v?.unit ||
          (v?.unit && v?.unit.length == 0) ||
          !v?.charges ||
          (v?.charges && v?.charges.length == 0) ||
          !v?.gst ||
          (v?.gst && v?.gst.length == 0) ||
          !v?.gst_amount ||
          (v?.gst_amount && v?.gst_amount.length == 0) ||
          !v?.total ||
          (v?.total && v?.total.length == 0) ||
          !v?.date ||
          (v?.date && v?.date.length == 0)
      )
    ) {
      return true;
    }
  };
  const calculate = () => {
    if (
      addData.find(
        (v) =>
          !v?.charges ||
          (v?.charges && v?.charges.length == 0) ||
          !v?.gst ||
          (v?.gst && v?.gst.length == 0)
      )
    ) {
      setMess("Please enter Charges and GST%");
      setOpen(true);
    } else {
      addData.forEach((data, index) => {
        const charges = Number(data?.charges);
        const gst = Number(data?.gst);

        const gst_amount = charges * (gst / 100);
        const total_amount = gst_amount + charges;
        setAddData((pre) =>
          pre.map((v, i) =>
            i == index
              ? {
                  ...v,
                  total: Number(total_amount).toFixed(2),
                  gst_amount: Number(gst_amount).toFixed(2),
                }
              : { ...v }
          )
        );
      });
    }
  };

  const valiDate = async () => {
    const data = [];

    for (let v of addData) {
      if (
        !v?.serv_name?.trim() ||
        (v?.serv_name?.trim() && v?.serv_name?.trim().length === 0)
      ) {
        data.push("Please Enter Service Name");
      }
      const data1 = await HSNValidator([v], "SAC code");
      if (!v?.hsn || (v?.hsn && v?.hsn.length === 0)) {
        data.push("Please Enter SAC code.");
      } else if (!/^99\d{4,6}$/.test(v.hsn)) {
        data.push("HSN code should start with '99' and be 6 to 8 digits long.");
      } else if (!/^\d{6,}$/.test(v.hsn)) {
        data.push("SAC code should have a minimum of 6 digits.");
      } else if (!/^\d{1,8}$/.test(v.hsn)) {
        data.push("SAC code should have a maximum of 8 digits.");
      } else if (!/^99/.test(v.hsn)) {
        data.push("Please Enter Valid SAC code.");
      } else if (data1 != true) {
        data.push(...data1);
      }
      if (!v?.unit || (v?.unit && v?.unit.length === 0)) {
        data.push("Please Select UNIT.");
      }
      if (!v?.charges || (v?.charges && v?.charges.length === 0)) {
        data.push("Please Enter Charges.");
      }

      if (!v?.gst || (v?.gst && v?.gst.length === 0)) {
        data.push("Please Select GST%");
      }
      if (!v?.gst_amount || (v?.gst_amount && v?.gst_amount.length === 0)) {
        data.push("Please Click on Calculate");
      } else if (!v?.total || (v?.total && v?.total.length === 0)) {
        data.push("Please Click on Calculate");
      }
      if (!v?.date || (v?.date && v?.date.length === 0)) {
        data.push("Please Enter EFFECTIVE DATE");
      } else if (
        DateValidator2(moment(v?.date).format("DD/MM/YYYY"), today, eff_date) !=
        true
      ) {
        data.push(
          ...DateValidator2(
            moment(addData[addData.length - 1]?.date).format("DD/MM/YYYY"),
            today,
            eff_date,
            `Effective Date cant be before ${moment(eff_date).format(
              "DD/MM/YYYY"
            )} `,
            `Effective Date cant be a future date`
          )
        );
      }
    }
    return data;
  };
  const add_items = async () => {
    const data = await valiDate();
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addData.forEach((v) => {
          body.append("serv_name[]", v?.serv_name.trim());
          body.append("sac_code[]", v?.hsn);
          body.append("uom[]", v?.unit);
          body.append("charges[]", v?.charges);
          body.append("gst[]", v?.gst);
          body.append("gst_amount[]", v?.gst_amount);
          body.append("total[]", v?.total);
          body.append("date[]", v?.date);
        });
        const responce = await service_master_api_second(body);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce.message);
          setAddData([]);
          store.dispatch(get_service_master_list());
        } else if (!responce.success) {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {data.map((item, i) => (
            <p style={{ textAlign: "left" }} key={i}>
              {item}
            </p>
          ))}
        </div>
      );
      setOpen(true);
    }
  };

  const delete_items = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", ID);
      const res = await service_master_api_second(body);
      setShowLoader(false);
      if (res.success) {
        toast(res.message);
        setOpen1(false);
        store.dispatch(get_service_master_list());
      } else {
        toast(res.message);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const update = async () => {
    const data = await valiDate2();
    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "update");
        body.append("agencyid", user_id);
        body.append("id", edit?.ID);
        body.append("serv_name", edit?.CAT_DESC.trim());
        body.append("sac_code", edit?.SAC_CODE);
        body.append("uom", edit?.UOM);
        body.append("charges", edit?.PROD_CHARGES);
        body.append("gst", edit?.GST);
        body.append("gst_amount", edit?.GST_AMT);
        body.append("total", edit?.TOTAL);
        body.append("date", edit?.effective_date);
        const responce = await service_master_api_second(body);
        setShowLoader(false);
        if (responce.success) {
          store.dispatch(get_service_master_list());
          toast.success(responce.message);
          setEdit({});
          setOpenEdit(false);
        } else if (!responce.success) {
          setMess(responce.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(
      <Alerts data={data}/>
      );
      setOpen(true);
    }
  };

  const handle_calculate = async () => {
    if (
      !edit?.PROD_CHARGES ||
      (edit?.PROD_CHARGES && edit?.PROD_CHARGES.length == 0) ||
      !edit?.GST ||
      (edit?.GST && edit?.GST.length == 0)
    ) {
      setMess("Please enter Charges and GST%");
      setOpen(true);
    } else {
      const charges = Number(edit?.PROD_CHARGES);
      const gst = Number(edit?.GST);

      const gst_amount = charges * (gst / 100);
      const total_amount = gst_amount + charges;
      setEdit({
        ...edit,
        TOTAL: Number(total_amount).toFixed(2),
        GST_AMT: Number(gst_amount).toFixed(2),
      });
    }
  };

  const valiDate2 = async () => {
    const data = [];
    if (
      !edit?.CAT_DESC?.trim() ||
      (edit?.CAT_DESC?.trim() && edit?.CAT_DESC?.trim().length === 0)
    ) {
      data.push("Please Enter Service Name");
    }
    const data1 = await HSNValidator(
      [
        {
          hsn: edit?.SAC_CODE,
        },
      ],
      "SAC code"
    );
    if (!edit?.SAC_CODE || (edit?.SAC_CODE && edit?.SAC_CODE.length === 0)) {
      data.push("Please Enter SAC code.");
    } else if (!/^\d{6,}$/.test(edit?.SAC_CODE)) {
      data.push("SAC code should have a minimum of 6 digits.");
    } else if (!/^\d{1,8}$/.test(edit?.SAC_CODE)) {
      data.push("SAC code should have a maximum of 8 digits.");
    } else if (!/^99/.test(edit?.SAC_CODE)) {
      data.push("Please Enter valid SAC code.");
    } else if (data1 != true) {
      data.push(...data1);
    }

    if (!edit?.UOM || (edit?.UOM && edit?.UOM.length === 0)) {
      data.push("Please Select UNIT.");
    }
    if (
      !edit?.PROD_CHARGES ||
      (edit?.PROD_CHARGES && edit?.PROD_CHARGES.length === 0)
    ) {
      data.push("Please Enter Charges.");
    }

    if (!edit?.GST || (edit?.GST && edit?.GST.length === 0)) {
      data.push("Please Select GST%");
    }
    if (!edit?.GST_AMT || (edit?.GST_AMT && edit?.GST_AMT.length === 0)) {
      data.push("Please Click on Calculate");
    } else if (!edit?.TOTAL || (edit?.TOTAL && edit?.TOTAL.length === 0)) {
      data.push("Please Click on Calculate");
    }
    if (
      !edit?.effective_date ||
      (edit?.effective_date && edit?.effective_date.length === 0)
    ) {
      data.push("Please Enter EFFECTIVE DATE");
    } else if (
      DateValidator2(
        moment(edit?.effective_date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData[addData.length - 1]?.effective_date).format(
            "DD/MM/YYYY"
          ),
          today,
          eff_date,
          `Effective Date cant be before ${moment(eff_date).format(
            "DD/MM/YYYY"
          )} `,
          `Effective Date cant be a future date`
        )
      );
    }
    return data;
  };

  const get_uom = (id) => {
    const uom_name = UOMData?.filter((v) => v?.UOM == id);
    if (id && uom_name?.length > 0) {
      return uom_name[0]?.title;
    } else {
      return id;
    }
  };

  const serviceData = [
    { id: 0, lable: "100%", value: 100 },
    { id: 1, lable: "75%", value: 75 },
    { id: 2, lable: "50%", value: 50 },
    { id: 3, lable: "25%", value: 25 },
    { id: 4, lable: "0%", value: 0 },
  ];
  return (
    <div className="container">
      <PageHeading
        title={"SERVICES MASTER"}
        middle={
          addData?.length == 0 && (
            <div
              className="flex aic gap10 serv-item"
              style={{ padding: 0, margin: 0 }}
            >
              <MediumText
                title={`CURRENT OFFER PERCENTAGE : ${service_master_percent}%`}
              />
              <div className="flex aic gap10" style={{ padding: 0, margin: 0 }}>
                <MediumText title={"OFFER PERCENTAGE : "} />
                <div style={{ padding: 0, margin: 0 }}>
                  <Dropdown
                    mw={80}
                    w={80}
                    value={
                      gstCount ||
                      serviceData.filter(
                        (v, i) => v?.value == service_master_percent
                      )?.[0]?.id
                    }
                    onChange={(e) => setGstCount(e.target.value)}
                    options={serviceData?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.lable}
                      </option>
                    ))}
                  />
                  {/* 
                      <>
                        <option value={0}>100%</option>
                        <option value={1}>75%</option>
                        <option value={2}>50%</option>
                        <option value={3}>25%</option>
                        <option value={4}>0%</option>
                        </>
                        */}
                </div>
                <div style={{ padding: 0, margin: 0 }}>
                  <Button onClick={change_service_charge} title={"SUBMIT"} />
                </div>
              </div>
            </div>
          )
        }
        right={
          <>
            <AddButton
              onClick={() => {
                if (validation_check()) {
                  setMess(
                    "Please enter all the values in current row and then add next row"
                  );
                  setOpen(true);
                  return;
                } else if (addData?.length >= 4) {
                  setMess("Please Save the Records and ADD Again");
                  setOpen(true);
                  return;
                }
                setAddData((pre) => [
                  ...pre,
                  {
                    charges: "0.00",
                  },
                ]);
              }}
            />
            {addData.length > 0 && (
              <Button title={"CALCULATE"} onClick={calculate} />
            )}
            {addData.length > 0 && <SaveButton onClick={() => add_items()} />}
            <BackButton />
            <HelpButton
              Hindi={false}
              link={["https://www.youtube.com/watch?v=snIk5VqvEpU"]}
            />
          </>
        }
      />
      {addData.length > 0 && (
        <Table
          headData={[
            "SERVICE NAME",
            "SAC CODE",
            "UOM",
            "CHARGES",
            "GST %",
            "GST AMOUNT",
            "TOTAL",
            "EFFECTIVE DATE",
            "ACTIONS",
          ]}
          body={addData.map((val, ind) => (
            <tr key={ind}>
              <td>
                <Input
                  placeholder={"SERVICE NAME"}
                  value={val.serv_name}
                  onChange={(e) => {
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind
                          ? {
                              ...v,
                              serv_name: e.target.value,
                            }
                          : { ...v }
                      )
                    );
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={"SAC CODE"}
                  value={val?.hsn}
                  onChange={(e) => {
                    if (e.target.value?.length < 9 || e.target.value == "") {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                hsn:
                                  /^\d+$/.test(e.target.value) ||
                                  e.target.value == ""
                                    ? e.target.value
                                    : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Dropdown
                  value={val.unit}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind ? { ...v, unit: e.target.value } : { ...v }
                      )
                    )
                  }
                  options={
                    <>
                      <option value={""}>{"SELECT"}</option>
                      <option value={"1"}>NOS</option>
                      <option value={"2"}>KGS</option>
                      <option value={"3"}>SET</option>
                      {UOMData.map((item) => (
                        <option value={item.UOM} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </>
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"CHARGES"}
                  value={val.charges}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      (inputValue?.length < 10 &&
                        /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(inputValue)) ||
                      e.target.value == 0
                    ) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                charges: /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(
                                  inputValue
                                )
                                  ? inputValue
                                  : "",
                                gst_amount: "",
                                total: "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Dropdown
                  value={val.gst}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind
                          ? {
                              ...v,
                              gst: e.target.value,
                              gst_amount: "",
                              total: "",
                            }
                          : { ...v }
                      )
                    )
                  }
                  options={
                    <>
                      <option value={""}>{"SELECT"}</option>
                      <option value={"0"}>0</option>
                      <option value={"5"}>5</option>
                      <option value={"12"}>12</option>
                      <option value={"18"}>18</option>
                      <option value={"28"}>28</option>
                    </>
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"GST AMOUNT"}
                  disabled={true}
                  value={val.gst_amount}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind
                          ? { ...v, gst_amount: e.target.value }
                          : { ...v }
                      )
                    )
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"TOTAL PRICE"}
                  value={val?.total}
                  disabled={true}
                />
              </td>
              <td>
                <Input
                  type={"date"}
                  value={val.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind ? { ...v, date: e.target.value } : { ...v }
                        )
                      );
                    }
                  }}
                  onBlur={() => {
                    if (addData[addData.length - 1].date?.length == 10) {
                      if (
                        DateValidator2(
                          moment(addData[addData.length - 1]?.date).format(
                            "DD/MM/YYYY"
                          ),
                          today,
                          eff_date,
                          `Effective Date cant be before ${moment(
                            eff_date
                          ).format("DD/MM/YYYY")} `,
                          `Effective Date cant be a future date`
                        ) != true
                      ) {
                        setMess(
                          ...DateValidator2(
                            moment(addData[addData.length - 1]?.date).format(
                              "DD/MM/YYYY"
                            ),
                            today,
                            eff_date,
                            `Effective Date cant be before ${moment(
                              eff_date
                            ).format("DD/MM/YYYY")} `,
                            `Effective Date cant be a future date`
                          )
                        );
                        setOpen(true);
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind ? { ...v, date: "" } : { ...v }
                          )
                        );
                      }
                    }
                  }}
                />
              </td>
              <td>
                <DeleteIcon
                  onClick={() =>
                    setAddData((pre) => pre.filter((v, i) => i != ind))
                  }
                />
              </td>
            </tr>
          ))}
        />
      )}

      {service_master_loading ? (
        <PageLoading loading={service_master_loading} />
      ) : (
        <TableNew
          nodata={service_master_list?.length == 0 ? true : false}
          headData={headData}
          body={service_master_list?.map((val, ind) => (
            <tr key={ind}>
              <td
                style={{ cursor: val?.default == "false" ? "pointer" : "" }}
                onClick={() => {
                  if (val?.default == "false") {
                    setOpenEdit(true);
                    setEdit(val);
                  }
                }}
              >
                {val?.CAT_DESC}
              </td>
              <td>{val?.SAC_CODE}</td>
              <td>{val?.default == "true" ? "NOS" : get_uom(val?.UOM)}</td>
              <td>{val?.PROD_CHARGES}</td>
              <td>{val?.GST_AMT}</td>
              <td>{moment(val?.effective_date).format("DD-MMM-YYYY")}</td>
              <td>
                {val?.default == "false" && (
                  <DeleteIcon
                    onClick={() => {
                      setID(val?.ID);
                      setOpen1(true);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        />
      )}

      {openEdit && (
        <Modal
          isOpen={openEdit}
          setIsOpen={setOpenEdit}
          title={"SERVICES MASTER"}
          body={
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
                className="credit-limit"
              >
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    disabled={true}
                    label="SERVICE NAME"
                    value={edit?.CAT_DESC}
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    label="SAC CODE"
                    placeholder={"SAC CODE"}
                    value={edit?.SAC_CODE}
                    onChange={(e) => {
                      if (e.target.value?.length < 9) {
                        setEdit({
                          ...edit,
                          SAC_CODE:
                            /^\d+$/.test(e.target.value) ||
                            e.target.value == "" ||
                            !edit?.SAC_CODE
                              ? e.target.value
                              : "",
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="UOM"
                    value={edit?.UOM}
                    onChange={(e) => {
                      setEdit({
                        ...edit,
                        UOM: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value={""}>{"SELECT"}</option>
                        <option value={"NOS"}>NOS</option>
                        <option value={"KGS"}>KGS</option>
                        <option value={"SET"}>SET</option>
                        {UOMData.map((item) => (
                          <option value={item.UOM} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </>
                    }
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    label="CHARGES"
                    placeholder={"CHARGES"}
                    value={edit?.PROD_CHARGES}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        (inputValue?.length < 10 &&
                          /^(0|[1-9]\d{0,5})(\.\d{0,2})?$/.test(inputValue)) ||
                        e.target.value == 0 ||
                        !edit?.PROD_CHARGES
                      ) {
                        setEdit({
                          ...edit,
                          PROD_CHARGES: inputValue,
                          TOTAL: "",
                          GST_AMT: "",
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label={"GST %"}
                    value={edit?.GST}
                    onChange={(e) => {
                      setEdit({
                        ...edit,
                        GST: e.target.value,
                        TOTAL: "",
                        GST_AMT: "",
                      });
                    }}
                    options={
                      <>
                        <option value={""}>{"SELECT"}</option>
                        <option value={"0"}>0</option>
                        <option value={"5"}>5</option>
                        <option value={"12"}>12</option>
                        <option value={"18"}>18</option>
                        <option value={"28"}>28</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    label="GST AMOUNT"
                    disabled={true}
                    placeholder={"GST AMOUNT"}
                    value={edit?.GST_AMT}
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    label="TOTAL"
                    disabled={true}
                    placeholder={"TOTAL"}
                    value={edit?.TOTAL}
                  />
                </div>
                <div style={{ width: "25%", padding: " .15rem .5rem" }}>
                  <Input
                    label="EFFECTIVE DATE"
                    placeholder={"EFFECTIVE DATE"}
                    type={"date"}
                    disabled={true}
                    value={moment(edit?.effective_date).format("YYYY-MM-DD")}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  marginTop: "1rem",
                  gap: ".5rem",
                }}
              >
                <Button title={"CALCULATE"} onClick={handle_calculate} />
                <SaveButton onClick={() => update()} />
              </div>
            </div>
          }
        />
      )}

      {open1 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={"Are You Sure you want to delete"}
          onClick={() => delete_items()}
        />
      )}
      {open && (
        <SmallModal
          okbtn
          title={"Alerts"}
          mess={mess}
          isOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default ServicesMaster;
