import React from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
const InvoiceBillInfo = () => {
  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>
        E-Invoice and EWay Bill Enabling
      </div>
      <div className={styles.invoice_card}>
        <div className={styles.invoive_txt}>
          Please do GSP Registration as MastersIndia in E-Way bill portal.
          Please Update Registered User Name and password for enabling E-Invoice
          and EWay Bill Generation as given in GSP Registration.
        </div>
        <div className={styles.invoice_btn_wrap}>
          <div>Help</div>
          <Button title={"ENABLE"} onClick={()=>console.log("This Functionality is under Process")} />
        </div>
      </div>
    </div>
  );
};

export default InvoiceBillInfo;
