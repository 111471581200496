import React, { useEffect, useState } from "react";
import Modal from "../../../components/modal/Modal";
import { useSelector } from "react-redux";
import moment from "moment";
import TableNew from "../../../components/table/TableWithPagination";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_areacode_master_list,
  get_customer_vender_list,
  get_equipment_list,
  get_refill_price_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import {
  get_arb_purchase_list,
  get_cylinder_purchase_list,
} from "../../transactions/trasactionsSlice";
import PageLoading from "../../../components/PageLoading";

export const CVOList = ({ cvoClick, setCvoClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("0");
  const [SerchInput, setSearchInput] = useState("");
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { cust_vend_list } = useSelector((state) => state.masterdata);

  useEffect(() => {
    store.dispatch(get_customer_vender_list(obj));
  }, [pageNo, entries]);

  return (
    <>
      <Modal
        preference={2}
        isOpen={cvoClick}
        title={"CUSTOMER / VENDOR MASTER"}
        setIsOpen={setCvoClick}
        nodata={totalCount == 0 ? true : false}
        body={
          <>
            <TableNew
              mt={1}
              data={true}
              headData={[
                "PARTY/PLANT NAME",
                "PARTY TYPE",
                "GST REG",
                "GSTIN",
                "PAN",
                "ADDRESS",
                "MOBILE NO",
                "EMAIL",
                "OPENING BALANCE",
                "CLOSING BALANCE",
              ]}
              body={
                cust_vend_list.length > 0 &&
                cust_vend_list.map((item, i) => (
                  <tr key={i}>
                    <td>{item.cvo_name}</td>
                    <td>{item.CVO_CAT}</td>
                    <td>{item.IS_GST_REG == "1" ? "YES" : "NO"}</td>
                    <td>{item.IS_GST_REG == "1" ? "YES" : "NO"}</td>
                    <td>{item.CVO_PAN}</td>
                    <td>
                      {item.B_LOCATION},{item.B_DISTRICT},{item.B_STATE_NAME},
                      {item.B_PINCODE}
                    </td>
                    <td>{item.CVO_CONTACT}</td>
                    <td>{item.CVO_EMAIL}</td>
                    <td>{item.OBAL}</td>
                    <td>{item.CBAL}</td>
                  </tr>
                ))
              }
              totalCount={totalCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              entries={entries}
              setEntries={setEntries}
              setSearchInput={setSearchInput}
              SerchInput={SerchInput}
            />
          </>
        }
      />
    </>
  );
};

export const EquipmentList = ({ EquipClick, setEquipClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");

  const { equipment_list } = useSelector((state) => state.masterdata);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_equipment_list(obj));
  }, [pageNo, entries]);

  return (
    <Modal
      preference={2}
      isOpen={EquipClick}
      title={"EQUIPMENT MASTER"}
      setIsOpen={setEquipClick}
      body={
        <>
          <TableNew
            data={true}
            mt={10}
            headData={[
              "PRODUCT",
              "UNITS",
              "GST %",
              "HSN CODE",
              "SECURITY DEPOSIT",
              "FULLS",
              "EMPTIES",
              "EFFECTIVE DATE",
            ]}
            nodata={equipment_list.length == 0 ? true : false}
            body={equipment_list.map((v, i) => (
              <tr key={i}>
                <td>
                  {v.CAT_NAME} - {v.product_name}
                </td>
                <td>{v.UNITS}</td>
                <td>{v.GSTP}</td>
                <td>{v.CSTEH_NO}</td>
                <td>{v.SECURITY_DEPOSIT}</td>
                <td>{v.OS_FULLS} </td>
                <td>{v.OS_EMPTYS}</td>
                <td>{moment(v.formatted_date).format("DD-MMM-YYYY")}</td>
              </tr>
            ))}
            totalCount={totalCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            setSearchInput={setSearchInput}
            SerchInput={SerchInput}
          />
        </>
      }
    />
  );
};

export const StaffList = ({ StaffClick, setStaffClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const { staff_list } = useSelector((state) => state.masterdata);

  useEffect(() => {
    store.dispatch(get_staff_list(obj));
  }, [pageNo, entries]);

  return (
    <Modal
      preference={2}
      isOpen={StaffClick}
      title={"STAFF MASTER"}
      setIsOpen={setStaffClick}
      body={
        <>
          <TableNew
            data={true}
            mt={10}
            headData={[
              "EMPLOYEE CODE	",
              "EMPLOYEE NAME	",
              "DATE OF BIRTH",
              "DESIGNATION",
              "ROLE",
            ]}
            body={staff_list.map((v, i) => (
              <tr key={i}>
                <td>{v.EMP_CODE} </td>
                <td>{v.EMP_NAME}</td>
                <td>{moment(v.DOB).format("DD-MMM-YYYY")}</td>
                <td>{v.DESIGNATION}</td>
                <td>{v.ROLE}</td>
              </tr>
            ))}
            totalCount={totalCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            setSearchInput={setSearchInput}
            SerchInput={SerchInput}
          />
        </>
      }
    ></Modal>
  );
};

export const PriceList = ({ PriceClick, setPriceClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");

  const { refill_price_list } = useSelector((state) => state.masterdata);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_refill_price_list(obj));
  }, []);

  return (
    <Modal
      preference={2}
      isOpen={PriceClick}
      title={"REFILL PRICE MASTER"}
      setIsOpen={setPriceClick}
      body={
        <>
          <TableNew
            mt={10}
            data={true}
            nodata={refill_price_list?.length == 0 ? true : false}
            headData={[
              "PRODUCT",
              "RSP PRICE",
              "BASIC PRICE",
              "SGST AMOUNT",
              "CGST AMOUNT",
              "OFFER PRICE",
              "MONTH",
              "YEAR",
            ]}
            body={refill_price_list.map((v, i) => (
              <tr key={i}>
                <td>
                  {v.CAT_NAME} - {v.CAT_DESC}{" "}
                </td>
                <td>{v.RSP}</td>
                <td>{v.BASE_PRICE}</td>
                <td>{v.SGST_PRICE}</td>
                <td>{v.CGST_PRICE}</td>
                <td>{v.OFFER_PRICE}</td>
                <td>{v.MONTHA}</td>
                <td>{v.YEARA}</td>
              </tr>
            ))}
            totalCount={totalCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            setSearchInput={setSearchInput}
            SerchInput={SerchInput}
          />
        </>
      }
    />
  );
};

export const ARBList = ({ arbClick, setArbClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;
  const { arb_list } = useSelector((state) => state.masterdata);

  useEffect(() => {
    store.dispatch(get_arb_list(obj));
  }, [pageNo, entries, totalCount]);
  return (
    <Modal
      preference={2}
      isOpen={arbClick}
      title={
        (OMC == 1 && "NFR PRODUCT") ||
        (OMC == 2 && "ARB PRODUCT") ||
        (OMC == 3 && "BLPG PRODUCT")
      }
      setIsOpen={setArbClick}
      body={
        <>
          <TableNew
            data={true}
            mt={10}
            nodata={arb_list?.length == 0 ? true : false}
            headData={[
              "PRODUCT",
              "BRAND",
              "PRODUCT NAME",
              "HSN CODE",
              "UNITS",
              "GST %",
              "PURCHASE PRICE	",
              "OPENING STOCK",
              "EFFECTIVE DATE",
            ]}
            body={arb_list.map((v, i) => (
              <tr key={i}>
                <td>{v.category_description}</td>
                {/* <td>{v.UNITS == 1 ? "NOS" : "KGS"}</td> */}
                <td>{v.PROD_BRAND}</td>
                <td>{v.PROD_NAME}</td>
                <td>{v.CSTEH_NO}</td>
                <td>{v.UNITS}</td>
                <td>{v.GSTP} %</td>
                <td>{v.PROD_MRP}</td>
                <td>{v.OPENING_STOCK}</td>
                <td>{moment(v.formatted_date).format("DD-MMM-YYYY")}</td>
              </tr>
            ))}
            totalCount={totalCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            setSearchInput={setSearchInput}
            SerchInput={SerchInput}
          />
        </>
      }
    />
  );
};

export const ARBPurchaseList = ({ ARBPClick, setARBPClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const { arb_purchase_list } = useSelector((state) => state.transactions);
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_arb_purchase_list(obj));
  }, [pageNo, entries]);
  return (
    <Modal
      preference={2}
      isOpen={ARBPClick}
      setIsOpen={setARBPClick}
      title={
        (OMC == 1 && "NFR PURCHASE INVOICES") ||
        (OMC == 2 && "ARB PURCHASE INVOICES") ||
        (OMC == 3 && "BLPG PURCHASE INVOICES")
      }
      body={
        <TableNew
          data={true}
          mt={5}
          body={
            arb_purchase_list.length > 0 &&
            arb_purchase_list.map((item, i) => (
              <tr key={i}>
                <td>{item.INV_REF_NO}</td>
                <td>{moment(item.Invoice_date).format("DD-MMM-YYYY")}</td>
                <td>{moment(item.stock_receive_date).format("DD-MMM-YYYY")}</td>
                <td>
                  {item.PROD_CODE +
                    "-" +
                    item.PROD_BRAND +
                    "-" +
                    item.PROD_NAME}
                </td>
                <td>{item.cvo_name}</td>
                <td>{item.REVERSE_CHARGE}</td>
                <td>{item.UNIT_PRICE}</td>
                <td>{item.QUANTITY}</td>
                <td>{item.GSTP}</td>
                <td>{item.BASIC_AMOUNT}</td>
                <td>{item.IGST_AMOUNT}</td>
                <td>{item.SGST_AMOUNT}</td>
                <td>{item.CGST_AMOUNT}</td>
                <td>{item.OTHER_CHARGES}</td>
                <td>{item.C_AMOUNT}</td>
              </tr>
            ))
          }
          headData={[
            "INV NO",
            "INVOICE DATE",
            "STOCK RECVD DATE",
            "PRODUCT",
            "VENDOR",
            "REVERSE CHARGE",
            "UNIT PRICE",
            "QUANTITY",
            "GST%",
            "TAXABLE AMOUNT",
            "IGST",
            "SGST",
            "CGST",
            "OTHER CHARGES	",
            "TOTAL AMOUNT",
          ]}
          totalCount={totalCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
          setSearchInput={setSearchInput}
          SerchInput={SerchInput}
        />
      }
    />
  );
};

export const CylinderPurchaseList = ({ CPClick, setCPClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const { cylinder_purchase_list } = useSelector((state) => state.transactions);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_cylinder_purchase_list(obj));
  }, [pageNo, entries]);
  return (
    <Modal
      preference={2}
      isOpen={CPClick}
      setIsOpen={setCPClick}
      title={"CYLINDERS PURCHASE INVOICE ENTRY"}
      body={
        <TableNew
          data={true}
          mt={"5"}
          body={
            cylinder_purchase_list.length > 0 &&
            cylinder_purchase_list.map((item, i) => (
              <tr key={i}>
                <td>{item.cvo_name}</td>
                <td>{item.INV_REF_NO}</td>
                <td>{moment(item.Invoice_date).format("DD-MMM-YYYY")}</td>
                <td>{moment(item.stock_receive_date).format("DD-MMM-YYYY")}</td>
                <td>{item.CAT_Name + "-" + item.CAT_DESC}</td>
                <td>{item.EMR_OR_DELV}</td>
                <td>{item.UNIT_PRICE}</td>
                <td>{item.QUANTITY}</td>
                <td>{item.GSTP}</td>
                <td>{item.BASIC_AMOUNT}</td>
                <td>{item.IGST_AMOUNT}</td>
                <td>{item.SGST_AMOUNT}</td>
                <td>{item.CGST_AMOUNT}</td>
                <td>{item.OTHER_CHARGES}</td>
                <td>{item.C_AMOUNT}</td>
              </tr>
            ))
          }
          headData={[
            "OMC",
            "INV NO",
            "INVOICE DATE",
            "STOCK RECVD DATE",
            "PRODUCT",
            "ONE WAY/TWO WAY LOAD",
            "UNIT PRICE",
            "QUANTITY",
            "GST%",
            "TAXABLE AMOUNT",
            "IGST",
            "SGST",
            "CGST",
            "OTHER CHARGES",
            "TOTAL AMOUNT",
          ]}
          totalCount={totalCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
          setSearchInput={setSearchInput}
          SerchInput={SerchInput}
        />
      }
    />
  );
};

export const AreaCodeList = ({ AreaClick, setAreaClick }) => {
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const { area_code_list, area_code_loading } = useSelector(
    (state) => state.masterdata
  );
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  useEffect(() => {
    store.dispatch(get_areacode_master_list(obj));
  }, [pageNo, entries]);
  return (
    <Modal
      preference={2}
      isOpen={AreaClick}
      setIsOpen={setAreaClick}
      title={"AREA CODE MASTER"}
      body={
        area_code_loading ? (
          <PageLoading />
        ) : (
          <TableNew
            mt={"5"}
            data={true}
            nodata={
              area_code_list.length == 0 && !area_code_loading ? true : false
            }
            body={
              area_code_list.length > 0 &&
              area_code_list.map((item, i) => (
                <tr key={i}>
                  <td>{item.AREA_CODE}</td>
                  <td>{item.AREA_NAME}</td>
                  <td>{item.ONE_WAY_DIST}</td>
                  <td>{item.TRANSPORT_CHARGES}</td>
                  <td>{moment(item.formatted_date).format("DD-MMM-YYYY")}</td>
                </tr>
              ))
            }
            headData={[
              "AREA CODE",
              "AREA NAME",
              "ONEWAY DISTANCE (KM)",
              "TRANSPORT CHARGES",
              "EFFECTIVE DATE",
            ]}
            totalCount={totalCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            setSearchInput={setSearchInput}
            SerchInput={SerchInput}
          />
        )
      }
    />
  );
};
