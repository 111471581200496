import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import { useSelector } from "react-redux";
import Table from "../../components/table/Table";
import store from "../../redux/store";
import {
  get_payment_receipt_report_list,
  get_sales_report_list,
} from "./transactionReportSlice";
import { DateValidator2 } from "../../utils/validation";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import { payment_and_reciepts_report_api } from "../../utils/apis";
import { Pagination2 } from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import { downloadExcel, exportPDF } from "../../utils/utils";
import { get_customer_vender_list } from "../MasterData/masterdataSlice";
import { Alerts } from "../../components/Alerts/Alerts";
import { DropInput } from "../../components/inputs/DropInput";
const PaymentReceived = () => {
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("50");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [
    payment_reciept_report_item_list,
    setpayment_reciept_report_item_list,
  ] = useState([]);

  const [totalData, setTotalData] = useState({});
  const { firm_name } = useSelector((state) => state.profile);
  const { payments_reciept_list } = useSelector(
    (state) => state.transactionReport
  );
  const { eff_date, user_id, today, omc_name } = useSelector(
    (state) => state.other
  );
  console.log(
    "payment_reciept_report_item_list ===",
    payment_reciept_report_item_list
  );
  const [addData, setAddData] = useState({});

  useEffect(() => {
    store.dispatch(get_payment_receipt_report_list());
  }, []);

  useEffect(() => {
    if (showData) {
      get_list(addDataPre);
    }
  }, [pageNo, entries]);

  const table_data = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        // v?.CUST_VEN == "0"
        //   ? "VENDOR"
        //   : v?.CUST_VEN == "1"
        //   ? "CUSTOMER"
        //   : v?.CUST_VEN == "2"
        //   ? `OMC-${omc_name}`
        //   : "OTHERS",
        // CUST_VEN?.filter((t) => (t?.id == v?.CUST_VEN) == "1")?.[0]?.value,
        v?.CUST_VEN,
        v?.CUSTOMER_NAME,
        moment(Number(v?.RCP_DATE || v?.PYMT_DATE)).format("DD-MMM-YYYY"),
        v?.SR_NO,

        v?.PAYMENT_MODE == "1"
          ? "CASH"
          : v?.PAYMENT_MODE == "2"
          ? "CHEQUE"
          : v?.PAYMENT_MODE == "3"
          ? "ONLINE"
          : "",
        v?.RCP_AMOUNT || v?.PYMT_AMOUNT,
        v?.BANK_ACC_NO,
        v?.STAFF_NAME,
        v?.NARRATION,
       v?.INSTR_DETAILS,
      ]),
      [
        "",
        "",
        "",
        "",
        "TOTAL",
        "",
        Number(
          total?.[0]?.TOTAL_RCP_AMOUNT || total?.[0]?.TOTAL_PYMT_AMOUNT
        )?.toFixed(2),
      ],
    ];

    return data;
  };
  const table_header = [
    "Party Type",
    "Party Name",
    "Voucher Date",
    "Voucher Number",
    "Mode Of Transaction",
    "Amount",
    "Credited/Debited To bank",
    // "Bank Name",
    "Received By",
    "Narration",
    "Txr Number",
  ];

  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - PAYMENTS AND RECEIPTS REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`,
      tableName: `${firm_name} - PAYMENTS AND RECEIPTS REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `${firm_name} - PAYMENTS AND RECEIPTS REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: SELECTED : : ${addData?.custProdDetails}`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", addData?.transaction_type);
        body.append("agencyid", user_id);
        body.append("sdate", addData?.from_date);
        body.append("edate", addData?.to_date);
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        body.append("customerid", addData?.customer_id || "");
        const response = await payment_and_reciepts_report_api(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.payments_data || response?.receipts_data,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.payments_data || response?.receipts_data,
              response?.total
            );
          } else {
            setpayment_reciept_report_item_list(
              response?.payments_data || response?.receipts_data
            );
            setTotalCount(response?.totalcount);
            setShowData(true);
            setAddDataPre(addData);
            setTotalData(response?.total);
          }
        } else if (!response.success) {
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      DateValidator2(
        moment(addData?.from_date).format("DD/MM/YYYY"),
        today,
        eff_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.from_date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `From Date Date cant be before Effective Date ${eff_date}`,
          `From Date Date cant be a future date`
        )
      );
    }
    if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (
      DateValidator2(
        moment(addData?.to_date).format("DD/MM/YYYY"),
        today,
        addData?.from_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addData?.to_date).format("DD/MM/YYYY"),
          today,
          addData?.from_date,
          `To Date cant be before ${moment(addData?.from_date).format(
            "DD/MM/YYYY"
          )} `,
          `To Date cant be a future date`
        )
      );
    }
    if (
      !addData?.transaction_type ||
      (addData?.transaction_type && addData?.transaction_type?.length == 0)
    ) {
      data.push("Please Select Transaction Type");
    }
    return data;
  };

  const CUST_VEN = [
    { id: 0, value: "VENDOR" },
    { id: 1, value: "CUSTOMER" },
    // { id: 2, value: `OMC-${omc_name}`, action: "fetchOmc" },
    { id: 3, value: "GST", action: "fetchgst" },
    { id: 4, value: "INCOME TAX", action: "fetchIncomeTax" },
    { id: 5, value: "TDS/TCS", action: "fetchTdsTcs" },
    { id: 6, value: "PROFSSIONAL TAX", action: "fetchProfessionalTax" },
    { id: 7, value: "LOAN AND ADVANCE", action: "fetchLoadAdvance" },
    { id: 8, value: "OTHERS", action: "fetchOthers" },
  ];

  return (
    <div className="container ">
      <PageHeading
        title={"PAYMENTS AND RECEIPTS REPORT"}
        right={<BackButton />}
      />
      <div className="transactionMain">
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
            type={"date"}
          />
        </div>
        <div style={{ width: "220px" }}>
          <Dropdown
            label={"Transaction Type"}
            value={addData?.transaction_type}
            onChange={(e) => {
              setAddData({
                ...addData,
                transaction_type: e.target.value,
                customer_id: "",
                custProdDetails: "",
                customer_name: "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                <option value={"fetchpayment"}>PAYMENTS</option>
                <option value={"fetchreceipt"}>RECEIPTS</option>
                {CUST_VEN?.slice(2, CUST_VEN?.length)?.map((v, i) => (
                  <option key={i} value={v?.action}>
                    {v?.value}
                  </option>
                ))}
              </>
            }
          />
        </div>
        {(addData?.transaction_type == "fetchpayment" || addData?.transaction_type == "fetchreceipt")
          && (
            <div style={{ width: "220px" }}>
              <DropInput
                label={"PARTY NAME"}
                id="custvend"
                placeholder={"PARTY NAME"}
                value={addData?.customer_name}
                onChange={(e) => {
                  const data = [
                    ...payments_reciept_list?.payments_list,
                    ...payments_reciept_list?.reciepts_list,
                  ]?.filter((v) => v?.ID == e.target.value);
                  setAddData({
                    ...addData,
                    customer_id: data?.length == 0 ? 0 : e.target.value,
                    customer_name: data?.[0]?.CUSTOMER_NAME || e.target.value,
                    custProdDetails: data?.[0]?.CUSTOMER_NAME || e.target.value,
                  });
                }}
                list={(addData?.transaction_type == "fetchpayment"
                  ? [...payments_reciept_list?.payments_list]
                  : addData?.transaction_type == "fetchreceipt"
                  ? [...payments_reciept_list?.reciepts_list]
                  : []
                )?.map((v, i) => (
                  <option key={i} value={v?.ID}>
                    {v?.CUSTOMER_NAME?.toUpperCase()}
                  </option>
                ))}
              />
            </div>
          )}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            // onClick={() => get_list(addData)}
            onClick={async () => {
              await setShowData(false);
              await setPageNo(1);
              // await setEntries(5);
              await get_list(addData, "main");
            }}
          />
        </div>
      </div>

      {payment_reciept_report_item_list?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>

            {addDataPre?.transaction_type != "" && (
              <>
                <span style={{ fontSize: "11PX" }}>TRANSACTION TYPE :</span>
                <h6 className="PDS">
                  {addDataPre?.transaction_type == "fetchpayment"
                    ? "PAYMENTS"
                    : addDataPre?.transaction_type == "fetchreceipt"
                    ? "RECEIPTS"
                    : ""}
                </h6>
              </>
            )}
            {addDataPre?.customer_id ? (
              <>
                <span style={{ fontSize: "11PX" }}>SELECTED:</span>
                <h6 className="PDS">
                  {addDataPre?.custProdDetails?.toUpperCase()}
                </h6>
              </>
            ) : (
              ""
            )}
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {payment_reciept_report_item_list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <Table
              headData={table_header}
              body={
                <>
                  {payment_reciept_report_item_list?.map((v, i) => (
                    <tr key={i}>
                      <td>
                        {/* {v?.CUST_VEN == "0"
                          ? "VENDOR"
                          : v?.CUST_VEN == "1"
                          ? "CUSTOMER"
                          : v?.CUST_VEN == "2"
                          ? `OMC-${omc_name}`
                          : "OTHERS"} */}
                        {
                          // CUST_VEN?.filter(
                          //   (t) => (t?.id == v?.CUST_VEN) == "1"
                          // )?.[0]?.value
                          v?.CUST_VEN
                        }
                      </td>
                      <td>{v?.CUSTOMER_NAME}</td>
                      <td>
                        {moment(Number(v?.RCP_DATE || v?.PYMT_DATE)).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td>{v?.SR_NO}</td>
                      <td>
                        {v?.PAYMENT_MODE == "1"
                          ? "CASH"
                          : v?.PAYMENT_MODE == "2"
                          ? "CHEQUE"
                          : v?.PAYMENT_MODE == "3"
                          ? "ONLINE"
                          : ""}
                      </td>
                      <td>{v?.RCP_AMOUNT || v?.PYMT_AMOUNT}</td>
                      <td>{v?.BANK_ACC_NO}</td>
                      <td>{v?.STAFF_NAME}</td>
                      <td>{v?.NARRATION}</td>
                      <td>{v?.INSTR_DETAILS}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      TOTAL
                    </td>
                    <td colSpan={1} />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {totalData?.[0]?.TOTAL_RCP_AMOUNT ||
                        totalData?.[0]?.TOTAL_PYMT_AMOUNT}
                    </td>
                  </tr>
                </>
              }
            />
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      <div style={{ height: "10px" }}></div>

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default PaymentReceived;
