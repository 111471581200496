import React, { useState } from "react";
import PageHeading from "../../../components/PageHeading";
import { BackButton } from "../../../components/btn/Buttons";
import Dropdown from "../../../components/inputs/Dropdown";
import Table from "../../../components/table/Table";

const DepreciationReport = () => {
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [financialYear, setFinancialYear] = useState("");

  // Handle changing of the financial year
  const handleYearChange = (event) => {
    setFinancialYear(event.target.value);
    // Optionally, fetch data related to the selected year
    fetchDataForYear(event.target.value);
  };
  const fetchDataForYear = (year) => {
    console.log(`Fetching data for the year: ${year}`);
  };
  return (
    <div className="container">
      <PageHeading
        title={"AGENCY DEPRECIATION REPORT"}
        right={<BackButton />}
      />
      <div style={{ height: "10px" }}></div>
      <div className="df mt10 gap10">
        <div style={{ width: "180px" }}>
          <Dropdown
            label={"FINANCIAL YEAR"}
            value={financialYear}
            onChange={handleYearChange}
            options={
              <>
                <option value="">SELECT</option>
                <option value="2019-20">2019-20</option>
                <option value="2020-21">2020-21</option>
                <option value="2021-22">2021-22</option>
                <option value="2022-23">2022-23</option>
                <option value="2023-24">2023-24</option>
                <option value="2024-25">2024-25</option>
              </>
            }
          />
        </div>

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <button className="GENERATE-BTN" onClick={() => setOpen(true)}>FETCH</button>
        </div>
      </div>
      <div style={{ height: "10px" }}></div>
      {open &&
        <Table
          mt={5}
          headData={[
            "FINANCIAL YEAR",
            "CREATED ON",
            "LAST UPDATED ON",
            "OPENING BALANCE",
            "NET ASSET VALUE",
            "DEPRECIATION AMOUNT",
            "CLOSING BALANCE",
            "STATUS",
            "ACTIONS",
          ]}
          body={
            <>
              <tr>
                <td>{financialYear}</td>
                <td>01/01/2023</td>
                <td>03/31/2024</td>
                <td>$10,000</td>
                <td>$9,500</td>
                <td>$500</td>
                <td>$9,000</td>
                <td>Active</td>
                <td>
                  <button className="GENERATE-BTN" onClick={() => setFlag(true)}>PROCESS</button>
                </td>
              </tr>
            </>
          }
        />}
     {flag&& <div>
        <Table
          mt={5}
          headData={[
            "DATE",
            "ITEM",
            "DR",
            "CR",
            "BALANCE",
          ]}
          body={
            <>
              <tr>
                <td>{financialYear}</td>
                <td>ITEM</td>
                <td>DR</td>
                <td>CR</td>
                <td>BALANCE</td>
              </tr>
            </>
          }
        />
      </div>}
    </div>
  );
};

export default DepreciationReport;
