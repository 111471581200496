import React from "react";
import styles from "./profile.module.css";
import CardDataRow from "./CardDataRow";

const PPinfo = () => {
  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>Personal Preferences</div>
      <div className={styles.p_card}>
        <CardDataRow title={"Language"} value={"English (United State)"} />
        <div className={styles.p_row_line} />
        <CardDataRow title={"Date Format"} value={"DD-MM-YYYY"} />
      </div>
    </div>
  );
};

export default PPinfo;
