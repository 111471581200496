import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import TableNew from "../../../components/table/TableWithPagination";
import PageLoading from "../../../components/PageLoading";
import { DeleteIcon, Radio } from "../../../utils/utils";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import Dropdown from "../../../components/inputs/Dropdown";
import { Input } from "../../../components/inputs/Inputs";
import { DateValidator2 } from "../../../utils/validation";
import moment from "moment";
import RadioBtn from "../../../components/radio/RadioBtn";
import { stock_adjustment_API } from "../../../utils/apis";
import { useSelector } from "react-redux";
import {
  get_arb_list,
  get_customer_vender_list,
  get_equipment_list,
  get_stock_adjustment_list,
} from "../masterdataSlice";
import store from "../../../redux/store";
import { toast } from "react-toastify";
import { Pagination2, Pagination3 } from "../../../components/pagination/pagination";
import { PinVerify } from "../../OtherComponent/PinVerify";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
const headData = [
  "ADJUSTMENT DATE",
  "PRODUCT",
  "TYPE",
  "FROM GODOWN",
  "TO GODOWN",
  "FULLS",
  "EMPTIES",
  "NARRATION",
  "ACTIONS",
];

export const StockAdjustMent = () => {
  const [addData, setAddData] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [showPinVerify, setShowPinVerify] = useState(true);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    search: SerchInput?.trim(),
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
  };
  
  const { user_id, today } = useSelector((state) => state.other);
  const { day_end_date } = useSelector((state) => state.profile);

  const {
    equipment_list,
    cust_vend_list,
    arb_list,
    stock_adjustment_loading,
    stock_adjustment_list,
    master_complete_list,
  } = useSelector((state) => state.masterdata);
  const { godown_address_list } = useSelector((state) => state.profile);

  // console.log("stock_adjustment_list ===", stock_adjustment_list);
  const fetchData_list = () => {
    store.dispatch(get_stock_adjustment_list(obj));
  };

  SearchApiHook(fetchData_list, SerchInput?.trim(), prevSearch, [entries, pageNo]);
  // useEffect(() => {
  //   const handler = setTimeout(
  //     () => {
  //       store.dispatch(get_stock_adjustment_list(obj));
  //     },
  //     SerchInput?.trim()?.length > 0 ? 500 : 0
  //   );

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [SerchInput?.trim()]);

  const navigate = useNavigate();

  const handleDeleteProduct = async (deleteid) => {
    try {
      const body = new FormData();
      body.append("agencyid", user_id);
      body.append("action", "delete");
      body.append("id", deleteid);
      const response = await stock_adjustment_API(body);
      if (response.success) {
        toast(response.message);
        setShowDeleteAlert(false);
        if (stock_adjustment_list?.length < 2) {
          setPageNo(pageNo - 1);
          store.dispatch(
            get_stock_adjustment_list({
              pageNo: pageNo - 1,
              entries: entries,
              setTotalCount: setTotalCount,
            })
          );
        } else {
          store.dispatch(get_stock_adjustment_list(obj));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const validate = () => {
    const errorData = [];
    const data1 = DateValidator2(
      moment(addData?.date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Adjustment Date cant be below as day end Date (${day_end_date})`,
      `Adjustment Date cant be a future date`
    );
    if (!addData?.date || (addData?.date && addData?.date.length == 0)) {
      errorData.push("Please Enter Date");
    } else if (data1 !== true) {
      errorData.push(...data1);
    }
    if (
      !addData?.product_category ||
      (addData?.product_category && addData?.product_category.length == 0)
    ) {
      errorData.push("Please Select Product Categoty");
    }
    if (
      !addData?.product_code ||
      (addData?.product_code && addData?.product_code.length == 0)
    ) {
      errorData.push("Please Product");
    }
    return errorData;
  };

  const validate2 = () => {
    const errorData = [];
    if (!addData?.type || (addData?.type && addData?.type.length == 0)) {
      errorData.push("Please Select Type");
    } else if (addData?.type == "INTERNAL" || addData?.type == "EXTERNAL") {
      if (
        !addData?.location ||
        (addData?.location && addData?.location.length == 0)
      ) {
        errorData.push("Please Select To Location");
      }
      if(addData?.product_category != "ARB"){
        if (
          !addData?.from_location ||
          (addData?.from_location && addData?.from_location.length == 0)
        ) {
          errorData.push("Please Select From Location");
        }
      }
    } else if (addData?.type == "LOSS") {
      if(addData?.product_category != "ARB"){
      if (
        !addData?.from_location ||
        (addData?.from_location && addData?.from_location.length == 0)
      ) {
        errorData.push("Please Select From Location");
      }
    }
    }
    if (
      !addData?.fullsChange ||
      (addData?.fullsChange && addData?.fullsChange.length == 0)
    ) {
      errorData.push("Please Enter Fulls");
    }
    if (
      addData.product_category == "EQUIPMENT" &&
      master_complete_list?.equipment?.filter(
        (v) => addData?.product_code == v?.ID
      ).length > 0 &&
      master_complete_list?.equipment?.filter(
        (v) => addData?.product_code == v?.ID
      )[0]?.CAT_NAME != "REGULATOR"
    ) {
      if (
        !addData?.emptysChange ||
        (addData?.emptysChange && addData?.emptysChange.length == 0)
      ) {
        errorData.push("Please Enter Emptys ");
      }
    }
    if (
      !addData?.narration ||
      (addData?.narration && addData?.narration.length == 0)
    ) {
      errorData.push("Please Enter Narration");
    }

    return errorData;
  };

  const add_items = async () => {
    const errorMess = validate2();
    if (errorMess?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("date", addData?.date);
        body.append("pcode", addData?.product_code);
        body.append("type", addData?.type);
        body.append("to_location", addData?.location);
        body.append("from_location", addData?.from_location || "");
        body.append("fulls", addData?.fullsChange);
        if (addData?.product_category == "EQUIPMENT") {
          body.append("emptys", addData?.emptysChange || "");
        } else {
          body.append("emptys", "");
        }
        body.append("narration", addData?.narration);
        body.append("pid", addData?.product_code);
        const responce = await stock_adjustment_API(body);
        setShowLoader(false);
        if (responce.success) {
          setIsAdd(false);
          store.dispatch(get_stock_adjustment_list(obj));
          setAddData({ date: "", product_category: "" });
          toast(responce?.message);
        } else {
          // toast(responce.message);
          setMess(responce?.message);
          setOpen(true);
        }
      } catch (error) {
        setShowLoader(false);
      }
    } else {
      setMess(<Alerts data={errorMess} />);
      setOpen(true);
    }
  };

  const fetchData = async (id) => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "fetch");
      body.append("agencyid", user_id);
      body.append("prod_cat", addData?.product_category);
      body.append("prod_code", addData?.product_code);

      if (addData?.product_category == "EQUIPMENT") {
        body.append("godown_id", id);
      }
      const responce = await stock_adjustment_API(body);
      setShowLoader(false);
      console.log("responce ====", responce);
      if (responce.success) {
        console.log(responce?.data?.CURRENT_STOCK || responce?.data?.CS_FULLS);
        setAddData({
          ...addData,
          from_location:id,
          fulls: Number(responce?.data?.CURRENT_STOCK || responce?.data?.CS_FULLS) ,
          emptys:
            addData?.product_category == "EQUIPMENT" ? (Number(responce?.data?.CS_EMPTYS)) : "",
        });
        toast(responce.message);
      } else {
        setMess(responce.message);
        setOpen(true);
        setAddData({
          ...addData,
          from_location: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddProduct = () => {
    const errorData = validate();
    if (errorData.length == 0) {
      check_product();
    } else {
      setMess(<Alerts data={errorData} />);
      setOpen(true);
    }
  };
  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_customer_vender_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_stock_adjustment_list(obj));
  }, [entries, pageNo]);

  const handleGetData = (item) => {
    const EquipmemtData = master_complete_list?.equipment?.filter(
      (v) => v.ID == item
    )[0];
    const ARBData = master_complete_list?.ARB?.filter((v) => v.ID == item)[0];
    const reguData = master_complete_list?.equipment?.filter(
      (v) => item == v?.ID
    )[0];
    const equipData = `${EquipmemtData?.CAT_NAME} - ${EquipmemtData?.CAT_DESC}`;
    const blpgData = `${ARBData?.category_description} - ${ARBData?.PROD_BRAND} - ${ARBData?.PROD_NAME}`;
    const regulData = `${reguData?.CAT_NAME} - ${reguData?.CAT_DESC}`;

    if (equipData != "undefined - undefined") {
      return equipData;
    } else if (blpgData != "undefined - undefined - undefined") {
      return blpgData;
    } else if (regulData != "undefined - undefined") {
      return regulData;
    }
    return "NA";
  };

  const check_product = async () => {
    const data = [...equipment_list, ...arb_list]?.filter(
      (v) =>
        v?.PROD_CODE == addData?.product_code || v?.ID == addData?.product_code
    );
    if (data?.length > 0) {
      try {
        // setShowLoader(true);
        // const body = new FormData();
        // body.append("action", "check");
        // body.append("agencyid", user_id);
        // body.append("date", addData?.date);
        // body.append("product_code", addData?.product_code);
        setIsAdd(true);
        if (addData?.product_category == "ARB") {
          fetchData("");
        }
        // setShowLoader(false);
        // const responce = await stock_adjustment_API(body);
        // if (responce.success) {
        //   setIsAdd(true);
        // } else {
        //   setMess(responce.message);
        //   setOpen(true);
        // }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(
        "The PRODUCT you have seleted has been deleted or Not add. Please check it and try again."
      );
      setOpen(true);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"STOCK ADJUSTMENT MODULE"}
        right={
          <>
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/sMKKHWUKdUY",
                "https://youtu.be/L0rsT7Zcz3E",
              ]}
            />
          </>
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          alignItems: "center",
          rowGap: ".25rem",
          columnGap: "1rem",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        <div style={{ width: "150px" }}>
          <Input
            w={150}
            label={"DATE"}
            type={"date"}
            value={addData?.date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "150px" }}>
          <Dropdown
            w={150}
            label={"PRODUCT CATEGORY"}
            value={addData?.product_category}
            onChange={(e) => {
              setAddData({ ...addData, product_category: e.target.value });
            }}
            options={
              <>
                <option value="">SELECT</option>
                <option value="EQUIPMENT">EQUIPMENT</option>
                <option value="ARB">BLPG/ARB/NFR</option>
              </>
            }
          />
        </div>
        <div style={{ width: "150px" }}>
          <Dropdown
            w={150}
            label={"PRODUCT"}
            value={addData?.product_code}
            onChange={(e) => {
              setAddData({
                ...addData,
                product_code: e.target.value,
                fulls:
                  
                  "",
                emptys: "",
              });
            }}
            options={
              <>
                <option value="">SELECT</option>
                {(addData.product_category == "EQUIPMENT" && (
                  <>
                    {/* {equipment_list.map((v, i) => (
                      <option value={v.PROD_CODE} key={i}>
                        {`${v.CAT_NAME} - ${v.product_name}`}
                      </option>
                    ))}
                    {master_complete_list?.equipment
                      .filter((v) => v[0]?.CAT_NAME == "REGULATOR")
                      .map((v, i) => (
                        <option value={v?.ID} key={i}>
                          {v?.CAT_DESC}
                        </option>
                      ))} */}
                    {master_complete_list?.equipment?.map((v, i) => (
                      <option value={v?.ID} key={i}>
                        {`${v.CAT_NAME} - ${v.CAT_DESC}`}
                      </option>
                    ))}
                    {/* {master_complete_list?.equipment
                      .filter((v) => v[0]?.CAT_NAME == "REGULATOR")
                      .map((v, i) => (
                        <option value={v?.ID} key={i}>
                          {v?.CAT_DESC}
                        </option>
                      ))} */}
                  </>
                )) ||
                  // addData.product_category == "ARB" &&
                  // arb_list.map((v, i) => (
                  //   <option value={v.ID} key={i}>
                  //     {`${v.category_description} - ${v.PROD_BRAND} - ${v.PROD_NAME}`}
                  //   </option>
                  // ))
                  (addData?.product_category == "ARB" &&
                    arb_list?.map((v, i) => (
                      <option value={v?.ID} key={i}>
                        {v?.category_description} - {v?.PROD_BRAND} -{" "}
                        {v?.PROD_NAME}
                      </option>
                    )))}
              </>
            }
          />
        </div>

        <div>
          <div style={{ marginTop: "1.7rem" }}></div>
          <AddButton onClick={() => handleAddProduct()} />
        </div>
      </div>
      {/* {totalCount > 5 && ( */}
        <Pagination3
          top={true}
          totalCount={totalCount > SearchCount ? SearchCount : totalCount}
          SerchInput={SerchInput}
          serchChange={(e) => {
            setSearchInput(e.target.value);
            setPrevSearch(SerchInput);
            setPageNo(1);
          }}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        >
      {/* )} */}
      {stock_adjustment_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          mt={30}
          nodata={
            !stock_adjustment_loading && stock_adjustment_list.length != 0
              ? false
              : true
          }
          headData={headData}
          body={stock_adjustment_list.map((item, i) => (
            <tr key={i}>
              <td>{moment(item?.Adjustment_date).format("DD-MMM-YYYY")}</td>
              <td>{handleGetData(item?.PROD_ID)}</td>
              <td> {item?.TYPE} </td>
              <td> {item?.FROM_GODOWN_NAME} </td>
              <td> {item?.TO_GODOWN_NAME || "-"} </td>
              <td> {item?.FULLS} </td>
              <td> {item?.EMPTIES} </td>
              <td> {item?.NARRATION} </td>
              <td>
                <DeleteIcon
                  onClick={() => {
                    setDeleteId(item?.ID);
                    setShowDeleteAlert(true);
                  }}
                />
              </td>
            </tr>
          ))}
        />
      )}
      </Pagination3>
      {/* {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )} */}
      {isAdd && (
        <SmallModal
          okBtnHide={false}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          onCancle={() => {
            setAddData({ date: "", product_category: "", product_code: "" });
          }}
          style={{ width: "fit-content", maxWidth: "fit-content" }}
          okbtn={setIsAdd}
          title={"AGENCY STOCK ADJUSTMENT"}
          mess={
            <div
              className="pin-ver"
              style={{
                margin: "0 0 1rem 0",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div
                className="flex aic gap10 "
                style={{ flexWrap: "wrap", rowGap: "0" }}
              >
                <RadioBtn label={"TYPE"} />
                <Radio
                  title={"INTERNAL TRANSFER"}
                  disabled={addData?.product_category == "ARB"}
                  show={addData.type == "INTERNAL" ? true : false}
                  setShow={() =>
                    setAddData({
                      ...addData,
                      location: "",
                      type: "INTERNAL",
                      location: "",
                      from_location: "",
                    })
                  }
                />
                <Radio
                  title={"EXTERNAL TRANSFER"}
                  show={addData.type == "EXTERNAL" ? true : false}
                  setShow={() =>
                    setAddData({
                      ...addData,
                      location: "",
                      type: "EXTERNAL",
                      location: "",
                      from_location: "",
                    })
                  }
                />
                <Radio
                  title={"LOSS"}
                  show={addData.type == "LOSS" ? true : false}
                  setShow={() =>
                    setAddData({
                      ...addData,
                      location: "",
                      type: "LOSS",
                      location: "",
                      from_location: "",
                    })
                  }
                />
              </div>

              {(addData?.type == "EXTERNAL" ||
                addData?.type == "INTERNAL" ||
                addData?.type == "LOSS") && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <div style={{ width: "200px" }}>
                    <Dropdown
                   disabled={addData?.product_category == "ARB"}
                      label={"FROM LOCATION:"}
                      value={addData?.from_location}
                      onChange={(e) => {
                        setAddData({
                          ...addData,
                          from_location: e.target.value,
                        });
                        if (e.target.value != "") {
                          fetchData(e.target.value);
                        }
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>
                          {godown_address_list?.map((s, i) => (
                            <option
                              disabled={addData?.location == s?.ID}
                              key={i}
                              value={s?.ID}
                            >
                              {s?.UNIT_NAME}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </div>
                  <div style={{ width: "200px" }}>
                    {addData?.type == "EXTERNAL" ? (
                      <Dropdown
                        label={"TO LOCATION:"}
                        value={addData?.location}
                        onChange={(e) => {
                          setAddData({ ...addData, location: e.target.value });
                        }}
                        options={
                          <>
                            <option value={""}>SELECT</option>
                            {cust_vend_list?.map((s, i) => (
                              <option key={i} value={s?.ID}>
                                {s?.cvo_name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    ) : addData?.type == "LOSS" ? (
                      ""
                    ) : (
                      <Dropdown
                        label={"TO LOCATION:"}
                        value={addData?.location}
                        onChange={(e) => {
                          setAddData({ ...addData, location: e.target.value });
                        }}
                        options={
                          <>
                            <option value={""}>SELECT</option>
                            {godown_address_list?.map((s, i) => (
                              <option
                                disabled={addData?.from_location == s.ID}
                                key={i}
                                value={s?.ID}
                              >
                                {s?.UNIT_NAME}
                              </option>
                            ))}
                          </>
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    DATE SELECTED:
                  </p>
                  <p style={{ fontSize: "15px", color: "gray" }}>
                    {moment(addData?.date).format("DD/MMM/YYYY")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    PRODUCT SELECTED:
                  </p>
                  <p style={{ fontSize: "15px", color: "gray" }}>
                    {handleGetData(addData?.product_code)}
                    {/* {addData?.product_code} */}
                  </p>
                </div>
              </div>
              <div
                className="stock-cards"
                style={{ justifyContent: "space-between" }}
              >
                <div className="stock-card">
                  <div
                    style={{
                      fontSize: "15px",
                      color: "black",
                      fontWeight: "bold",
                      height: "40px",
                    }}
                  >
                    {addData.product_category == "ARB" ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )?.length == 0 ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )[0]?.CAT_NAME == "REGULATOR"
                      ? "STOCK CLOSING BALANCE :"
                      : "CLOSING BALANCE"}
                  </div>
                  <div
                    className="stock-cards"
                    style={{
                      padding: "1rem",
                      width: "calc(100% - 2rem)",
                    }}
                  >
                    <div
                      className="stock-card"
                      style={{ width: "100%", border: "none" }}
                    >
                      <Input
                        label={
                          addData.product_category == "ARB" ||
                          master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )?.length == 0 ||
                          master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )[0]?.CAT_NAME == "REGULATOR"
                            ? ""
                            : "FULLS"
                        }
                        value={addData?.fullsChange}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^-?\d*$/.test(inputValue)) {
                            setAddData({
                              ...addData,
                              fullsChange: e.target.value,
                            });
                          }
                        }}
                        placeholder={
                          addData.product_category == "ARB" ||
                          master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )?.length == 0 ||
                          master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )[0]?.CAT_NAME == "REGULATOR"
                            ? "ENTER QUANTITY"
                            : "FULLS"
                        }
                      />
                    </div>
                    {addData.product_category == "ARB" ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )?.length == 0 ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )[0]?.CAT_NAME == "REGULATOR" ? (
                      ""
                    ) : (
                      <div
                        className="stock-card"
                        style={{ width: "100%", border: "none" }}
                      >
                        <Input
                          label={"EMPTIES"}
                          value={addData?.emptysChange}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^-?\d*$/.test(inputValue)) {
                              setAddData({
                                ...addData,
                                emptysChange: e.target.value,
                              });
                            }
                          }}
                          placeholder={"EMPTIES"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="stock-card"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        justifyContent: "center",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      BALANCE AS ON :
                    </p>
                    <p style={{ fontSize: "15px", color: "gray" }}>
                      {moment(new Date()).format("DD-MM-YYYY, h:mm:ss A")}
                    </p>
                  </div>
                  <div
                    className="stock-cards"
                    style={{
                      padding: "1rem",
                      width: "calc(100% - 2rem)",
                    }}
                  >
                    <div
                      className="stock-card"
                      style={{ width: "100%", border: "none" }}
                    >
                      <Input
                        label={
                          addData?.product_category == "ARB" ||
                          (master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )?.length == 0 &&
                            addData?.product_category == "EQUIPMENT") ||
                          master_complete_list?.equipment?.filter(
                            (v) => addData?.product_code == v?.ID
                          )[0]?.CAT_NAME == "REGULATOR"
                            ? ""
                            : "FULLS"
                        }
                        value={
                          addData?.fulls - (Number(addData?.fullsChange) || 0)
                        }
                        readOnly={true}
                        placeholder={"FULLS"}
                      />
                    </div>
                    {addData.product_category == "ARB" ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )?.length == 0 ||
                    master_complete_list?.equipment?.filter(
                      (v) => addData?.product_code == v?.ID
                    )[0]?.CAT_NAME == "REGULATOR" ? (
                      ""
                    ) : (
                      <div
                        className="stock-card"
                        style={{ width: "100%", border: "none" }}
                      >
                        <Input
                          label={"EMPTIES"}
                          value={
                            addData?.emptys -
                            (Number(addData?.emptysChange) || 0)
                          }
                          readOnly={true}
                          placeholder={"EMPTIES"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "1rem",
                    justifyContent: "flex-start",
                  }}
                >
                  NARRATION
                </div>
                <Input
                  placeholder={"NARRATION"}
                  value={addData?.narration}
                  onChange={(e) =>
                    setAddData({ ...addData, narration: e.target.value })
                  }
                />
              </div>

              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteProduct(DeleteId)}
          title={"Confirm"}
          mess={"Are You Sure you want to delete"}
        />
      )}

      {showPinVerify && (
        <PinVerify
          showPinVerify={showPinVerify}
          setShowPinVerify={setShowPinVerify}
          setShowLoader={setShowLoader}
          setAlertOpen={setOpen}
          setAlertMess={setMess}
        />
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alert"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};
