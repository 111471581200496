import React, { useMemo } from "react";
import styles from "./tax.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { bankDetails } from "../../../utils/data";
import EInvoice from "./EInvoice";
import useAmountToWords from "../../../utils/Hook/useAmountToWords";
var converter = require("number-to-words");

const inv_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

const TaxInvoice2 = ({
  e_inv = false,
  previewRef,
  table_header,
  table_body,
  listdata,
  net_total,
  hsn_data,
  other_type,
  cols,
  Total_tax,
}) => {
  const { profile_data } = useSelector((state) => state.profile);
  const { bank_master_list } = useSelector((state) => state.masterdata);
  const bank_data = bank_master_list?.filter((v) => v?.DA_STATUS == "1");

  const convertAmountToWords = useAmountToWords(net_total);
  const convertAmountToWords_Total_tax = useAmountToWords(Total_tax);

  return (
    <div className={styles.invoice_main} ref={previewRef}>
      <h2 style={{ textAlign: "center", padding: "1rem 0" }}>
        {e_inv ? "E-Invoice" : other_type ? other_type : "Tax Invoice"}
      </h2>

      {e_inv && <EInvoice />}
      <table className={styles.inv_main_table}>
        <tr>
          <td rowSpan={3} className={styles.td_1}>
            <div className={styles.td_1_data}>
              {e_inv ? (
                <>
                  <div className={styles.bold_text}>1.e-Invoice Details</div>
                  <div className={styles.norm_text}>
                    IRN :
                    1e1a881c5a8b64330ccb7232d821e65e0d2570daf0203af891061371828022dc
                  </div>
                  <div className={styles.norm_text}>
                    Ack. No : 112421099909157
                  </div>
                  <div className={styles.norm_text}>
                    Ack. Date : 17-JUL-2024
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.bold_text}>{profile_data?.name}</div>
                  <div className={styles.norm_text}>
                    {profile_data.ADDRESS}, {profile_data?.LOCATION},
                    {profile_data.DISTRICT}, {profile_data?.PINCODE}
                  </div>
                  <div className={styles.norm_text}>
                    PH:{profile_data?.mobile},{profile_data?.landline}
                  </div>
                  <div className={styles.norm_text}>
                    GSTIN/UIN: {profile_data?.gst}
                  </div>
                  <div className={styles.norm_text}>
                    State Name : Telangana, Code : {profile_data?.state}
                  </div>
                  <div className={styles.norm_text}>
                    E-Mail : {profile_data?.email}
                  </div>
                </>
              )}
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>
                {other_type ? other_type : " Invoice "} No.
              </div>
              <div className={styles.bold_text}>{listdata?.SR_NO}</div>
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Dated</div>
              <div className={styles.bold_text}>
                {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td rowSpan={1} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Delivery Note</div>
              <div className={styles.bold_text}></div>
            </div>
          </td>
          <td rowSpan={1} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Mode/Terms of Payment:</div>
              <div className={styles.bold_text}>{listdata?.pay_terms}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td rowSpan={1} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Reference No. & Date. :</div>
              <div className={styles.bold_text}>
                {listdata?.REF_NUMBER}
                {listdata?.REF_NUMBER
                  ? ` dt. ${moment(listdata?.REF_DATE).format("DD-MMM-YYYY")}`
                  : ""}
              </div>
            </div>
          </td>
          <td rowSpan={1} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Other References</div>
              <div className={styles.bold_text}></div>
            </div>
          </td>
        </tr>
        {/* First Row Data End  */}
        {/* Second Row Data Start  */}
        <tr>
          <td rowSpan={3} className={styles.td_1}>
            <div className={styles.td_1_data}>
              <div className={styles.norm_text}>Consignee (Ship to)</div>
              <div className={styles.bold_text}>{listdata?.CVO_NAME}</div>
              <div className={styles.norm_text}>{listdata?.CVO_ADDRESS}</div>
              <div className={styles.norm_text}>PH:{listdata?.MOBILE}</div>
              <div className={styles.norm_text}>
                GSTIN/UIN: {listdata?.CVO_TIN}
              </div>
              <div className={styles.norm_text}>
                State Name : {listdata?.B_STATE_NAME}, Code :{" "}
                {listdata?.B_STATE_CODE}
              </div>
              {/* <div className={styles.norm_text}>E-Mail : info@rathi.biz</div> */}
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Buyer's Order No.</div>
              <div className={styles.bold_text}>320-9861/62/63/64/65</div>
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Dated</div>
              <div className={styles.bold_text}>1-Feb-20</div>
            </div>
          </td>
        </tr>
        <tr>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Dispatch Doc No.</div>
              <div className={styles.bold_text}></div>
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Delivery Note Date</div>
              <div className={styles.bold_text}></div>
            </div>
          </td>
        </tr>
        <tr>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Dispatched through</div>
              <div className={styles.bold_text}>Destination</div>
            </div>
          </td>
          <td className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Other References</div>
              <div className={styles.bold_text}></div>
            </div>
          </td>
        </tr>
        {/* Second Row Data End  */}
        {/* Third Row Data Start  */}

        <tr>
          <td rowSpan={3} className={styles.td_1}>
            <div className={styles.td_1_data}>
              <div className={styles.norm_text}>Buyer (Bill to)</div>
              <div className={styles.bold_text}>{listdata?.SHIP_CVO_NAME}</div>
              <div className={styles.norm_text}>
                {listdata?.SHIP_CVO_ADDRESS}
              </div>
              <div className={styles.norm_text}>PH:{listdata?.MOBILE}</div>
              <div className={styles.norm_text}>
                GSTIN/UIN: {listdata?.CVO_TIN}
              </div>
              <div className={styles.norm_text}>
                State Name : {listdata?.SHIP_B_STATE_NAME}, Code :{" "}
                {listdata?.SHIP_B_STATE}
              </div>
              {/* <div className={styles.norm_text}>E-Mail : info@rathi.biz</div> */}
            </div>
          </td>
          <td colSpan={2} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>
                Salesman Name: <b>{listdata?.EMP_NAME}</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Terms of Delivery</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={styles.td_2}>
            <div className={styles.td_2_data}>
              <div className={styles.norm_text}>Transport Name: ()</div>
              <div className={styles.bold_text}>Transport Phone #: 0</div>
              <div className={styles.bold_text}>Booking Point :</div>
              <div
                className={styles.bold_text}
                style={{ display: "flex", width: "100%" }}
              >
                <div style={{ flex: 1, textAlign: "left" }}>Tel No.(hyd):</div>
                <div style={{ flex: 1, textAlign: "left" }}>Tel Nos(Sec):</div>
              </div>
            </div>
          </td>
        </tr>
        {/* Third Row Data End  */}
      </table>
      <table
        id="preview_data_table"
        className="table_cls"
        style={{
          width: "100%",
          border: "1px solid",
          borderColor: "#000",
          borderTop: "none",
          fontSize: "10px",
          borderBottom: "1px solid black !important",
        }}
      >
        <tr height="30px" style={{ borderBottom: "1px solid #000" }}>
          {(table_header ? table_header : inv_header)?.map((v, i) => (
            <th
              key={i}
              // colSpan={ 1}
              bgcolor="#ffffff"
              height="30px"
              style={{
                width: "fit-content !important",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                borderTop: "none",
                fontSize: "10px",
                fontWeight: "bold",
                textTransform: "unset",
              }}
            >
              {v?.title}
            </th>
          )) || table_header}
        </tr>
        <tbody id="preview_data_table_body">{table_body}</tbody>
        {listdata?.dc_type ? (
          ""
        ) : (
          <tr>
            <td
              colSpan={table_header.length - 2}
              className={styles.td_1}
              style={{ textAlign: "left", borderRight: "none" }}
            >
              <div className={styles.norm_text}>
                Amount Chargeable (in words)
              </div>
              <div className={styles.bold_text}>
                {/* INR Eighty One Thousand Two Hundred Sixty Only */}
                {convertAmountToWords}
              </div>
            </td>
            <td
              colSpan={table_header.length - 1}
              className={styles.td_1}
              style={{ borderLeft: "none" }}
            >
              E. & O.E
            </td>
          </tr>
        )}

        {hsn_data && (
          <>
            <tr>
              <td
                colSpan={cols ? cols : 2}
                rowSpan={2}
                className={styles.td_1}
                style={{ textAlign: "left" }}
              >
                HSN/SAC
              </td>
              <td rowSpan={2} className={styles.td_1}>
                Taxable Value
              </td>
              <td colSpan={2} className={styles.td_1}>
                Integrated Tax
              </td>
              <td colSpan={2} className={styles.td_1}>
                Central Tax
              </td>
              <td colSpan={2} className={styles.td_1}>
                State Tax
              </td>
              <td rowSpan={2} className={styles.td_1}>
                Total
              </td>
            </tr>

            <tr>
              <td className={styles.td_1}>Rate</td>
              <td className={styles.td_1}>Amount</td>
              <td className={styles.td_1}>Rate</td>
              <td className={styles.td_1}>Amount</td>
              <td className={styles.td_1}>Rate</td>
              <td className={styles.td_1}>Amount</td>
            </tr>
            {hsn_data}
          </>
        )}
      </table>
      <table >
        <tr> 
          <td
            colSpan={table_header?.length}
            className={styles.td_1}
            style={{
              textAlign: "left",
              color: "#000",
              fontSize: "14px !important",
              borderTop: "none",
              borderBottom: "none",
              borderTop:listdata?.dc_type ?"1px solid #000":""
            }}
          >
            {listdata?.dc_type ? (
              ""
            ) : (
              <>
                Tax Amount (in words) : <b>{convertAmountToWords_Total_tax}</b>
              </>
            )}
          </td>
        </tr>
        <tr>
          <td
            className={`${styles.td_1} borde_top_white `}
            colSpan={table_header?.length / 2}
            style={{
              border: "none",
              borderLeft: "1px solid #000",
              borderBottom: "1px solid #000",
            }}
            rowSpan={3}
          >
            <div className={styles.td_1_data} style={{ maxWidth: "100%" }}>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                Company's PAN : <b>{profile_data?.PAN_NO}</b>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                <u>Declaration</u>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                We declare that this {other_type ? other_type : " invoice"}{" "}
                shows the actual price of the goods described and that all
                particulars are true and correct. E.& O.E
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                E.& O.E 1.Payment should be done in 45 days from the date of
                {other_type ? other_type : " invoice"}. 18% interest will be
                charged if not paid on due date . 2.Goods once sold then cannot
                be taken or exchanged .
              </div>
            </div>
          </td>
          <td
            className={`${styles.td_2} borde_top_white`}
            colSpan={table_header?.length / 2 + 1}
            style={{ border: "none ", borderRight: "1px solid #000" }}
          >
            <div className={styles.td_1_data} style={{ maxWidth: "100%" }}>
              <div
                className={styles.norm_text}
                style={{
                  fontSize: "10px !important",
                  textAlign: "center",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <u>Company's Bank Details</u>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                Name :
                <b>
                  {
                    bankDetails?.filter(
                      (v) => v?.value == bank_data[0]?.BANK_NAME
                    )[0]?.bankName
                  }
                </b>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                Branch : <b>{bank_data[0]?.BANK_BRANCH}</b>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                IFSCode : <b>{bank_data[0]?.BANK_IFSC_CODE}</b>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                A/c No : <b>{bank_data[0]?.BANK_ACC_NO}</b>
              </div>
              <div
                className={styles.norm_text}
                style={{ fontSize: "10px !important" }}
              >
                A/c UPI ID : <b>7692802631@ybl</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            rowSpan={2}
            className={styles.td_2}
            colSpan={table_header?.length / 2 + 1}
            style={{ height: "60px" }}
          >
            <div
              className={styles.td_1_data}
              style={{
                height: "100%",
                justifyContent: "space-between",
                maxWidth: "100%",
              }}
            >
              <div
                className={styles.norm_text}
                style={{ textAlign: "right", width: "100%" }}
              >
                <b>for {profile_data?.name?.toUpperCase()}</b>
              </div>
              <div
                className={styles.norm_text}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>Prepared by </div>
                <div>Verified by</div>
                <div>Authorised Signatory</div>
              </div>
            </div>
          </td>
        </tr>
      </table>

      {/* <h4 style={{ textAlign: "center" }}>
        Details of Outstanding as on 1-Feb-20
      </h4> */}
      {/* <table className={styles.inv_main_table}>
        <tr>
          <td className={styles.td_1}>
            {" "}
            <div
              className={styles.td_1_data}
              style={{ maxWidth: "100%", textAlign: "center" }}
            >
              <div>less then 30 Days</div>
              <div>
                <b>4,58,998.00</b>
              </div>
            </div>
          </td>
          <td className={styles.td_1}>
            {" "}
            <div
              className={styles.td_1_data}
              style={{ maxWidth: "100%", textAlign: "center" }}
            >
              <div>30 Days to 60 Days</div>
              <div></div>
            </div>
          </td>
          <td className={styles.td_1}>
            {" "}
            <div
              className={styles.td_1_data}
              style={{ maxWidth: "100%", textAlign: "center" }}
            >
              <div>60 Days to 90 Days</div>
              <div></div>
            </div>
          </td>
          <td className={styles.td_1}>
            {" "}
            <div
              className={styles.td_1_data}
              style={{ maxWidth: "100%", textAlign: "center" }}
            >
              <div>more then 90 Days</div>
              <div></div>
            </div>
          </td>
        </tr>
      </table> */}
      {/* <div style={{ textAlign: "center" }}>
        SUBJECT TO SECUNDERABAD JURISDICTION
      </div> */}
      <div style={{ textAlign: "center" }}>
        This is a Computer Generated {other_type ? other_type : "Invoice"}
      </div>
    </div>
  );
};

export default TaxInvoice2;
