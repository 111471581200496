import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import Table from "../../../components/table/Table";
import Input from "../../../components/inputs/Input";
import { DeleteIcon, base_url } from "../../../utils/utils";
import store from "../../../redux/store";
import { get_areacode_master_list } from "../masterdataSlice";
import SmallModal from "../../../components/modal/SmallModal";
import { useSelector } from "react-redux";
import { area_code_action } from "../../../utils/apis";
import PageLoading from "../../../components/PageLoading";
import moment from "moment";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import TableNew from "../../../components/table/TableWithPagination";
import NoData from "../../../components/NoData";
import { DateValidator2 } from "../../../utils/validation";
import { toast } from "react-toastify";
import { Pagination2 } from "../../../components/pagination/pagination";
import { onlyNumber } from "../../../utils/Rejex";
import { Alerts } from "../../../components/Alerts/Alerts";

const AreaCodeMaster = () => {
  const [addData, setAddData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { area_code_loading, area_code_list } = useSelector(
    (state) => state.masterdata
  );
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [ID, setID] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { eff_date, user_id, today } = useSelector((state) => state.other);

  const navigate = useNavigate();

  const headData = [
    "PINCODE",
    "AREA CODE",
    "AREA NAME",
    "ONEWAY DISTANCE (KM)",
    "TRANSPORT CHARGES",
    "EFFECTIVE DATE",
    "ACTIONS",
  ];

  const validation_check = () => {
    const arr = [];
    const seenPinCodes = new Set();
    addData.forEach((v) => {
      const dataValid = DateValidator2(
        moment(v?.date).format("DD/MM/YYYY"),
        today,
        eff_date,
        `Effective Date cant be before ${eff_date} `,
        `Effective Date cant be a future date`
      );
      const duplicate_item = addData?.filter((val)=>v?.code == val?.code)
      if (
        !v?.pin_code ||
        v.pin_code.length === 0 ||
        !v?.code ||
        v.code.length === 0 ||
        !v?.name ||
        v.name.length === 0 ||
        !v?.distance ||
        v.distance.length === 0 ||
        !v?.charge ||
        v.charge.length === 0 ||
        !v?.date ||
        v.date.length === 0 ||
        dataValid != true ||
        duplicate_item?.length > 1
      ) {
        if (duplicate_item?.length > 1) {
          arr.push("You can't able to add duplicate Area Code");
        }
        if (!v?.pin_code || v?.pin_code.length === 0) {
          arr.push("Please Enter PIN CODE");
        } else if ((v?.pin_code).toString().length != 6) {
          arr.push("Please Enter Valid PIN CODE");
        }
        if (!v?.code || v?.code.length === 0) {
          arr.push("Please Enter AREA CODE");
        }
        if (!v?.name || v?.name.length === 0) {
          arr.push("Please Enter AREA NAME");
        }
        if (!v?.date || v?.date.length === 0) {
          arr.push("Please Enter EFFECTIVE DATE");
        } else if (dataValid != true) {
          arr.push(...dataValid);
        }
      }
    });
    //   function findDuplicates(arr) {
    //     return arr.filter((item, index) => arr.indexOf(item?.code) !== index);
    // }
    // const duplicateData = findDuplicates(addData);
    // if(duplicateData?.length > 0){
    //   arr.push("Please Enter EFFECTIVE DATE");
    // }
    return arr;
  };

  const add_items = async () => {
    const data = [...(new Set(validation_check()))];
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addData.forEach((v) => body.append("areacode[]", v?.code));
        addData.forEach((v) => body.append("pincode[]", v?.pin_code));
        addData.forEach((v) => body.append("areaname[]", v?.name));
        addData.forEach((v) => body.append("distance[]", v?.distance));
        addData.forEach((v) => body.append("charge[]", v?.charge));
        addData.forEach((v) => body.append("date[]", v?.date));
        const responce = await fetch(base_url + "area_code_api.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          store.dispatch(get_areacode_master_list(obj));
          setAddData([]);
          toast.success(res.message);
        } else {
          setMess(res.message);
          setOpen(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const deleteItem = () => {
    setOpen1(false);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);

    body.append("id", ID);
    area_code_action(body, setLoading).then((res) => {
      if (area_code_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_areacode_master_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_areacode_master_list(obj));
      }
    });
  };

  useEffect(() => {
    store.dispatch(get_areacode_master_list(obj));
  }, [pageNo, entries]);

  return (
    <div className="container">
      {loading && <Loader loading={loading} />}
      <PageHeading
        title={"AREA CODE MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                const data = validation_check();
                if (data?.length > 0) {
                  setMess(
                    "Please enter all the values in current row and then add next row"
                  );
                  setOpen(true);
                  return;
                }
                setAddData((pre) => [...pre, {}]);
              }}
            />
            {addData.length > 0 && <SaveButton onClick={() => add_items()} />}
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/zkj07f1EFAM",
                "https://youtu.be/3mM5h4-6hlI",
              ]}
            />
          </>
        }
      />
      {addData.length > 0 && (
        <Table
          headData={headData}
          body={addData.map((val, ind) => (
            <tr key={ind}>
              <td>
                <Input
                  placeholder={"PIN CODE"}
                  value={val.pin_code}
                  onChange={(e) => {
                    if (e.target.value.length < 7) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                pin_code: /^\d+$/.test(e.target.value)
                                  ? Number(e.target.value)
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={"AREA CODE"}
                  value={val.code}
                  onChange={(e) => {
                    if (e.target.value.length < 9) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                code: /^[a-zA-Z0-9]+$/.test(e.target.value)
                                  ? e.target.value
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={"AREA NAME"}
                  value={val.name}
                  onChange={
                    (e) => {
                      const inputValue = e.target.value;
                      if (inputValue?.length < 26) {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i === ind
                              ? {
                                  ...v,
                                  name: inputValue,
                                }
                              : { ...v }
                          )
                        );
                      }
                    }
                    // Check if the value contains only allowed characters
                    // if (/^[a-zA-Z0-9 ,."'\/]*$/.test(inputValue)) {
                    //   setAddData((pre) =>
                    //     pre.map((v, i) =>
                    //       i === ind
                    //         ? {
                    //             ...v,
                    //             name: /^[a-zA-Z0-9 ,."'\/]*$/.test(inputValue)
                    //               ? inputValue
                    //               : "",
                    //           }
                    //         : { ...v }
                    //     )
                    //   );
                    // }
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"DISTANCE (KM)"}
                  value={val.distance}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue?.length < 3) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                distance: onlyNumber.test(inputValue)
                                  ? inputValue
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={"TRANSPORT CHARGES"}
                  value={val.charge}
                  // onChange={(e) => {
                  //   const inputValue = e.target.value;
                  //   if (
                  //     inputValue?.length < 6 ||
                  //     /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/.test(inputValue) ||
                  //     e.target.value == 0
                  //   ) {
                  //     setAddData((pre) =>
                  //       pre.map((v, i) =>
                  //         i === ind
                  //           ? {
                  //               ...v,
                  //               charge:  /^(0|[1-9]\d{0,2})(\.\d{0,2})?$/.test(
                  //                 inputValue
                  //               )
                  //                 ? inputValue
                  //                 : "",
                  //             }
                  //           : { ...v }
                  //       )
                  //     );
                  //   }
                  // }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (
                      (inputValue?.length < 6 &&
                        /^(0|[1-9]\d{0,1})(\.\d{0,2})?$/.test(inputValue)) ||
                      event.target.value == 0
                    ) {
                      setAddData((prevData) => {
                        const updatedData = [...prevData];
                        updatedData[ind] = {
                          ...updatedData[ind],
                          charge: /^(0|[1-9]\d{0,1})(\.\d{0,2})?$/.test(
                            inputValue
                          )
                            ? inputValue
                            : "",
                        };
                        return updatedData;
                      });
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  type={"date"}
                  value={val.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind ? { ...v, date: e.target.value } : { ...v }
                        )
                      );
                    }
                  }}
                  // onBlur={() => {
                  //   if (addData[addData.length - 1].date?.length == 10) {
                  //     if (
                  //       DateValidator2(
                  //         moment(addData[addData.length - 1]?.date).format(
                  //           "DD/MM/YYYY"
                  //         ),
                  //         today,
                  //         eff_date,
                  //         `Effective Date cant be before ${moment(
                  //           eff_date
                  //         ).format("DD/MM/YYYY")} `,
                  //         `Effective Date cant be a future date`
                  //       ) != true
                  //     ) {
                  //       setMess(
                  //         ...DateValidator2(
                  //           moment(addData[addData.length - 1]?.date).format(
                  //             "DD/MM/YYYY"
                  //           ),
                  //           today,
                  //           eff_date,
                  //           `Effective Date cant be before ${moment(
                  //             eff_date
                  //           ).format("DD/MM/YYYY")} `,
                  //           `Effective Date cant be a future date`
                  //         )
                  //       );
                  //       setOpen(true);
                  //       setAddData((pre) =>
                  //         pre.map((v, i) =>
                  //           i == ind ? { ...v, date: "" } : { ...v }
                  //         )
                  //       );
                  //     }
                  //   }
                  // }}
                />
              </td>
              <td>
                <DeleteIcon
                  onClick={() =>
                    setAddData((pre) => pre.filter((v, i) => ind != i))
                  }
                />
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {area_code_loading ? (
        <PageLoading />
      ) : area_code_list.length == 0 ? (
        <NoData mt={30} />
      ) : (
        <TableNew
          nodata={area_code_list?.length == 0 ? true : false}
          headData={headData}
          body={area_code_list.map((val, ind) => (
            <tr key={ind}>
              <td>{val?.PINCODE || "-"}</td>
              <td>{val?.AREA_CODE}</td>
              <td>{val?.AREA_NAME}</td>
              <td>{val?.ONE_WAY_DIST}</td>
              <td>{val?.TRANSPORT_CHARGES}</td>

              <td>{moment(val?.formatted_date).format("DD-MMM-YYYY")}</td>
              <td>
                <DeleteIcon
                  onClick={() => {
                    setID(val.ID);
                    setOpen1(true);
                  }}
                />
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {open && (
        <SmallModal
          okbtn
          title={"Alerts"}
          mess={mess}
          isOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {open1 && (
        <SmallModal
          onClick={() => deleteItem()}
          title={"Alerts"}
          mess={"Are You Sure you want to delete"}
          isOpen={open1}
          setIsOpen={setOpen1}
        />
      )}
    </div>
  );
};

export default AreaCodeMaster;
