import React, { useEffect, useRef, useState } from "react";
import "./noti.css";
import { AnimatePresence, motion } from "framer-motion";
import { IoNotificationsSharp } from "react-icons/io5";
import { BsBell } from "react-icons/bs";
import { useSelector } from "react-redux";

const Notification = () => {
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const { home_data } = useSelector((state) => state.profile);
  const currentDate = new Date();

  const compareDates = (date) => {
    const validDateArray = date.split("-");
    const validDate = new Date(
      validDateArray[0],
      validDateArray[1] - 1,
      validDateArray[2]
    ); 
    if (currentDate >= validDate) {
      return true;
    } else if (currentDate < validDate) {
      return false;
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocused(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div ref={wrapperRef} >
      <div onClick={() => setFocused(!focused)} className="noti-icon">
        <IoNotificationsSharp size={25} style={{cursor:'pointer'}} color="#526485" />
      </div>
      <AnimatePresence mode="wait">
        {focused && (
          <motion.div
            className="noti-mess-box"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            style={{width:"300px"}}
            transition={{ duration: 1, bounce: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="noti-mess-header">Notifications</div>
            <div
              // style={{ width: "100%", height: "200px", overflowY: "scroll" }}
              className="noti-mess-main"
            >
              {home_data?.alerts.map((v, i) => (
                <div className="noti-mess-div" key={i}>
                  <div className="noti-bell-icon">
                    <BsBell size={18} color="#fff" />
                  </div>
                  <div
                    className="noti-mess-txt"
                    style={{ color: compareDates(v?.valid) ? "red" : "black" }}
                  >
                    {v?.message}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Notification;
