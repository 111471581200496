import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
import CardDataRow from "./CardDataRow";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import SmallModal from "../../components/modal/SmallModal";
import { Input } from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import { profile_update_api } from "../../utils/apis";
import Loader from "../../components/Loader";
import { get_profile_data } from "./UsersSlice";
import { get_complete_list } from "../../redux/slice/completeListSlice";

const Ainfo = () => {
  const [showUpdatePic, setShowUpdatePic] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { stateList, omcList } = useSelector((state) => state.completeList);
  const [rmvPic, setRmvPic] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { img_url } = useSelector((state) => state.profile);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");

  const { profile_data } = useSelector((state) => state.profile);
  const { user_id } = useSelector((state) => state.other);

  useEffect(() => {
    update_profile_data();
  }, [user_id]);
  const update_profile_data = () => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
    }
  };

  useEffect(() => {
    // store.dispatch(get_complete_list());
  }, [profile_data]);
  

  const handle_pic_update = async () => {
    if (selectedFile !== null) {
      try {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("action", "imageedit");
        formData.append("agencyid", user_id);
        formData.append("image", selectedFile);
        const responce = await profile_update_api(formData);
        setShowLoader(false);
        if (responce.success) {
          setSelectedFile("");
          setShowUpdatePic(false);
          toast.success(responce?.message);
          // store.dispatch(get_profile_data(userid));
          update_profile_data();
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess("Please select file first");
      setOpen(true);
    }
  };
  const remove_pic = async () => {
    try {
      try {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("action", "imageremove");
        formData.append("agencyid", user_id);
        const responce = await profile_update_api(formData);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce?.message);
          // store.dispatch(get_profile_data(userid));
          update_profile_data();
          setRmvPic(false);
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.p_card_header}>
        <div className={styles.card_heading}>Agency Information</div>
        <div className="flex aic gap10">
          <Button
            title={"REMOVE PROFILE PIC"}
            onClick={() => {
              if (img_url !== "") {
                setRmvPic(true);
              } else {
                toast.error("Please Upload Image First");
              }
            }}
          />
          <Button
            title={"UPDATE PROFILE PIC"}
            onClick={() => setShowUpdatePic(true)}
          />
        </div>
      </div>
      <div className={styles.p_card}>
        <CardDataRow title={"Distributorship ID"} value={profile_data?.agencyid} />
        <div className={styles.p_row_line} />
        <CardDataRow
          title={"Distributorship Name"}
          value={profile_data?.name?.toUpperCase()}
        />
        <div className={styles.p_row_line} />
        <CardDataRow title={"GSTIN"} value={profile_data?.gst} />
        <div className={styles.p_row_line} />
        <CardDataRow title={"OIL Company"} value={omcList[profile_data?.OMC]} />
        <div className={styles.p_row_line} />
        <CardDataRow
          title={"State"}
          value={stateList?.filter((v) => v?.code == profile_data?.state)[0]?.name}
          // value={data?.state}
        />
      </div>
      {showUpdatePic && (
        <SmallModal
          okBtnHide={false}
          isOpen={showUpdatePic}
          setIsOpen={setShowUpdatePic}
          style={{ width: "25rem" }}
          okbtn={setShowUpdatePic}
          title={"UPLOAD PICTURE"}
          mess={
            <div className="pin-ver">
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      value={selectedFile?.name}
                      placeholder={"File Name"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "0 1% ",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="label">
                    <input
                      type="file"
                      accept=".jpg, .jpeg"
                      required
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const fileType = selectedFile.type;
                          if (
                            fileType === "image/jpeg" ||
                            fileType === "image/jpg"
                          ) {
                            setSelectedFile(selectedFile);
                          } else {
                            alert(
                              "Please select a valid image file (either .jpg or .jpeg)."
                            );
                            e.target.value = null;
                          }
                        }
                      }}
                    />

                    <span style={{ fontSize: "13px" }}>Browse</span>
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                  <Button title={"UPLOAD"} onClick={handle_pic_update} />
                </div>
              </div>
            </div>
          }
        />
      )}
      {rmvPic && (
        <SmallModal
          isOpen={rmvPic}
          setIsOpen={setRmvPic}
          onClick={() => remove_pic()}
          title={"Confirm"}
          mess={"ARE YOU SURE TO REMOVE PROFILE PIC"}
        />
      )}
      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default Ainfo;
