import React from "react";
import "./sidebar.css";
import TransactionList from "../list/TransactionList";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import CashnBankList from "../list/CashnBankList";
import TransReportList from "../list/TransReportList";
import { MdOutlineWatchLater } from "react-icons/md";
import MasterDataList from "../list/MasterDataList";
import PayRollList from "../list/PayRollList";
import { useLocation, useNavigate } from "react-router-dom";
import FinacialReportsList from "../list/FinacialReportsLinst";
import GstReturnList from "../list/GstReturnList";
import TdsTclList from "../list/TdsTcsList";
import { FiHome } from "react-icons/fi";
// import { useWindowSize } from "../../utils/data";
import store from "../../redux/store";
import { setSidebarWidth } from "../../redux/slice/actionSlice";
import { useSelector } from "react-redux";
import { ProfileList } from "../list/ProfileList";
import { useWindowSize } from "../../utils/Hook/UseWindowSizeHook";

const SidebarProfile = () => {
  const windowSize = useWindowSize();
  const { focused } = useSelector((state) => state.actions);
  const location = useLocation();
  const pathName = location?.pathname;
  const navigate = useNavigate();
  const handle_close_drawer = () => {
    if (windowSize?.width < 907) {
      {
        store.dispatch(setSidebarWidth(false));
      }
    }
  };
  return (
    <div className="sidebar-main">
      <ProfileList
        close={handle_close_drawer}
        path={pathName}
        focused={focused}
      />
    </div>
  );
};

export default SidebarProfile;
