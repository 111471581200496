import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const get_day_end_day_list = createAsyncThunk(
  "day_end_day_list",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "dayendlist");
    body.append("agencyid", userId);
    body.append("date", data?.date);
    console.log(" data?.selectLink ===", data?.selectLink);
    
    data?.selectLink.forEach((v, i) => {
      body.append("table_checkbox[]", v);
    });
    //     action:dayendlist
    // agencyid:7777777777
    // date:2024-07-31
    // https://mylpg.pmpframe.com/api/reports/day_end_reports.php
    const response = await fetch(base_url + "reports/day_end_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

const initialState = {
  day_end_date_list: [],
  day_end_date_loading: false,
  cylinder_stock_list: [],
  arb_stock_list: [],
  bank_transaction_day_list: [],
  cash_transaction_day_list: [],
};

const dayEndDateSlice = createSlice({
  name: "cylinder_purchase_data",
  initialState: initialState,
  extraReducers: (builder) => {
    // transition complete list

    builder.addCase(get_day_end_day_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.day_end_date_loading = false;

      if (data?.success) {
        state.day_end_date_list = data.datalist;
        state.cylinder_stock_list = data.cylinder_stock;
        state.arb_stock_list = data.arb_stock;
        state.bank_transaction_day_list = data.bank_transactions;
        state.cash_transaction_day_list = data.cash_bank_transactions;
      } else {
        state.day_end_date_list = [];
        state.cylinder_stock_list = [];
        state.arb_stock_list = [];
        state.bank_transaction_day_list = [];
        state.cash_transaction_day_list = [];
      }
    });
    builder.addCase(get_day_end_day_list.pending, (state, action) => {
      state.day_end_date_loading = true;
    });
    builder.addCase(get_day_end_day_list.rejected, (state, action) => {
      state.day_end_date_loading = false;
    });
  },
});

export default dayEndDateSlice.reducer;
