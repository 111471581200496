import React from "react";
import "./layout.css";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import {
  setSidebarProfileWidth,
  setSidebarWidth,
} from "../../redux/slice/actionSlice";
import store from "../../redux/store";
import { Drawer } from "antd";
import styles from "../../screens/profile/profile.module.css";
import SidebarProfile from "../sidebar/SidebarProfile";
import { CgMenuLeftAlt } from "react-icons/cg";

const LayoutProfile = () => {
  const { smallSidebarProfileWidth } = useSelector((state) => state.actions);

  return (
    <div>
      <div  className={styles.profile_head} >
        <div
          className={styles.title_main}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>Account Info</div>
          <div className={styles.profile_icon}>
            <CgMenuLeftAlt
              size={30}
              className="cursor"
              color="#9e72ff"
              onClick={() => {
                store.dispatch(
                  setSidebarProfileWidth(!smallSidebarProfileWidth)
                );
              }}
            />
          </div>
        </div>
        <div className={styles.subtitle_main}>
          You have full control to manage your own account setting
        </div>
      </div>
      <div
        className="layout-main"
        style={{ paddingTop: "1rem", backgroundColor: "#f5f6fa" }}
      >
        <div
          className="layout-sidebar"
          style={{
            width: smallSidebarProfileWidth ? "4rem" : "15rem",
            transition: "width ease 1s",
            borderTop: "1px solid #e5e9f2",
            paddingTop:'1rem'
          }}
        >
          <SidebarProfile />
        </div>
        <div className="sidebar-new">
          <Drawer
            width={"16rem"}
            placement={"right"}
            destroyOnClose={true}
            rootClassName={"sidebar-new"}
            Header={false}
            onClose={() =>
              store.dispatch(setSidebarProfileWidth(!smallSidebarProfileWidth))
            }
            open={smallSidebarProfileWidth}
          >
            <SidebarProfile />
          </Drawer>
        </div>

        <div className={`layout-body `} style={{ backgroundColor: "#f5f6fa" }}>
          <div className="layout-outlet2">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutProfile;
