import React from "react";
import "./input.css";

export const DropInput = ({
  label,
  list,
  onChange,
  value,
  onBlur,
  disabled,
  placeholder,
  id = "hsn",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {label && <div className="inp-label">{label}</div>}
      <div className="inp-wrap" style={{ position: "relative" }}>
        <input
          list={id}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
          className="inp"
        />
        <datalist
          // style={{ position: "absolute", top: "0", backgroundColor: "white" }}
          id={id}
        >
          {list}
        </datalist>
      </div>
    </div>
  );
};
