import React, { useMemo } from "react";
import Indane from "../../../assets/Indane.png";
import moment from "moment";
import { useSelector } from "react-redux";
import { bankDetails } from "../../../utils/data";
import useAmountToWords from "../../../utils/Hook/useAmountToWords";
var converter = require("number-to-words");

export const TaxInvoice = ({
  listdata = {},
  previewRef,
  title,
  table_header,
  table_body,
  tax_total,
  igst_amount,
  cgst_amount,
  sgst_amount,
  net_total = 0,
  bank,
  MOT,
  Vehicle,
  dateandtime,
  placeofsupply,
  statename,
  price_show = true,
  nc_data = false,
  security_deposite,
  total_in_amt,
  other,
}) => {

  const { profile_data } = useSelector((state) => state.profile);

  const convertAmountToWords = useAmountToWords(listdata?.REFUNDED_AMT ? listdata?.REFUNDED_AMT :net_total);
  // const convertAmountToWords = useMemo(() => {
  //   const total = listdata?.REFUNDED_AMT ? listdata?.REFUNDED_AMT :net_total;

  //   if (isNaN(total)) {
  //     return ''; // or any appropriate handling if total is not a number
  //   }
  
  //   const totalAmount = title === "ITV/RC"
  //     ? Number(total) + Number(listdata?.SECURITY_DEPOSIT)
  //     : Number(total) || 0;
  
  //   const integerPart = Math.floor(totalAmount);
  //   const decimalPart = Math.round((totalAmount - integerPart) * 100);
  
  //   const integerWords = converter.toWords(integerPart);
  //   const decimalWords = converter.toWords(decimalPart);
  
  //   let result = `${integerWords} rupees`;
  //   if (decimalPart > 0) {
  //     result += ` and ${decimalWords} paisa`;
  //   }
    
  //   return result?.toUpperCase();
  // }, [net_total]);
  return (
    <div id="preview_div_body" ref={previewRef}>
      <div
        id="preview_div_body_sub"
        style={{ height: "fit-content", overflow: "auto" }}
      >
        <table style={{ width: "90%", margin: "auto", marginTop: "-47px" }}>
          <tr valign="top">
            <td className="inv-td">
              <br colSpan={3} />
              <br />
              <br />
              <center>
                <span style={{ fontSize: "20px" }}>
                  {title == "VOUCHER"
                    ? `${listdata?.VOUCHER_NAME} ${title}`
                    : title}
                </span>
              </center>
              <span
                style={{
                  float: "right",
                  marginTop: "-10px",
                  paddingRight: "10px",
                }}
              ></span>
              <table
                style={{
                  width: "100%",
                  borderSpacing: 0,
                  borderCollapse: 0,
                  padding: "-1px",
                  margin: "-1px",
                  fontSize: "14px",
                  border: "1px solid #808b96",
                }}
              >
                <tr valign="top">
                  <td
                    className="inv-td"
                    width="80%"
                    style={{
                      padding: "10px",
                      borderBottom: "2px solid #808b96",
                    }}
                  >
                    <div style={{ display: "" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "fit-content",
                            alignItems: "flex-start",
                          }}
                        >
                          <b style={{ float: "left", fontSize: "18px" }}>
                            <span id="Aname">
                              {profile_data?.name?.toUpperCase()}
                            </span>
                          </b>
                          <div style={{ width: "500px" }}>
                            <span
                              style={{
                                float: "left",
                                textAlign: "left",
                                marginTop: ".5rem",
                              }}
                            >
                              ADDRESS: {profile_data?.ADDRESS || "NOT DEFINED"}
                            </span>
                          </div>
                        </div>
                        <b style={{ float: "right", fontSize: "18px" }}>
                          <img
                            id="Aname"
                            style={{ width: "120px", height: "60px" }}
                            src={Indane}
                          />
                        </b>
                      </div>
                      <span
                        style={{
                          float: "left",
                          width: "50%",
                          textAlign: "left",
                        }}
                      >
                        <b>GSTIN : </b>
                        <span id="dgstin">{profile_data?.gst}</span>
                        <br />
                        <b> MOB NO: </b>
                        <span id="llno">{profile_data?.mobile}</span>
                      </span>
                      <br />

                      <span
                        style={{
                          float: "right",
                          marginTop: "-15px",
                          width: "50%",
                          textAlign: "left",
                        }}
                      >
                        <br />

                        {profile_data?.email}
                      </span>
                      <br />
                      <br />

                      {/* <!--  DISPLAY STARTS HERE --> */}
                      <table
                        style={{
                          width: "100%",
                          border: "1px solid",
                          borderColor: "#808B96",
                          fontSize: "12px",
                          padding: "10px",
                        }}
                      >
                        <tr valign="top">
                          <td
                            className="inv-td"
                            width="50%"
                            style={{
                              border: "1px solid",
                              borderColor: "#808B96",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            {title == "TAX INVOICE" || title == "CREDIT / DEBIT INVOICE" ? "INVOICE" : title} NUMBER
                            :{listdata?.SR_NO} <br />
                            {title == "TAX INVOICE" || title == "CREDIT / DEBIT INVOICE"? "INVOICE" : title} DATE :
                            {moment(listdata?.SR_NO_DATE).format("DD-MMM-YYYY")}
                            <br />
                            {title == "TAX INVOICE" && listdata?.PO_NUMBER && (
                              <>
                                <label>PO NO : {listdata?.PO_NUMBER}</label>
                                <br />
                                PO DATE :
                                {listdata?.PO_DATE
                                  ? moment(listdata?.PO_DATE).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "NA"}
                                <br />
                              </>
                            )}
                            {(listdata?.title == "SALES RETURN" ||
                              title == "PURCHASE ORDER") && <br />}
                            {(title == "DELIVERY CHALLAN" ||
                              listdata?.title == "SALES RETURN" ||
                              title == "PURCHASE ORDER") &&
                              listdata?.REF_NO && (
                                <>
                                  <label>
                                    INVOICE REF. NO : {listdata?.REF_NO}
                                  </label>
                                  <br />
                                  INVOICE REF. DATE :
                                  {moment(listdata?.REF_NO_DATE).format(
                                    "DD-MMM-YYYY"
                                  )}
                                  <br />
                                </>
                              )}
                            {/* {title == "PURCHASE ORDER" && listdata?.REF_NO && (
                              <>
                                <label>
                                  INVOICE REF. NO : {listdata?.REF_NO}
                                </label>
                                <br />
                                INVOICE REF. DATE :
                                {moment(listdata?.REF_NO_DATE).format(
                                  "DD-MMM-YYYY"
                                )}
                                <br />
                              </>
                            )} */}
                            {(title == "ITV/RC" || title == "OTV / TTV /TV") &&
                              listdata?.CUST_ID && (
                                <>
                                  <br />
                                  <label style={{ marginTop: "1rem" }}>
                                    CUSTOMER NO : {listdata?.CUST_ID}
                                  </label>
                                  <br />
                                </>
                              )}
                            {/* {(title == "ITV/RC" || title == "OTV / TTV /TV") &&
                              listdata?.CUST_ID && (
                                <>
                                  <br />
                                  <label style={{ marginTop: "1rem" }}>
                                    CUSTOMER NO : {listdata?.CUST_ID}
                                  </label>
                                  <br />
                                </>
                              )} */}
                            {listdata?.title == "DOMESTIC SALES" && (
                              <>
                                <br />
                                <label style={{ marginTop: "1rem" }}>
                                  PLACE OF SUPPLY : {listdata?.place_of_supply}
                                </label>
                                <br />
                              </>
                            )}
                          </td>
                          {(MOT ||
                            Vehicle ||
                            dateandtime ||
                            placeofsupply ||
                            statename ||
                            title == "CREDIT / DEBIT INVOICE") && (
                            <td
                              className="inv-td"
                              width="50%"
                              style={{
                                border: "1px solid",
                                borderColor: "#808B96",
                                fontSize: "12px",
                                textAlign: "left",
                              }}
                            >
                              <>
                                {title == "CREDIT / DEBIT INVOICE" &&
                                  listdata?.PO_NUMBER && (
                                    <>
                                      <label>
                                        INVOICE REF. NO : {listdata?.PO_NUMBER}
                                      </label>
                                      <br />
                                      INVOICE REF. DATE :
                                      {moment(listdata?.PO_DATE).format(
                                        "DD-MMM-YYYY"
                                      )}
                                      <br />
                                    </>
                                  )}
                                {MOT && (
                                  <>
                                    <label style={{ marginRight: "180px" }}>
                                      MODE OF TPT :{MOT?.toUpperCase()}
                                    </label>
                                    <br />
                                  </>
                                )}
                                {Vehicle && (
                                  <>
                                    <label>
                                      VEHICLE NO : {Vehicle || "NA"}
                                    </label>
                                    <br />
                                  </>
                                )}

                                {dateandtime && (
                                  <>
                                    <label style={{ marginRight: "120px" }}>
                                      DATE&TIME OF SUPPLY:{" "}
                                      {moment(dateandtime)?.format(
                                        "DD/MM/YYYY hh:mm:ss"
                                      )}
                                    </label>
                                    <br />
                                  </>
                                )}
                                {placeofsupply && (
                                  <>
                                    <label>
                                      PLACE OF SUPPLY : {placeofsupply}
                                    </label>
                                    <br />
                                  </>
                                )}
                                {statename && (
                                  <>
                                    <label>STATE NAME : {statename}</label>
                                    <br />
                                  </>
                                )}
                                <br />
                              </>
                            </td>
                          )}
                        </tr>
                      </table>
                      <br />
                      {!nc_data && (
                        <table
                          style={{
                            width: "100%",
                            border: "1px solid",
                            borderColor: "#808B96",
                            fontSize: "14px",
                          }}
                        >
                          <tr
                            bgcolor="#808B96"
                            style={{ borderBottom: "1px solid #808B96" }}
                            height="30px"
                          >
                            <td
                              className="inv-td"
                              width="50%"
                              align="center"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              {title == "PURCHASE ORDER" && "Purchase From"}
                              {(title == "TAX INVOICE" ||
                                title == "VOUCHER" ||
                                title == "DELIVERY CHALLAN" ||
                                title == "CREDIT / DEBIT INVOICE" ||
                                title == "QUOTATION") &&
                                "Details of the Receiver (Billed To)	"}
                            </td>
                            <td
                              className="inv-td"
                              width="50%"
                              align="center"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              {title == "PURCHASE ORDER" && "Ship it to"}
                              {(title == "TAX INVOICE" ||
                                title == "DELIVERY CHALLAN" ||
                              title == "CREDIT / DEBIT INVOICE" ||
                                title == "VOUCHER" ||
                                title == "QUOTATION") &&
                                "Details of the Receiver (Shipped To)"}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="inv-td"
                              style={{ textAlign: "left" }}
                            >
                              Party Name : {listdata?.CVO_NAME}
                              <br />
                              <p
                                style={{
                                  maxWidth: "350px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {" "}
                                Address :{listdata?.CVO_ADDRESS}
                              </p>
                              State Code :{listdata?.B_STATE}
                              <br />
                              <b>GSTIN : </b>
                              {listdata?.CVO_TIN || "NA"}
                              <br />
                            </td>
                            {(title == "PURCHASE ORDER" ||
                              title == "TAX INVOICE" ||
                              title == "DELIVERY CHALLAN" ||
                              title == "CREDIT / DEBIT INVOICE" ||
                              title == "VOUCHER" ||
                              title == "QUOTATION") && (
                              <td
                                className="inv-td"
                                style={{ textAlign: "left" }}
                              >
                                Party Name : {listdata?.SHIP_CVO_NAME}
                                <br />
                                <p
                                  style={{
                                    maxWidth: "350px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {" "}
                                  Address :{listdata?.SHIP_CVO_ADDRESS}
                                </p>
                                State Code :{listdata?.SHIP_B_STATE}
                                <br />
                                <b>GSTIN : </b>
                                {listdata?.SHIP_CVO_TIN}
                                <br />
                              </td>
                            )}
                          </tr>
                        </table>
                      )}
                      {title != "VOUCHER" && (
                        <div id="data_table">
                          <br />

                          <div>
                            <table
                              id="preview_data_table"
                              className="table_cls"
                              style={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: "#808B96",
                                fontSize: "14px",
                              }}
                            >
                              <thead>
                                <tr
                                  height="30px"
                                  style={{ borderBottom: "1px solid #808B96" }}
                                >
                                  {table_header?.map((v, i) => (
                                    <th
                                      key={i}
                                      bgcolor="#ffffff"
                                      height="30px"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                        border: "none",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {v}
                                    </th>
                                  )) || table_header}
                                </tr>
                              </thead>
                              <tbody id="preview_data_table_body">
                                {table_body}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                      <div>
                        <br />

                        <div>
                          <table
                            id="data_table2"
                            style={{
                              width: "100%",
                              border: "1px solid",
                              borderColor: "#808B96",
                              fontSize: "14px",
                              padding: "10px",
                            }}
                          >
                            <tr style={{ backgroundColor: "red !important" }}>
                              <td className="inv-td">
                                <table style={{ width: "100%" }}>
                                  <tbody>
                                    {listdata?.make_of_payment && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Mode of{" "}
                                          {(listdata?.VOUCHER_NAME ==
                                            "RECEIPT" &&
                                            "Receipt") ||
                                            (listdata?.VOUCHER_NAME ==
                                              "PAYMENT" &&
                                              "Payment")}{" "}
                                          :
                                          <span id="nars">
                                            {listdata?.make_of_payment}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {listdata?.NARRATION && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          NARRATION :
                                          <span id="nars">
                                            {listdata?.NARRATION}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {listdata?.AMOUNT && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          AMOUNT :
                                          <span id="nars">
                                            {listdata?.AMOUNT}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {(title == "ITV/RC" ) && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TOTAL INVOICE AMOUNT :
                                          <b>{net_total}</b>
                                        </td>
                                      </tr>
                                    )}{" "}
                                    {(title == "ITV/RC" ||
                                      title == "OTV / TTV /TV") && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TOTAL SECURITY DEPOSIT AMOUNT :
                                          <b>{listdata?.SECURITY_DEPOSIT}</b>
                                        </td>
                                      </tr>
                                    )}
                                    {(
                                      title == "OTV / TTV /TV") && (
                                      <>
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          LESS IMPREST AMOUNT DEDUCTED :
                                          <b>{listdata?.IMPREST_AMT}</b>
                                        </td>
                                      </tr>
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                         LESS SERVICE CHARGES :
                                          <b>{listdata?.SERVICE_AMT}</b>
                                        </td>
                                      </tr>
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                        TOTAL REFUNDED AMOUNT (In Figure) :
                                          <b>{listdata?.REFUNDED_AMT}</b>
                                        </td>
                                      </tr>
                                      </>
                                    )}
                                    {(title == "ITV/RC" ) && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TOTAL AMOUNT :
                                          <b>
                                            {Number(net_total) +
                                              Number(
                                                listdata?.SECURITY_DEPOSIT
                                              )}
                                          </b>
                                        </td>
                                      </tr>
                                    )}
                                     {/* {(
                                      title == "OTV / TTV /TV") && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TOTAL INVOICE AMOUNT :
                                          <b>{net_total}</b>
                                        </td>
                                      </tr>
                                    )}{" "} */}
                                    {price_show && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TOTAL INVOICE AMOUNT (In Words) :
                                          <b>{convertAmountToWords}</b>
                                        </td>
                                      </tr>
                                    )}
                                    {listdata?.TERMS && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          TERMS :
                                          <span id="nars">
                                            {listdata?.TERMS}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {listdata?.FOOT_NOTES && (
                                      <tr valign="top">
                                        <td
                                          className="inv-td"
                                          colSpan="3"
                                          style={{
                                            fontSize: "14px",
                                            padding: "4px",
                                            textAlign: "left",
                                          }}
                                        >
                                          FOOT NOTES :
                                          <span id="nars">
                                            {listdata?.FOOT_NOTES}
                                          </span>
                                        </td>
                                      </tr>
                                    )}
                                    {bank && (
                                      <>
                                        <tr valign="top" id="bankdetailstr">
                                          <td
                                            className="inv-td"
                                            colSpan="3"
                                            style={{
                                              fontSize: "14px",
                                              padding: "4px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Bank Details: <br />
                                          </td>
                                        </tr>
                                        <tr id="banktr">
                                          <td
                                            className="inv-td"
                                            style={{ padding: "4px" }}
                                          >
                                            <table
                                              style={{
                                                width: "100%",
                                                borderCollapse: "collapse",
                                                border: "1px solid",
                                                borderColor: "#808B96",
                                                fontSize: "14px",
                                              }}
                                            >
                                              <thead>
                                                <tr
                                                  height="30px"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #808B96",
                                                  }}
                                                >
                                                  <th
                                                    align="left"
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                      border: "none",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Bank Name
                                                  </th>
                                                  <th
                                                    align="left"
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                      border: "none",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Account No
                                                  </th>
                                                  <th
                                                    align="left"
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                      border: "none",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    IFSC Code
                                                  </th>
                                                  <th
                                                    align="left"
                                                    style={{
                                                      backgroundColor: "white",
                                                      color: "black",
                                                      border: "none",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Branch Name
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody id="bank_details_table_body">
                                                {bank
                                                  ?.slice(0, 1)
                                                  ?.map((bank, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        className="inv-td"
                                                        align="left"
                                                      >
                                                        {
                                                          bankDetails?.filter(
                                                            (v) =>
                                                              v?.value ==
                                                              bank?.BANK_NAME
                                                          )[0]?.bankName
                                                        }
                                                      </td>
                                                      <td
                                                        className="inv-td"
                                                        align="left"
                                                      >
                                                        {bank?.BANK_ACC_NO}
                                                      </td>
                                                      <td
                                                        className="inv-td"
                                                        align="left"
                                                      >
                                                        {bank?.BANK_IFSC_CODE}
                                                      </td>
                                                      <td
                                                        className="inv-td"
                                                        align="left"
                                                      >
                                                        {bank?.BANK_BRANCH}
                                                      </td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                     {(
                                      title != "OTV / TTV /TV") && (
                                    <tr valign="top">
                                      <td
                                        className="inv-td"
                                        colSpan="3"
                                        style={{
                                          fontSize: "14px",
                                          padding: "4px",
                                          textAlign: "left",
                                        }}
                                      >
                                        DECLARATION : This is system generated.
                                        Hence no stamp and signature is
                                        required.
                                        <br />
                                      </td>
                                    </tr>
                                      )}
                                  </tbody>
                                </table>
                              </td>
                              {price_show && (
                                <td className="inv-td">
                                  {title != "VOUCHER" ? (
                                    <table
                                      id="data_table3"
                                      style={{
                                        width: "100%",
                                        minWidth: "200px",
                                        marginLeft: "-1%",
                                        border: "1px solid",
                                        borderColor: "#808B96",
                                        fontSize: "15px",
                                        padding: "10px",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      <tr>
                                        <td
                                          className="inv-td"
                                          colSpan="2"
                                          width="24.5%"
                                          style={{
                                            border: "1px solid",
                                            borderColor: "#808B96",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            padding: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ flex: 5 }}>
                                              TAXABLE{" "}
                                            </div>
                                            <div style={{ flex: 1 }}>:</div>
                                            <div style={{ flex: 5 }}>
                                              {tax_total}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="inv-td"
                                          colSpan="2"
                                          width="24.5%"
                                          style={{
                                            border: "1px solid",
                                            borderColor: "#808B96",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            padding: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ flex: 5 }}>IGST</div>
                                            <div style={{ flex: 1 }}>:</div>
                                            <div style={{ flex: 5 }}>
                                              {igst_amount}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="inv-td"
                                          colSpan="2"
                                          width="24.5%"
                                          style={{
                                            border: "1px solid",
                                            borderColor: "#808B96",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            padding: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ flex: 5 }}>CGST</div>
                                            <div style={{ flex: 1 }}>:</div>
                                            <div style={{ flex: 5 }}>
                                              {cgst_amount}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="inv-td"
                                          colSpan="2"
                                          width="24.5%"
                                          style={{
                                            border: "1px solid",
                                            borderColor: "#808B96",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            padding: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ flex: 5 }}>SGST</div>
                                            <div style={{ flex: 1 }}>:</div>
                                            <div style={{ flex: 5 }}>
                                              {sgst_amount}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      {other && (
                                        <tr>
                                          <td
                                            className="inv-td"
                                            colSpan="2"
                                            width="24.5%"
                                            style={{
                                              border: "1px solid",
                                              borderColor: "#808B96",
                                              fontSize: "15px",
                                              textAlign: "left",
                                              padding: "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div style={{ flex: 5 }}>
                                                OTHER
                                              </div>
                                              <div style={{ flex: 1 }}>:</div>
                                              <div style={{ flex: 5 }}>
                                                {other}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td
                                          className="inv-td"
                                          colSpan="2"
                                          width="24.5%"
                                          style={{
                                            border: "1px solid",
                                            borderColor: "#808B96",
                                            fontSize: "15px",
                                            textAlign: "left",
                                            padding: "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div style={{ flex: 5 }}>TOTAL</div>
                                            <div style={{ flex: 1 }}>:</div>
                                            <div style={{ flex: 5 }}>
                                              {net_total}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      {security_deposite && (
                                        <tr>
                                          <td
                                            className="inv-td"
                                            colSpan="2"
                                            width="24.5%"
                                            style={{
                                              border: "1px solid",
                                              borderColor: "#808B96",
                                              fontSize: "15px",
                                              textAlign: "left",
                                              padding: "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              <div style={{ flex: 5 }}>
                                                SECURITY DEPOSIT
                                              </div>
                                              <div style={{ flex: 1 }}>:</div>
                                              <div style={{ flex: 5 }}>
                                                {security_deposite}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {total_in_amt && (
                                        <tr>
                                          <td
                                            className="inv-td"
                                            colSpan="2"
                                            width="24.5%"
                                            style={{
                                              border: "1px solid",
                                              borderColor: "#808B96",
                                              fontSize: "15px",
                                              textAlign: "left",
                                              padding: "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              <div style={{ flex: 5 }}>
                                                INVOICE AMOUNT{" "}
                                              </div>
                                              <div style={{ flex: 1 }}>:</div>
                                              <div style={{ flex: 5 }}>
                                                {/* {Number(net_total) -
                                              Number(security_deposite)} */}
                                                {Number(net_total) +
                                                  Number(security_deposite)}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </table>
                                  ) : (
                                    <>
                                      {listdata?.intrument_details ? (
                                        <p>
                                          Instrument Details:{" "}
                                          <span>
                                            {listdata?.intrument_details}
                                          </span>{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                            </tr>
                          </table>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          {nc_data && (
                            <p
                              style={{
                                textAlign: "left",
                                marginTop: "50px",
                                width: "50%",
                              }}
                            >
                              CUSTOMER SIGNATURE
                            </p>
                          )}
                          <p
                            style={{
                              textAlign: "right",
                              marginTop: "50px",
                              width: nc_data ? "50%" : "100%",
                            }}
                          >
                            STAMP AND SIGNATURE
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
