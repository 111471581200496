import React from "react";
import styles from "./profile.module.css";

const CardDataRow = ({ title, value }) => {
  return (
    <div className={styles.p_data_row}>
      <div className={styles.p_row_title}>{title}</div>
      <div>:</div>
      <div className={styles.p_row_value}>{value}</div>
    </div>
  );
};

export default CardDataRow;
