import React, { useEffect, useState } from "react";
import "./auth.css";
import "./login.css";
import Input from "../../components/inputs/Input";
import Button from "../../components/btn/Button";
import { BiLogIn } from "react-icons/bi";
import { FiArrowLeft } from "react-icons/fi";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import AuthBg from "../../components/authbg/AuthBg";
import Dropdown from "../../components/inputs/Dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import Tooltip from "../../components/tooltip/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { EmailValidation } from "../../utils/validator";
import SmallModal from "../../components/modal/SmallModal";
export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=.*[a-zA-Z]).{8,12}$/;

const Signup = () => {
  const [userData, setUserData] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [stateFreeze, setStateFreeze] = useState(false);
  const {
    stateList,
    firmList,
    omcList,
    refillList,
    turnoverList,
    payrollList,
  } = useSelector((state) => state.completeList);

  const onSignUp = async () => {
    if (
      !userData.id ||
      userData?.id.length < 6 ||
      userData?.id.length > 10 ||
      !userData?.name ||
      userData?.name.trim().length == 0 ||
      !userData?.mobile ||
      userData?.mobile.length != 10 ||
      !userData?.email ||
      !EmailValidation.test(userData.email) ||
      !userData?.gst ||
      !userData?.state ||
      !userData?.firm ||
      !userData?.omc ||
      !userData?.refill ||
      !userData?.turnover ||
      !userData?.payroll ||
      !userData?.pass ||
      !passwordRegex.test(userData?.pass) ||
      !userData.cpass ||
      !passwordRegex.test(userData?.cpass) ||
      userData?.cpass != userData?.pass
    ) {
      setUserData({
        ...userData,
        idmess: !userData.id
          ? "Agency Id is required"
          : userData?.id.length < 6
          ? "Agency Id must contain atleast 6 digit"
          : userData?.id.length > 10
          ? "Agency Id less then or equal to 11 digit"
          : "",
        idval: !userData.id ? true : false,
        namemess: !userData?.name
          ? "DISTRIBUTORSHIP NAME Can't Be Empty."
          : userData?.name.trim().length == 0
          ? "DISTRIBUTORSHIP NAME Can't Be Empty."
          : "",
        nameval: !userData?.name ? true : false,
        mobilemess: !userData?.mobile
          ? "Dealer Mobile is required"
          : userData?.mobile.length != 10
          ? "MOBILE NUMBER Must Be Valid Number."
          : "",
        mobileval: !userData?.mobile ? true : false,
        emailmess: !userData?.email
          ? "Email ID is required"
          : !EmailValidation.test(userData.email)
          ? "Please Enter Proper Email."
          : "",
        emailval: !userData?.email ? true : false,
        gstmess: !userData?.gst
          ? "GSTIN is required"
          : userData?.gst?.length != 15
          ? "GST Should be 15 Characters"
          : "",
        gstval: !userData?.gst ? true : false,
        statemess: !userData?.state ? "State is required" : "",
        stateval: !userData?.state ? true : false,
        firmmess: !userData?.firm ? "Firm Type is required" : "",
        firmval: !userData?.firm ? true : false,
        omcmess: !userData?.omc ? "OMC is required" : "",
        omcval: !userData?.omc ? true : false,
        refillmess: !userData?.refill ? "Refill Sale Quantity is required" : "",
        refillval: !userData?.refill ? true : false,
        turnovermess: !userData?.turnover
          ? "Annual Turnover of previous financial year is required"
          : "",
        turnoverval: !userData?.turnover ? true : false,
        payrollmess: !userData?.payroll
          ? "Payroll & ESI Filling is required"
          : "",
        payrollval: !userData?.payroll ? true : false,
        passmess: !userData?.pass ? "Password is required" : "",
        passval: !userData?.pass ? true : false,
        passval:
          !passwordRegex.test(userData?.pass) && userData?.pass != ""
            ? true
            : false,
        passmess:
          !passwordRegex.test(userData?.pass) && userData?.pass != ""
            ? "Password must be a combination of @,A,a,1."
            : "",
        cpassmess: !userData?.cpass ? "Confirm Password is required" : "",
        cpassval: !userData?.cpass ? true : false,
        cpassval:
          !passwordRegex.test(userData?.cpass) && userData?.cpass != ""
            ? true
            : false,
        cpassmess:
          !passwordRegex.test(userData?.cpass) && userData?.cpass != ""
            ? "c Password must be a combination of @,A,a,1."
            : "",
        cpassval: userData?.cpass != userData?.pass ? true : false,
        cpassmess:
          userData?.cpass != userData?.pass ? "Password not Matched" : "",
      });
      return;
    }
    setLoading(true);
    const body = new FormData();
    body.append("action", "signup");
    body.append("userid", userData?.id);
    body.append("email", userData?.email);
    body.append("mobile", userData?.mobile);
    body.append("name", userData?.name.trim());
    body.append("gst", userData?.gst);
    body.append("state", userData?.state);
    body.append("address", userData?.address);
    body.append("district", userData?.district);
    body.append("pincode", userData?.pincode);
    body.append("omc", userData?.omc);
    body.append("sale_qty", userData?.refill);
    body.append("turnover", userData?.turnover);
    body.append("firm", userData?.firm);
    body.append("payroll", userData?.payroll);
    body.append("password", userData?.pass);
    body.append("cpassword", userData?.cpass);

    try {
      const responce = await fetch(base_url + "signup.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        navigate("/login");
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gstcheck = async (gst_no) => {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      gst_no
    );
    if (regTest) {
      try {
        const body = new FormData();
        body.append("gstin", gst_no);
        const responce = await fetch(base_url + "gstfetch.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        if (res.error == false) {
          setStateFreeze(true);
          setUserData({
            ...userData,
            state: gst_no.slice(0, 2),
            statemess: "",
            gst: gst_no,
            gstmess: "",
            address: res?.address,
            district: res?.district,
            pincode: res?.pincode,
          });
        } else {
          toast.error(res.message);
          setUserData({
            ...userData,
            gstmess: "Please Enter Valid GST",
            gst: gst_no,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setUserData({ ...userData, gstmess: "Please Enter Valid GST" });
    }
  };

  return (
    <AuthBg>
      {loading && <Loader loading={loading} />}
      <div className="login-inner-div">
        <div className="sidebar-logo aic jcc ">
          <img src={logoicon} className="s_logo" />
          <img src={logotxt} className="s_logotxt" />
        </div>
        <div className="login-text mt10">Create your account</div>
        <div className="auth-input-row">
          <Input
            placeholder={"Enter Agency ID"}
            value={userData.id}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");

              if (numericValue.length <= 10) {
                setUserData({
                  ...userData,
                  id: numericValue,
                  idmess: "",
                  idval: false,
                });
              }
            }}
            // onBlur={() => {
            //   if (userData?.id?.length < 6) {
            //     setUserData({
            //       ...userData,
            //       idmess: "Agency must contain atleast 6 digit",
            //       idval: false,
            //     });
            //   }
            // }}
            error={userData.idmess}
            bcolor={userData.idval}
          />
          <Input
            placeholder={"Distributorship Name"}
            value={userData.name}
            onChange={(e) => {
              const characterValue = e.target.value.replace(/[^a-zA-Z ]/g, "");

              setUserData({
                ...userData,
                name: characterValue,
                namemess: "",
                nameval: false,
              });
            }}
            error={userData.namemess}
            bcolor={userData.nameval}
          />
        </div>
        <div className="auth-input-row">
          <Input
            placeholder={"Dealer Mobile"}
            value={userData.mobile}
            onChange={(e) => {
              const numericValue = e.target.value
                .replace(/[^0-9]/g, "")
                .slice(0, 10);
              setUserData({
                ...userData,
                mobile: numericValue,
                mobilemess: "",
                mobileval: false,
              });
            }}
            error={userData.mobilemess}
            bcolor={userData.mobileval}
          />
          <Input
            placeholder={"Email ID"}
            value={userData.email}
            onChange={(e) =>
              setUserData({
                ...userData,
                email: e.target.value,
                emailmess: "",
                emailval: false,
              })
            }
            error={userData.emailmess}
            bcolor={userData.emailval}
            // onBlur={() => {
            //   if (
            //     !EmailValidation.test(userData.email) &&
            //     userData.email !== ""
            //   ) {
            //     setUserData({
            //       ...userData,
            //       email: userData.email,
            //       emailmess: "Please Enter Valid Email",
            //       emailval: true,
            //     });
            //   } else {
            //     setUserData({
            //       ...userData,
            //       email: userData.email,
            //       emailmess: "",
            //       emailval: false,
            //     });
            //   }
            // }}
          />
        </div>
        <div className="auth-input-row">
          <Input
            placeholder={"GSTIN"}
            value={userData?.gst}
            onChange={(e) => {
              let GSTInputValue = e.target.value?.toUpperCase();
              const validInput = GSTInputValue.replace(/[^a-zA-Z0-9]/g, "");
              setStateFreeze(false);
              if (validInput?.length <= 15) {
                setUserData({
                  ...userData,
                  gst: validInput,
                  gstmess: "",
                  gstval: false,
                  address: "",
                  district: "",
                  pincode: "",
                  state: "",
                });
                if (validInput?.length == 15) {
                  gstcheck(validInput);
                }
              }
            }}
            // onBlur={() => {
            //   if (userData?.gst?.length < 15) {
            //     setUserData({
            //       ...userData,
            //       gstmess: "GST Should be 15 Characters",
            //       gstval: false,
            //       address: "",
            //       district: "",
            //       pincode: "",
            //       state: "",
            //     });
            //   }
            // }}
            error={userData?.gstmess}
            bcolor={userData?.gstval}
          />
          <Dropdown
            disabled={stateFreeze}
            placeholder={"State OR UT"}
            options={[<option value={""}>{"State OR UT"}</option>].concat(
              stateList.map((v, i) => (
                <option key={i} value={v.code}>
                  {v?.code} - {v.name}
                </option>
              ))
            )}
            value={userData.state}
            onChange={(e) =>
              setUserData({
                ...userData,
                state: e.target.value,
                statemess: "",
                stateval: false,
              })
            }
            error={userData.statemess}
            bcolor={userData.stateval}
          />
        </div>
        <div className="auth-input-row">
          <Dropdown
            placeholder={"Firm Type"}
            options={firmList.map((v, i) => (
              <option value={i == 0 ? "" : i} key={i}>
                {v}
              </option>
            ))}
            value={userData.firm}
            onChange={(e) =>
              setUserData({
                ...userData,
                firm: e.target.value,
                firmmess: "",
                firmval: false,
              })
            }
            error={userData.firmmess}
            bcolor={userData.firmval}
          />
          <Dropdown
            placeholder={"Select OMC"}
            options={omcList.map((v, i) => (
              <option value={i == 0 ? "" : i} key={i}>
                {v}
              </option>
            ))}
            value={userData.omc}
            onChange={(e) =>
              setUserData({
                ...userData,
                omc: e.target.value,
                omcmess: "",
                omcval: false,
              })
            }
            error={userData.omcmess}
            bcolor={userData.omcval}
          />
        </div>
        <div className="auth-input-row">
          <div className="flex aic gap10" style={{ width: "100%" }}>
            <div style={{ flex: 1, width: "100%" }}>
              <Dropdown
                placeholder={"Refill Sale Quantity"}
                options={
                  <>
                    <option disabled={true} value="-1">
                      *NOTE: Select the total count of Commercial and Domestic{" "}
                    </option>
                    {refillList.map((v, i) => (
                      <option value={i == 0 ? "" : i} key={i}>
                        {v}
                      </option>
                    ))}
                  </>
                }
                value={userData.refill}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    refill: e.target.value,
                    refillmess: "",
                    refillval: false,
                  })
                }
                error={userData.refillmess}
                bcolor={userData.refillval}
              />
            </div>
            <Tooltip
              content={`${
                "*NOTE: Select the total count of Commercial and Domestic" +
                "\n" +
                "Sales that can be done in this financial year"
              }`}
              direction="top"
            >
              <BsInfoCircle />
            </Tooltip>
          </div>
        </div>

        <div className="auth-input-row">
          <Dropdown
            placeholder={"Annual Turnover of previous financial year"}
            options={
              <>
                <option value={""}>
                  Annual Turnover of previous financial year
                </option>
                <option value={"1"}>{`Annual Turnover < 1.5 CRORES `}</option>
                <option
                  value={"2"}
                >{`Annual Turnover > 1.5 & < 10 CRORES`}</option>
                <option
                  value={"3"}
                >{`Annual Turnover > 10 & < 20 CRORES`}</option>
                <option value={"4"}>{`Annual Turnover > 20 CRORES`}</option>
              </>
            }
            value={userData.turnover}
            onChange={(e) =>
              setUserData({
                ...userData,
                turnover: e.target.value,
                turnovermess: "",
                turnoverval: false,
              })
            }
            error={userData.turnovermess}
            bcolor={userData.turnoverval}
          />

          <Dropdown
            placeholder={"Payroll & ESI Filling"}
            options={payrollList.map((v, i) => (
              <option key={i} value={i == 0 ? "" : i}>
                {v}
              </option>
            ))}
            value={userData.payroll}
            onChange={(e) =>
              setUserData({
                ...userData,
                payroll: e.target.value,
                payrollmess: "",
                payrollval: false,
              })
            }
            error={userData.payrollmess}
            bcolor={userData.payrollval}
          />
        </div>
        <div className="auth-input-row">
          <Input
            placeholder={"Password"}
            type={showPass ? "text" : "password"}
            mess={"[8-12 Characters]"}
            value={userData.pass}
            onChange={(e) => {
              if (e.target.value.length <= 12) {
                setUserData({
                  ...userData,
                  pass: e.target.value,
                  passmess: "",
                  passval: false,
                });
              }
            }}
            onBlur={() => {
              if (!passwordRegex.test(userData.pass) && userData.pass != "") {
                setUserData({
                  ...userData,
                  passmess: "Password must be a combination of @,A,a,1.",
                  passval: true,
                });
              }
            }}
            error={userData.passmess}
            bcolor={userData.passval}
            pass={true}
            setShowPass={setShowPass}
            showPass={showPass}
          />
          <Input
            placeholder={"Confirm Password"}
            type={showPassConfirm ? "text" : "password"}
            mess={"[Same as Password]"}
            value={userData.cpass}
            onChange={(e) => {
              if (e.target.value.length <= 12) {
                setUserData({
                  ...userData,
                  cpass: e.target.value,
                  cpassmess: "",
                  cpassval: false,
                });
              }
            }}
            onBlur={() => {
              if (!passwordRegex.test(userData.cpass) && userData.cpass != "") {
                setUserData({
                  ...userData,
                  cpassmess: "Password must be a combination of @,A,a,1.",
                  cpassval: true,
                });
              }
            }}
            error={userData.cpassmess}
            bcolor={userData.cpassval}
            pass={true}
            setShowPass={setShowPassConfirm}
            showPass={showPassConfirm}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            marginTop: 10,
          }}
        >
          <input
            checked={termAndCondition}
            onClick={() => setTermAndCondition(!termAndCondition)}
            type="checkbox"
            style={{ height: 22, width: 22 }}
          />
          <div
            onClick={() => setOpenModal(true)}
            style={{
              color: "#3A8DFE",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Terms of Services
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            disable={!termAndCondition}
            title={"Register"}
            icon={<BiLogIn size={20} />}
            onClick={onSignUp}
          />
        </div>

        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-start",
            gap: "10px",
            justifyContent: "flex-start",
          }}
          onClick={() => navigate("/login")}
        >
          <FiArrowLeft size={20} color="#000" />
          <div
            style={{
              color: "#000",
              fontSize: "16px",
            }}
          >
            Back to Login
          </div>
        </div>
        {openModal && (
          <SmallModal
            okBtnText={"Close"}
            bgColor={"white"}
            isOpen={openModal}
            setIsOpen={setOpenModal}
            style={{ minWidth: "40rem", maxHeight: "80vh" }}
            okbtn={setOpenModal}
            title={"TERMS OF SERVICE"}
            mess={
              <div className="modal-body">
                <p>
                  Updated on: 01st July 2017.
                  <br />
                  Effective Date: 01st July 2017.
                </p>
                <p>
                  THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU
                  REPRESENT (hereinafter You or Your) AND MyLPGBooks
                  (hereinafter MyLPGBooks) GOVERNING YOUR USE OF MyLPGBooks
                  SOFTWARE PRODUCTS.M
                </p>
                <h3 className="model-h3">Parts of this Agreement</h3>
                <p>
                  This Agreement consists of the following terms and conditions
                  (hereinafter the General Terms) and terms and conditions, if
                  any, specific to use of individual Services (hereinafter the
                  Service Specific Terms). The General Terms and Service
                  Specific Terms are collectively referred to as the Terms. In
                  the event of a conflict between the General Terms and Service
                  Specific Terms, the Service Specific Terms shall prevail.
                </p>

                <h3 className="model-h3">Acceptance of the Terms</h3>
                <p>
                  You must be of legal age to enter into a binding agreement in
                  order to accept the Terms. If you do not agree to the General
                  Terms, do not use any of our Services. If you agree to the
                  General Terms and do not agree to any Service Specific Terms,
                  do not use the corresponding Service. You can accept the Terms
                  by checking a checkbox or clicking on a button indicating your
                  acceptance of the terms or by actually using the Services.
                </p>

                <h3 className="model-h3">Description of Service</h3>
                <p>
                  We provide an array of services for online collaboration and
                  management including custom accounting package, mobile
                  applications and e-commerce ("Service" or "Services"). You may
                  use the Services for your personal and business use or for
                  internal business purpose in the organization that you
                  represent. You may connect to the Services using any Internet
                  browser supported by the Services. You are responsible for
                  obtaining access to the Internet and the equipment necessary
                  to use the Services. You can create and edit content with your
                  user account and if you choose to do so, you can publish and
                  share such content.
                </p>

                <h3 className="model-h3">Subscription to Beta Service</h3>
                <p>
                  We may offer certain Services as closed or open beta services
                  ("Beta Service" or Beta Services) for the purpose of testing
                  and evaluation. You agree that we have the sole authority and
                  discretion to determine the period of time for testing and
                  evaluation of Beta Services. We will be the sole judge of the
                  success of such testing and the decision, if any, to offer the
                  Beta Services as commercial services. You will be under no
                  obligation to acquire a subscription to use any paid Service
                  as a result of your subscription to any Beta Service. We
                  reserve the right to fully or partially discontinue, at any
                  time and from time to time, temporarily or permanently, any of
                  the Beta Services with or without notice to you. You agree
                  that MyLPGBooks will not be liable to you or to any third
                  party for any harm related to, arising out of, or caused by
                  the modification, suspension or discontinuance of any of the
                  Beta Services for any reason.
                </p>

                <h3 className="model-h3">Modification of Terms of Service</h3>
                <p>
                  We may modify the Terms upon notice to you at any time through
                  a service announcement or by sending email to your primary
                  email address. If we make significant changes to the Terms
                  that affect your rights, you will be provided with at least 30
                  days advance notice of the changes by email to your primary
                  email address. You may terminate your use of the Services by
                  providing MyLPGBooks notice by email within 30 days of being
                  notified of the availability of the modified Terms if the
                  Terms are modified in a manner that substantially affects your
                  rights in connection with use of the Services. In the event of
                  such termination, you will be entitled to prorated refund of
                  the unused portion of any prepaid fees. Your continued use of
                  the Service after the effective date of any change to the
                  Terms will be deemed to be your agreement to the modified
                  Terms.
                </p>

                <h3 className="model-h3">User Sign up Obligations</h3>
                <p>
                  You need to sign up for a user account by providing all
                  required information in order to access or use the Services.
                  If you represent an organization and wish to use the Services
                  for corporate internal use, we recommend that you, and all
                  other users from your organization, sign up for user accounts
                  by providing your corporate contact information. In
                  particular, we recommend that you use your corporate email
                  address. You agree to: a provide true, accurate, current and
                  complete information about yourself as prompted by the sign up
                  process; and b maintain and promptly update the information
                  provided during sign up to keep it true, accurate, current,
                  and complete. If you provide any information that is untrue,
                  inaccurate, outdated, or incomplete, or if MyLPGBooks has
                  reasonable grounds to suspect that such information is untrue,
                  inaccurate, outdated, or incomplete, MyLPGBooks may terminate
                  your user account and refuse current or future use of any or
                  all of the Services.
                </p>

                <h3 className="model-h3">
                  Organization Accounts and Administrators
                </h3>
                <p>
                  When you sign up for an account for your organization you may
                  specify one or more administrators. The administrators will
                  have the right to configure the Services based on your
                  requirements and manage end users in your organization
                  account. If your organization account is created and
                  configured on your behalf by a third party, it is likely that
                  such third party has assumed administrator role for your
                  organization. Make sure that you enter into a suitable
                  agreement with such third party specifying such party's roles
                  and restrictions as an administrator of your organization
                  account. You are responsible for i ensuring confidentiality of
                  your organization account password, ii appointing competent
                  individuals as administrators for managing your organization
                  account, and iii ensuring that all activities that occur in
                  connection with your organization account comply with this
                  Agreement. You understand that MyLPGBooks is not responsible
                  for account administration and internal management of the
                  Services for you. You are responsible for taking necessary
                  steps for ensuring that your organization does not lose
                  control of the administrator accounts. You may specify a
                  process to be followed for recovering control in the event of
                  such loss of control of the administrator accounts by sending
                  an email to info@mylpgbooks.com , provided that the process is
                  acceptable to MyLPGBooks. In the absence of any specified
                  administrator account recovery process, MyLPGBooks may provide
                  control of an administrator account to an individual providing
                  proof satisfactory to MyLPGBooks demonstrating authorization
                  to act on behalf of the organization. You agree not to hold
                  MyLPGBooks liable for the consequences of any action taken by
                  MyLPGBooks in good faith in this regard.
                </p>

                <h3 className="model-h3">Personal Information and Privacy</h3>
                <p>
                  Personal information you provide to MyLPGBooks through the
                  Service is governed by MyLPGBooks Privacy Policy. Your
                  election to use the Service indicates your acceptance of the
                  terms of the MyLPGBooks Privacy Policy. You are responsible
                  for maintaining confidentiality of your username, password and
                  other sensitive information. You are responsible for all
                  activities that occur in your user account and you agree to
                  inform us immediately of any unauthorized use of your user
                  account by email to{" "}
                  <a href="mailto:support@mylpgbooks.com">
                    support@mylpgbooks.com
                  </a>
                  or by calling us on any of the numbers listed on Contact Us at{" "}
                  <a href="https://www.mylpgbooks.com/ebs/login">
                    https://www.mylpgbooks.com/ebs/login
                  </a>
                  . We are not responsible for any loss or damage to you or to
                  any third party incurred as a result of any unauthorized
                  access and/or use of your user account, or otherwise.
                </p>

                <h3 className="model-h3">Communications from MyLPGBooks</h3>
                <p>
                  The Service may include certain communications from
                  MyLPGBooks, such as service announcements, administrative
                  messages and newsletters. You understand that these
                  communications shall be considered part of using the Services.
                  As part of our policy to provide you total privacy, we also
                  provide you the option of opting out from receiving
                  newsletters from us. However, you will not be able to opt-out
                  from receiving service announcements and administrative
                  messages.
                </p>

                <h3 className="model-h3">Complaints</h3>
                <p>
                  If we receive a complaint from any person against you with
                  respect to your activities as part of use of the Services, we
                  will forward the complaint to the primary email address of
                  your user account. You must respond to the complainant
                  directly within 10 days of receiving the complaint forwarded
                  by us and copy MyLPGBooks in the communication. If you do not
                  respond to the complainant within 10 days from the date of our
                  email to you, we may disclose your name and contact
                  information to the complainant for enabling the complainant to
                  take legal action against you. You understand that your
                  failure to respond to the forwarded complaint within the 10
                  days' time limit will be construed as your consent to
                  disclosure of your name and contact information by MyLPGBooks
                  to the complainant.
                </p>

                <h3 className="model-h3">Fees and Payments</h3>
                <p>
                  The Services are available under subscription plans of various
                  durations. Payments for subscription plans of duration of less
                  than a year can be made only by IMPS/NEFT. Your subscription
                  will be automatically renewed at the end of each subscription
                  period unless you downgrade your paid subscription plan to a
                  free plan or inform us that you do not wish to renew the
                  subscription. At the time of automatic renewal, the
                  subscription fee will be charged to the Credit Card last used
                  by you. We provide you the option of changing the details if
                  you would like the payment for the renewal to be made through
                  a different Credit Card. If you do not wish to renew the
                  subscription, you must inform us at least seven days prior to
                  the renewal date. If you have not downgraded to a free plan
                  and if you have not informed us that you do not wish to renew
                  the subscription, you will be presumed to have authorized
                  MyLPGBooks to charge the subscription fee to the Credit Card
                  last used by you. Please click here to know about our Refund
                  Policy. From time to time, we may change the price of any
                  Service or charge for use of Services that are currently
                  available free of charge. Any increase in charges will not
                  apply until the expiry of your then current billing cycle. You
                  will not be charged for using any Service unless you have
                  opted for a paid subscription plan.
                </p>

                <h3 className="model-h3">Restrictions on Use</h3>
                <p>
                  In addition to all other terms and conditions of this
                  Agreement, you shall not: (i) transfer the Services or
                  otherwise make it available to any third party; (ii) provide
                  any service based on the Services without prior written
                  permission; (iii) use the third party links to sites without
                  agreeing to their website terms &amp; conditions; (iv) post
                  links to third party sites or use their logo, company name,
                  etc. without their prior written permission; (v) publish any
                  personal or confidential information belonging to any person
                  or entity without obtaining consent from such person or
                  entity; (vi) use the Services in any manner that could damage,
                  disable, overburden, impair or harm any server, network,
                  computer system, resource of MyLPGBooks; (vii) violate any
                  applicable local, state, national or international law; and
                  (viii) create a false identity to mislead any person as to the
                  identity or origin of any communication.
                </p>

                <h3 className="model-h3">Spamming and Illegal Activities</h3>
                <p>
                  You agree to be solely responsible for the contents of your
                  transmissions through the Services. You agree not to use the
                  Services for illegal purposes or for the transmission of
                  material that is unlawful, defamatory, harassing, libelous,
                  invasive of another's privacy, abusive, threatening, harmful,
                  vulgar, pornographic, obscene, or is otherwise objectionable,
                  offends religious sentiments, promotes racism, contains
                  viruses or malicious code, or that which infringes or may
                  infringe intellectual property or other rights of another. You
                  agree not to use the Services for the transmission of "junk
                  mail", "spam", "chain letters", phishing or unsolicited mass
                  distribution of email. We reserve the right to terminate your
                  access to the Services if there are reasonable grounds to
                  believe that you have used the Services for any illegal or
                  unauthorized activity.
                </p>

                <h3 className="model-h3">Inactive User Accounts Policy</h3>
                <p>
                  We reserve the right to terminate unpaid user accounts that
                  are inactive for a continuous period of 120 days. In the event
                  of such termination, all data associated with such user
                  account will be deleted. We will provide you prior notice of
                  such termination and option to back-up your data. The data
                  deletion policy may be implemented with respect to any or all
                  of the Services. Each Service will be considered an
                  independent and separate service for the purpose of
                  calculating the period of inactivity. In other words, activity
                  in one of the Services is not sufficient to keep your user
                  account in another Service active. In case of accounts with
                  more than one user, if at least one of the users is active,
                  the account will not be considered inactive.
                </p>

                <h3 className="model-h3">Data Ownership</h3>
                <p>
                  We respect your right to ownership of content created or
                  stored by you. You own the content created or stored by you.
                  Unless specifically permitted by you, your use of the Services
                  does not grant MyLPGBooks the license to use, reproduce,
                  adapt, modify, publish or distribute the content created by
                  you or stored in your user account for MyLPGBooks's
                  commercial, marketing or any similar purpose. But you grant
                  MyLPGBooks permission to access, copy, distribute, store,
                  transmit, reformat, publicly display and publicly perform the
                  content of your user account solely as required for the
                  purpose of providing the Services to you.
                </p>

                <h3 className="model-h3">User Generated Content</h3>
                <p>
                  You may transmit or publish content created by you using any
                  of the Services or otherwise. However, you shall be solely
                  responsible for such content and the consequences of its
                  transmission or publication. Any content made public will be
                  publicly accessible through the internet and may be crawled
                  and indexed by search engines. You are responsible for
                  ensuring that you do not accidentally make any private content
                  publicly available. Any content that you may receive from
                  other users of the Services, is provided to you AS IS for your
                  information and personal use only and you agree not to use,
                  copy, reproduce, distribute, transmit, broadcast, display,
                  sell, license or otherwise exploit such content for any
                  purpose, without the express written consent of the person who
                  owns the rights to such content. In the course of using any of
                  the Services, if you come across any content with copyright
                  notice(s) or any copy protection feature(s), you agree not to
                  remove such copyright notice(s) or disable such copy
                  protection feature(s) as the case may be. By making any
                  copyrighted/copyrightable content available on any of the
                  Services you affirm that you have the consent, authorization
                  or permission, as the case may be from every person who may
                  claim any rights in such content to make such content
                  available in such manner. Further, by making any content
                  available in the manner aforementioned, you expressly agree
                  that MyLPGBooks will have the right to block access to or
                  remove such content made available by you if MyLPGBooks
                  receives complaints concerning any illegality or infringement
                  of third party rights in such content. By using any of the
                  Services and transmitting or publishing any content using such
                  Service, you expressly consent to determination of questions
                  of illegality or infringement of third party rights in such
                  content by the agent designated by MyLPGBooks for this
                  purpose. For procedure relating to complaints of illegality or
                  infringement of third party rights in content transmitted or
                  published using the Services, click here. If you wish to
                  protest any blocking or removal of content by MyLPGBooks, you
                  may do so in the manner provided here.
                </p>

                <h3 className="model-h3">Sample files and Applications</h3>
                <p>
                  MyLPGBooks may provide sample files and applications for the
                  purpose of demonstrating the possibility of using the Services
                  effectively for specific purposes. The information contained
                  in any such sample files and applications consists of random
                  data. MyLPGBooks makes no warranty, either express or implied,
                  as to the accuracy, usefulness, completeness or reliability of
                  the information or the sample files and applications.
                </p>

                <h3 className="model-h3">Trademark</h3>
                <p>
                  MyLPGBooks Apps, MyLPGBooks, MyLPGBooks logo, the names of
                  individual Services and their logos are trademarks of
                  MyLPGBooks . You agree not to display or use, in any manner,
                  the MyLPGBooks trademarks, without MyLPGBooks's prior
                  permission.
                </p>

                <h3 className="model-h3">Disclaimer of Warranties</h3>
                <p>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE
                  SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
                  AS-IS-AND-AS-AVAILABLE BASIS. MyLPGBooks EXPRESSLY DISCLAIMS
                  ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                  MyLPGBooks MAKES NO WARRANTY THAT THE SERVICES WILL BE
                  UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. USE OF ANY
                  MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
                  SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL
                  BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM,
                  MOBILE TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM
                  THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL.
                  NO ADVICE OR INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY
                  YOU FROM MyLPGBooks, ITS EMPLOYEES OR REPRESENTATIVES SHALL
                  CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
                </p>

                <h3 className="model-h3">Limitation of Liability</h3>
                <p>
                  YOU AGREE THAT MyLPGBooks SHALL, IN NO EVENT, BE LIABLE FOR
                  ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR
                  OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS
                  PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF
                  BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED
                  BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF
                  MyLPGBooks HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
                  IN NO EVENT SHALL MyLPGBooks'S ENTIRE LIABILITY TO YOU IN
                  RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE
                  FEES PAID BY YOU TOWARDS SUCH SERVICE.
                </p>

                <h3 className="model-h3">Indemnification</h3>
                <p>
                  You agree to indemnify and hold harmless MyLPGBooks, its
                  officers, directors, employees, suppliers, and affiliates,
                  from and against any losses, damages, fines and expenses
                  (including attorney's fees and costs) arising out of or
                  relating to any claims that you have used the Services in
                  violation of another party's rights, in violation of any law,
                  in violations of any provisions of the Terms, or any other
                  claim related to your use of the Services, except where such
                  use is authorized by MyLPGBooks.
                </p>

                <h3 className="model-h3">Arbitration</h3>
                <p>
                  Any controversy or claim arising out of or relating to the
                  Terms shall be settled by binding arbitration in accordance
                  with the commercial arbitration rules of the American
                  Arbitration Association. Any such controversy or claim shall
                  be arbitrated on an individual basis, and shall not be
                  consolidated in any arbitration with any claim or controversy
                  of any other party. The decision of the arbitrator shall be
                  final and un-appealable. The arbitration shall be conducted in
                  California and judgment on the arbitration award may be
                  entered into any court having jurisdiction thereof.
                  Notwithstanding anything to the contrary, MyLPGBooks may at
                  any time seek injunctions or other forms of equitable relief
                  from any court of competent jurisdiction.
                </p>

                <h3 className="model-h3">Suspension and Termination</h3>
                <p>
                  We may suspend your user account or temporarily disable access
                  to whole or part of any Service in the event of any suspected
                  illegal activity, extended periods of inactivity or requests
                  by law enforcement or other government agencies. Objections to
                  suspension or disabling of user accounts should be made to{" "}
                  <a href="mailto:support@mylpgbooks.com">
                    support@mylpgbooks.com
                  </a>
                  within thirty days of being notified about the suspension. We
                  may terminate a suspended or disabled user account after
                  thirty days. We will also terminate your user account on your
                  request. In addition, we reserve the right to terminate your
                  user account and deny the Services upon reasonable belief that
                  you have violated the Terms and to terminate your access to
                  any Beta Service in case of unexpected technical issues or
                  discontinuation of the Beta Service. You have the right to
                  terminate your user account if MyLPGBooks breaches its
                  obligations under these Terms and in such event, you will be
                  entitled to prorated refund of any prepaid fees. Termination
                  of user account will include denial of access to all Services,
                  deletion of information in your user account such as your
                  email address and password and deletion of all data in your
                  user account.
                </p>
                <h3 className="model-h3">END OF TERMS OF SERVICE</h3>
                <p>
                  If you have any questions or concerns regarding this
                  Agreement, please contact us at{" "}
                  <a href="mailto:support@mylpgbooks.com">
                    support@mylpgbooks.com
                  </a>
                  .
                </p>
              </div>
            }
          />
        )}
      </div>
    </AuthBg>
  );
};

export default Signup;

<div className="">
  <p>
    Updated on: 01st July 2017.
    <br />
    Effective Date: 01st July 2017.
  </p>
  <p>
    THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
    (hereinafter You or Your) AND MyLPGBooks (hereinafter MyLPGBooks) GOVERNING
    YOUR USE OF MyLPGBooks SOFTWARE PRODUCTS.M
  </p>
  <h3 className="h3">Parts of this Agreement</h3>
  <p>
    This Agreement consists of the following terms and conditions (hereinafter
    the General Terms) and terms and conditions, if any, specific to use of
    individual Services (hereinafter the Service Specific Terms). The General
    Terms and Service Specific Terms are collectively referred to as the Terms.
    In the event of a conflict between the General Terms and Service Specific
    Terms, the Service Specific Terms shall prevail.
  </p>

  <h3 className="h3">Acceptance of the Terms</h3>
  <p>
    You must be of legal age to enter into a binding agreement in order to
    accept the Terms. If you do not agree to the General Terms, do not use any
    of our Services. If you agree to the General Terms and do not agree to any
    Service Specific Terms, do not use the corresponding Service. You can accept
    the Terms by checking a checkbox or clicking on a button indicating your
    acceptance of the terms or by actually using the Services.
  </p>

  <h3 className="h3">Description of Service</h3>
  <p>
    We provide an array of services for online collaboration and management
    including custom accounting package, mobile applications and e-commerce
    ("Service" or "Services"). You may use the Services for your personal and
    business use or for internal business purpose in the organization that you
    represent. You may connect to the Services using any Internet browser
    supported by the Services. You are responsible for obtaining access to the
    Internet and the equipment necessary to use the Services. You can create and
    edit content with your user account and if you choose to do so, you can
    publish and share such content.
  </p>

  <h3 className="h3">Subscription to Beta Service</h3>
  <p>
    We may offer certain Services as closed or open beta services ("Beta
    Service" or Beta Services) for the purpose of testing and evaluation. You
    agree that we have the sole authority and discretion to determine the period
    of time for testing and evaluation of Beta Services. We will be the sole
    judge of the success of such testing and the decision, if any, to offer the
    Beta Services as commercial services. You will be under no obligation to
    acquire a subscription to use any paid Service as a result of your
    subscription to any Beta Service. We reserve the right to fully or partially
    discontinue, at any time and from time to time, temporarily or permanently,
    any of the Beta Services with or without notice to you. You agree that
    MyLPGBooks will not be liable to you or to any third party for any harm
    related to, arising out of, or caused by the modification, suspension or
    discontinuance of any of the Beta Services for any reason.
  </p>

  <h3 className="h3">Modification of Terms of Service</h3>
  <p>
    We may modify the Terms upon notice to you at any time through a service
    announcement or by sending email to your primary email address. If we make
    significant changes to the Terms that affect your rights, you will be
    provided with at least 30 days advance notice of the changes by email to
    your primary email address. You may terminate your use of the Services by
    providing MyLPGBooks notice by email within 30 days of being notified of the
    availability of the modified Terms if the Terms are modified in a manner
    that substantially affects your rights in connection with use of the
    Services. In the event of such termination, you will be entitled to prorated
    refund of the unused portion of any prepaid fees. Your continued use of the
    Service after the effective date of any change to the Terms will be deemed
    to be your agreement to the modified Terms.
  </p>

  <h3 className="h3">User Sign up Obligations</h3>
  <p>
    You need to sign up for a user account by providing all required information
    in order to access or use the Services. If you represent an organization and
    wish to use the Services for corporate internal use, we recommend that you,
    and all other users from your organization, sign up for user accounts by
    providing your corporate contact information. In particular, we recommend
    that you use your corporate email address. You agree to: a provide true,
    accurate, current and complete information about yourself as prompted by the
    sign up process; and b maintain and promptly update the information provided
    during sign up to keep it true, accurate, current, and complete. If you
    provide any information that is untrue, inaccurate, outdated, or incomplete,
    or if MyLPGBooks has reasonable grounds to suspect that such information is
    untrue, inaccurate, outdated, or incomplete, MyLPGBooks may terminate your
    user account and refuse current or future use of any or all of the Services.
  </p>

  <h3 className="h3">Organization Accounts and Administrators</h3>
  <p>
    When you sign up for an account for your organization you may specify one or
    more administrators. The administrators will have the right to configure the
    Services based on your requirements and manage end users in your
    organization account. If your organization account is created and configured
    on your behalf by a third party, it is likely that such third party has
    assumed administrator role for your organization. Make sure that you enter
    into a suitable agreement with such third party specifying such party's
    roles and restrictions as an administrator of your organization account. You
    are responsible for i ensuring confidentiality of your organization account
    password, ii appointing competent individuals as administrators for managing
    your organization account, and iii ensuring that all activities that occur
    in connection with your organization account comply with this Agreement. You
    understand that MyLPGBooks is not responsible for account administration and
    internal management of the Services for you. You are responsible for taking
    necessary steps for ensuring that your organization does not lose control of
    the administrator accounts. You may specify a process to be followed for
    recovering control in the event of such loss of control of the administrator
    accounts by sending an email to info@mylpgbooks.com , provided that the
    process is acceptable to MyLPGBooks. In the absence of any specified
    administrator account recovery process, MyLPGBooks may provide control of an
    administrator account to an individual providing proof satisfactory to
    MyLPGBooks demonstrating authorization to act on behalf of the organization.
    You agree not to hold MyLPGBooks liable for the consequences of any action
    taken by MyLPGBooks in good faith in this regard.
  </p>

  <h3 className="h3">Personal Information and Privacy</h3>
  <p>
    Personal information you provide to MyLPGBooks through the Service is
    governed by MyLPGBooks Privacy Policy. Your election to use the Service
    indicates your acceptance of the terms of the MyLPGBooks Privacy Policy. You
    are responsible for maintaining confidentiality of your username, password
    and other sensitive information. You are responsible for all activities that
    occur in your user account and you agree to inform us immediately of any
    unauthorized use of your user account by email to{" "}
    <a href="mailto:support@mylpgbooks.com">support@mylpgbooks.com</a>
    or by calling us on any of the numbers listed on Contact Us at{" "}
    <a href="https://www.mylpgbooks.com/ebs/login">
      https://www.mylpgbooks.com/ebs/login
    </a>
    . We are not responsible for any loss or damage to you or to any third party
    incurred as a result of any unauthorized access and/or use of your user
    account, or otherwise.
  </p>

  <h3 className="h3">Communications from MyLPGBooks</h3>
  <p>
    The Service may include certain communications from MyLPGBooks, such as
    service announcements, administrative messages and newsletters. You
    understand that these communications shall be considered part of using the
    Services. As part of our policy to provide you total privacy, we also
    provide you the option of opting out from receiving newsletters from us.
    However, you will not be able to opt-out from receiving service
    announcements and administrative messages.
  </p>

  <h3 className="h3">Complaints</h3>
  <p>
    If we receive a complaint from any person against you with respect to your
    activities as part of use of the Services, we will forward the complaint to
    the primary email address of your user account. You must respond to the
    complainant directly within 10 days of receiving the complaint forwarded by
    us and copy MyLPGBooks in the communication. If you do not respond to the
    complainant within 10 days from the date of our email to you, we may
    disclose your name and contact information to the complainant for enabling
    the complainant to take legal action against you. You understand that your
    failure to respond to the forwarded complaint within the 10 days' time limit
    will be construed as your consent to disclosure of your name and contact
    information by MyLPGBooks to the complainant.
  </p>

  <h3 className="h3">Fees and Payments</h3>
  <p>
    The Services are available under subscription plans of various durations.
    Payments for subscription plans of duration of less than a year can be made
    only by IMPS/NEFT. Your subscription will be automatically renewed at the
    end of each subscription period unless you downgrade your paid subscription
    plan to a free plan or inform us that you do not wish to renew the
    subscription. At the time of automatic renewal, the subscription fee will be
    charged to the Credit Card last used by you. We provide you the option of
    changing the details if you would like the payment for the renewal to be
    made through a different Credit Card. If you do not wish to renew the
    subscription, you must inform us at least seven days prior to the renewal
    date. If you have not downgraded to a free plan and if you have not informed
    us that you do not wish to renew the subscription, you will be presumed to
    have authorized MyLPGBooks to charge the subscription fee to the Credit Card
    last used by you. Please click here to know about our Refund Policy. From
    time to time, we may change the price of any Service or charge for use of
    Services that are currently available free of charge. Any increase in
    charges will not apply until the expiry of your then current billing cycle.
    You will not be charged for using any Service unless you have opted for a
    paid subscription plan.
  </p>

  <h3 className="h3">Restrictions on Use</h3>
  <p>
    In addition to all other terms and conditions of this Agreement, you shall
    not: (i) transfer the Services or otherwise make it available to any third
    party; (ii) provide any service based on the Services without prior written
    permission; (iii) use the third party links to sites without agreeing to
    their website terms &amp; conditions; (iv) post links to third party sites
    or use their logo, company name, etc. without their prior written
    permission; (v) publish any personal or confidential information belonging
    to any person or entity without obtaining consent from such person or
    entity; (vi) use the Services in any manner that could damage, disable,
    overburden, impair or harm any server, network, computer system, resource of
    MyLPGBooks; (vii) violate any applicable local, state, national or
    international law; and (viii) create a false identity to mislead any person
    as to the identity or origin of any communication.
  </p>

  <h3 className="h3">Spamming and Illegal Activities</h3>
  <p>
    You agree to be solely responsible for the contents of your transmissions
    through the Services. You agree not to use the Services for illegal purposes
    or for the transmission of material that is unlawful, defamatory, harassing,
    libelous, invasive of another's privacy, abusive, threatening, harmful,
    vulgar, pornographic, obscene, or is otherwise objectionable, offends
    religious sentiments, promotes racism, contains viruses or malicious code,
    or that which infringes or may infringe intellectual property or other
    rights of another. You agree not to use the Services for the transmission of
    "junk mail", "spam", "chain letters", phishing or unsolicited mass
    distribution of email. We reserve the right to terminate your access to the
    Services if there are reasonable grounds to believe that you have used the
    Services for any illegal or unauthorized activity.
  </p>

  <h3 className="h3">Inactive User Accounts Policy</h3>
  <p>
    We reserve the right to terminate unpaid user accounts that are inactive for
    a continuous period of 120 days. In the event of such termination, all data
    associated with such user account will be deleted. We will provide you prior
    notice of such termination and option to back-up your data. The data
    deletion policy may be implemented with respect to any or all of the
    Services. Each Service will be considered an independent and separate
    service for the purpose of calculating the period of inactivity. In other
    words, activity in one of the Services is not sufficient to keep your user
    account in another Service active. In case of accounts with more than one
    user, if at least one of the users is active, the account will not be
    considered inactive.
  </p>

  <h3 className="h3">Data Ownership</h3>
  <p>
    We respect your right to ownership of content created or stored by you. You
    own the content created or stored by you. Unless specifically permitted by
    you, your use of the Services does not grant MyLPGBooks the license to use,
    reproduce, adapt, modify, publish or distribute the content created by you
    or stored in your user account for MyLPGBooks's commercial, marketing or any
    similar purpose. But you grant MyLPGBooks permission to access, copy,
    distribute, store, transmit, reformat, publicly display and publicly perform
    the content of your user account solely as required for the purpose of
    providing the Services to you.
  </p>

  <h3 className="h3">User Generated Content</h3>
  <p>
    You may transmit or publish content created by you using any of the Services
    or otherwise. However, you shall be solely responsible for such content and
    the consequences of its transmission or publication. Any content made public
    will be publicly accessible through the internet and may be crawled and
    indexed by search engines. You are responsible for ensuring that you do not
    accidentally make any private content publicly available. Any content that
    you may receive from other users of the Services, is provided to you AS IS
    for your information and personal use only and you agree not to use, copy,
    reproduce, distribute, transmit, broadcast, display, sell, license or
    otherwise exploit such content for any purpose, without the express written
    consent of the person who owns the rights to such content. In the course of
    using any of the Services, if you come across any content with copyright
    notice(s) or any copy protection feature(s), you agree not to remove such
    copyright notice(s) or disable such copy protection feature(s) as the case
    may be. By making any copyrighted/copyrightable content available on any of
    the Services you affirm that you have the consent, authorization or
    permission, as the case may be from every person who may claim any rights in
    such content to make such content available in such manner. Further, by
    making any content available in the manner aforementioned, you expressly
    agree that MyLPGBooks will have the right to block access to or remove such
    content made available by you if MyLPGBooks receives complaints concerning
    any illegality or infringement of third party rights in such content. By
    using any of the Services and transmitting or publishing any content using
    such Service, you expressly consent to determination of questions of
    illegality or infringement of third party rights in such content by the
    agent designated by MyLPGBooks for this purpose. For procedure relating to
    complaints of illegality or infringement of third party rights in content
    transmitted or published using the Services, click here. If you wish to
    protest any blocking or removal of content by MyLPGBooks, you may do so in
    the manner provided here.
  </p>

  <h3 className="h3">Sample files and Applications</h3>
  <p>
    MyLPGBooks may provide sample files and applications for the purpose of
    demonstrating the possibility of using the Services effectively for specific
    purposes. The information contained in any such sample files and
    applications consists of random data. MyLPGBooks makes no warranty, either
    express or implied, as to the accuracy, usefulness, completeness or
    reliability of the information or the sample files and applications.
  </p>

  <h3 className="h3">Trademark</h3>
  <p>
    MyLPGBooks Apps, MyLPGBooks, MyLPGBooks logo, the names of individual
    Services and their logos are trademarks of MyLPGBooks . You agree not to
    display or use, in any manner, the MyLPGBooks trademarks, without
    MyLPGBooks's prior permission.
  </p>

  <h3 className="h3">Disclaimer of Warranties</h3>
  <p>
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR
    SOLE RISK. THE SERVICES ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS.
    MyLPGBooks EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
    OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
    MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MyLPGBooks MAKES NO
    WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR
    FREE. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
    SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
    RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE TELEPHONE,
    WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE
    DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER WRITTEN OR
    ORAL, OBTAINED BY YOU FROM MyLPGBooks, ITS EMPLOYEES OR REPRESENTATIVES
    SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
  </p>

  <h3 className="h3">Limitation of Liability</h3>
  <p>
    YOU AGREE THAT MyLPGBooks SHALL, IN NO EVENT, BE LIABLE FOR ANY
    CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR
    DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION,
    COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF
    OR CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF MyLPGBooks
    HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL
    MyLPGBooks'S ENTIRE LIABILITY TO YOU IN RESPECT OF ANY SERVICE, WHETHER
    DIRECT OR INDIRECT, EXCEED THE FEES PAID BY YOU TOWARDS SUCH SERVICE.
  </p>

  <h3 className="h3">Indemnification</h3>
  <p>
    You agree to indemnify and hold harmless MyLPGBooks, its officers,
    directors, employees, suppliers, and affiliates, from and against any
    losses, damages, fines and expenses (including attorney's fees and costs)
    arising out of or relating to any claims that you have used the Services in
    violation of another party's rights, in violation of any law, in violations
    of any provisions of the Terms, or any other claim related to your use of
    the Services, except where such use is authorized by MyLPGBooks.
  </p>

  <h3 className="h3">Arbitration</h3>
  <p>
    Any controversy or claim arising out of or relating to the Terms shall be
    settled by binding arbitration in accordance with the commercial arbitration
    rules of the American Arbitration Association. Any such controversy or claim
    shall be arbitrated on an individual basis, and shall not be consolidated in
    any arbitration with any claim or controversy of any other party. The
    decision of the arbitrator shall be final and un-appealable. The arbitration
    shall be conducted in California and judgment on the arbitration award may
    be entered into any court having jurisdiction thereof. Notwithstanding
    anything to the contrary, MyLPGBooks may at any time seek injunctions or
    other forms of equitable relief from any court of competent jurisdiction.
  </p>

  <h3 className="h3">Suspension and Termination</h3>
  <p>
    We may suspend your user account or temporarily disable access to whole or
    part of any Service in the event of any suspected illegal activity, extended
    periods of inactivity or requests by law enforcement or other government
    agencies. Objections to suspension or disabling of user accounts should be
    made to <a href="mailto:support@mylpgbooks.com">support@mylpgbooks.com</a>
    within thirty days of being notified about the suspension. We may terminate
    a suspended or disabled user account after thirty days. We will also
    terminate your user account on your request. In addition, we reserve the
    right to terminate your user account and deny the Services upon reasonable
    belief that you have violated the Terms and to terminate your access to any
    Beta Service in case of unexpected technical issues or discontinuation of
    the Beta Service. You have the right to terminate your user account if
    MyLPGBooks breaches its obligations under these Terms and in such event, you
    will be entitled to prorated refund of any prepaid fees. Termination of user
    account will include denial of access to all Services, deletion of
    information in your user account such as your email address and password and
    deletion of all data in your user account.
  </p>
  <h3 className="h3">END OF TERMS OF SERVICE</h3>
  <p>
    If you have any questions or concerns regarding this Agreement, please
    contact us at{" "}
    <a href="mailto:support@mylpgbooks.com">support@mylpgbooks.com</a>.
  </p>
</div>;
