import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { RadioTurnNP } from "../../utils/utils";
import { profile_update_api } from "../../utils/apis";
import store from "../../redux/store";
import { get_profile_data } from "./UsersSlice";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { get_complete_list } from "../../redux/slice/completeListSlice";

const Uinfo = () => {
  const [check, setCheck] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const { profile_data } = useSelector((state) => state.profile);
  const { user_id } = useSelector((state) => state.other);

  useEffect(() => {
    update_profile_data();
  }, [user_id]);
  const update_profile_data = () => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
    }
  };

  useEffect(() => {
    // store.dispatch(get_complete_list());
  }, [profile_data]);

  useEffect(() => {
    setCheck(profile_data?.turnover);
  }, [profile_data]);

  useEffect(() => {
    if (profile_data?.turnover != check && check != null) {
      fetchData();
    }
  }, [check]);


  const fetchData = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "turnoverupdate");
      body.append("agencyid", user_id);
      body.append("turnover", check);
      const responce = await profile_update_api(body);
      setShowLoader(false);
      if (responce.success) {
        toast.success(responce.message);
        // store.dispatch(get_profile_data(user_id));
        update_profile_data();
      } else if (!responce.success) {
        toast.error(responce.message);
        setCheck(profile_data?.turnover);
      }
    } catch (error) {
      setCheck(profile_data?.turnover);
      console.log(error);
    }
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.card_heading}>User Settings</div>
      <div className={styles.p_card}>
        <div className={styles.u_title}>Previous FY Turnover</div>
        <div className={styles.u_radio_wrap}>
          <RadioTurnNP
            value={1}
            title={"< 1.5 Crore "}
            ShowValue={check}
            setShowValue={setCheck}
          />
          <RadioTurnNP
            ShowValue={check}
            setShowValue={setCheck}
            value={2}
            title={"> 1.5 Crore but < 10 Crore "}
          />
          <RadioTurnNP
            ShowValue={check}
            setShowValue={setCheck}
            value={3}
            title={"> 10 Crore but < 20 Crore "}
          />
          <RadioTurnNP
            ShowValue={check}
            setShowValue={setCheck}
            value={4}
            title={"> 20 Crore "}
          />
        </div>
      </div>
      {showLoader && <Loader />}
    </div>
  );
};

const RadioTxt = ({ check, setCheck, txt }) => {
  const trabsition = {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01],
    scale: {
      type: "spring",
      damping: 5,
      stiffness: 100,
      restDelta: 0.001,
    },
  };
  return (
    <div className={styles.p_radio_main} onClick={() => setCheck(!check)}>
      {check ? (
        <AnimatePresence>
          <motion.div
            className={styles.p_radio_btn}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            whileTap={{ opacity: 0, scale: 0.5 }}
            transition={trabsition}
          />
        </AnimatePresence>
      ) : (
        <AnimatePresence mode="popLayout">
          <motion.div
            className={styles.p_radio_active_btn}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            whileTap={{ opacity: 0, scale: 0.5 }}
            transition={trabsition}
          >
            <div className={styles.p_radio_active_btn_inner} />
          </motion.div>
        </AnimatePresence>
      )}
      <div className={styles.p_radio_txt}>{txt}</div>
    </div>
  );
};

export default Uinfo;
