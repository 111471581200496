import React, { useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  BackButton,
  Button,
  HelpButton,
} from "../../../components/btn/Buttons";
import { useSelector } from "react-redux";
import Dropdown from "../../../components/inputs/Dropdown";
import useLastFourFinancialYears from "../../../utils/Hook/financialYearHook";
import { TDS_TCS_API } from "../../../utils/apis";
import { Alerts } from "../../../components/Alerts/Alerts";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import Table from "../../../components/table/Table";
import NoData from "../../../components/NoData";
import { downloadExcel, exportPDF } from "../../../utils/utils";
import moment from "moment";

const TDS = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [showData, setShowData] = useState(false);
  const { user_id, today } = useSelector((state) => state.other);
  const [showLoader, setShowLoader] = useState(false);
  const [tds_tcs_item_list, setTds_tcs_item_list] = useState([]);
  console.log("tds_tcs_item_list ===", tds_tcs_item_list);
  const options = useLastFourFinancialYears(4);
  const { firm_name } = useSelector((state) => state.profile);

  const handleInputChange = (key) => (event) => {
    if (event.target.value.length <= 10) {
      setAddData({
        ...addData,
        [key]: event.target.value,
      });
    }
  };

  const table_header = [
    "PAN Number",
    "Date",
    "Party Name",
    "Total Value (Taxable Value)",
    "Under Section",
    "Rate(Percentage)",
    "TDS value",
  ];
  const table_data = (addData) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.PAN,
        moment(v?.Date).format('DD-MMM-YYYY'),
        v?.Name,
        v?.TotalValue,
        v?.UNDER_SECTION ,
        v?.TDS_PERCENT,
        v?.TDS_Value,
      ]),
    ];

    return data;
  };

  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - TDS REPORTS -- ${addData.month}-${addData?.year}`,
      tableName: `${firm_name} - TDS REPORTS --  ${addData.month}-${addData?.year}`,
    });
  };

  const export_excel = (addData, data) => {
    downloadExcel(
      table_data(data),
      ["S.No.", ...table_header],
      `${firm_name} - TDS REPORTS --  ${addData.month}-${addData?.year}`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "list");
        body.append("agencyid", user_id);
        body.append("year", addData?.year);
        body.append("month", addData?.month);
        body.append("type", "TDS");
        const res = await TDS_TCS_API(body);
        console.log("res ====", res);
        setShowLoader(false);
        if (res.success) {
          if (data1 == "excel") {
            export_excel(addData, res?.data);
          } else if (data1 == "pdf") {
            export_pdf(addData, res?.data);
          } else {
            setTds_tcs_item_list(res?.data);
            setShowData(true);
            setAddDataPre(addData);
          }
        } else if (!res.success) {
          setMess(res?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];

    if (!addData?.year || (addData?.year && addData?.year?.length == 0)) {
      data.push("Please Select Year");
    }
    if (!addData?.month || (addData?.month && addData?.month?.length == 0)) {
      data.push("Please Select Year");
    }
    if (addData?.month && addData?.year) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      if (currentYear == addData.year) {
        if (addData.month > currentMonth) {
          data.push("You are not able to select future months");
        }
      }
    }
    return data;
  };

  return (
    <div className="container">
      <PageHeading
        title={"TDS"}
        right={
          <div className="ReportMenu-btn">
            <div>
              <BackButton />
            </div>
            <div>
              <HelpButton
                link={[
                  "https://youtu.be/oEzSuwSIkRg",
                  "https://youtu.be/rrX-pqA7h68",
                ]}
              />
            </div>
          </div>
        }
      />

      <div className="tb1">
        <div className="GSTR1Mian">
          <div className="DR">
            <Dropdown
              label={"MONTH"}
              value={addData?.month}
              onChange={handleInputChange("month")}
              options={
                <>
                  <option>SELECT</option>
                  <option value={"1"}>JAN</option>
                  <option value={"2"}>FEB</option>
                  <option value={"3"}>MAR</option>
                  <option value={"4"}>APR</option>
                  <option value={"5"}>MAY</option>
                  <option value={"6"}>JUN</option>
                  <option value={"7"}>JUL</option>
                  <option value={"8"}>AUG</option>
                  <option value={"9"}>SEP</option>
                  <option value={"10"}>OCT</option>
                  <option value={"11"}>NOV</option>
                  <option value={"12"}>DEC</option>
                </>
              }
            />
          </div>

          <div className="DR">
            <Dropdown
              label={"YEAR"}
              value={addData?.year}
              onChange={handleInputChange("year")}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {options}
                </>
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginTop: "30px",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Button
              title={" GENERATE TDS"}
              onClick={() => get_list(addData)}
              className="GST-btn"
            />
          </div>
        </div>
      </div>

      {showData && tds_tcs_item_list?.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="SFromDate" style={{ alignItems: "center" }}>
              <span style={{ fontSize: "11PX" }}>Month :</span>
              <p className="FDATE">{addDataPre?.month}</p>
              <span style={{ fontSize: "11PX" }}>Year :</span>
              <p className="FDATE">{addDataPre.year}</p>
            </div>
            <div
              className="epss-btn"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <button
                style={{
                  padding: "0.5em 1em",
                  fontSize: ".88em",
                  backgroundColor: "#e9e9e9",
                }}
                onClick={() => get_list(addDataPre,"excel")}
              >
                Excel
              </button>
              <button
                style={{
                  padding: "0.5em 1em",
                  fontSize: ".88em",
                  backgroundColor: "#e9e9e9",
                }}
                onClick={() => get_list(addDataPre,"pdf")}
              >
                PDF
              </button>
            </div>
          </div>

          <Table
            headData={table_header}
            body={tds_tcs_item_list?.map((v, i) => (
              <tr key={i}>
                <td>{v?.PAN}</td>
                <td>{moment(v?.Date).format('DD-MMM-YYYY')}</td>
                <td>{v?.Name}</td>
                <td>{v?.TotalValue}</td>
                <td>{v?.UNDER_SECTION }</td>
                <td>{v?.TDS_PERCENT}</td>
                <td>{v?.TDS_Value}</td>
              </tr>
            ))}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default TDS;
