import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import { useSelector } from "react-redux";
import PageLoading from "../../../components/PageLoading";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_staff_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import { DeleteIcon, Radio } from "../../../utils/utils";
import {
  get_arb_purchase_list,
  get_transition_complete_list,
  get_vendor_purchase_list,
} from "../trasactionsSlice";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { arb_purchase_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { ARBList, CVOList } from "../../OtherComponent/StaticLists/staticLists";
import NoData from "../../../components/NoData";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { DateValidator2 } from "../../../utils/validation";
import { DropInput } from "../../../components/inputs/DropInput";
import { Alerts } from "../../../components/Alerts/Alerts";
import { addItem } from "../../../utils/data";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";

const table_header = [
  "PRODUCT",
  "GST%",
  "UNIT RATE ",
  "QUANTITY",
  "TAXABLE AMOUNT",
  "TCS/TDS",
  "GST AMOUNT	",
  "AMOUNT",
  "TRUCK NUMBER",
  "DRIVER NAME",
  "RECIEVED BY",
  "ACTIONS",
];

const BANPurchases = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [arbClick, setArbClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open2data, setOpen2data] = useState("");
  const { user_id, user_turnover, eff_date, omc_data, today } = useSelector(
    (state) => state.other
  );
  const { day_end_date,home_data } = useSelector((state) => state.profile);

  const {
    arb_purchase_list,
    arb_purchase_loading,
    transition_complete_list,
    vendor_purchase_list,
  } = useSelector((state) => state.transactions);
  const { arb_list, staff_list, vehical_list } = useSelector(
    (state) => state.masterdata
  );

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_vehical_list());
    store.dispatch(get_vendor_purchase_list());
  }, []);

  const fetchData = () => {
    store.dispatch(
      get_arb_purchase_list({
        pageNo: pageNo,
        entries: entries,
        setTotalCount: setTotalCount,
        setSearchCount: setSearchCount,
        search: SerchInput,
      })
    );
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const onDelete_item = () => {
    setOpen(false);
    const data = arb_purchase_list?.filter((v) => v?.ID == id);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    // body.append("id", id);
    data?.[0]?.products?.forEach((product) => {
      if (product && product.ID) {
        body.append("id[]", product.ID);
      }
    });
    arb_purchase_api(body, setLoading).then((v) => {
      if (v.success) {
        setPageNo(arb_purchase_list?.length < 2 ? pageNo - 1 : pageNo);
        store.dispatch(
          get_arb_purchase_list({
            pageNo: arb_purchase_list?.length < 2 ? pageNo - 1 : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(v.message);
      } else {
        setMess(v.message);
        setOpen1(true);
      }
    });
  };
  const calculate = () => {
    if (!addDataHead.name) {
      setMess("Please select VENDOR NAME");
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) =>
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          !v.quantity ||
          (v.quantity && v.quantity.length == 0)
      )
    ) {
      setMess(`Please Enter UNIT RATE.
Please Enter QUANTITY.`);
      setOpen1(true);
      return;
    }
    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data?.unit_rate);
      const quantity = Number(data?.quantity);
      const p_gst = Number(data?.p_gst);

      const tax_amount = unit_rate * quantity;
      const gst_amount = tax_amount * (p_gst / 100);
      const tds_amt = addDataHead?.type == "NA" ? 0 : tax_amount * (0.1 / 100);
      const amount =
        addDataHead?.type == "TDS"
          ? gst_amount + tax_amount - tds_amt
          : addDataHead?.type == "TCS"
          ? gst_amount + tax_amount + tds_amt
          : gst_amount + tax_amount;

      setAddData((prevData) => {
        return prevData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              tax_amount: tax_amount.toFixed(2),
              gst_amount: gst_amount.toFixed(2),
              amount: amount.toFixed(2),
              tcs_tds: Number(tds_amt).toFixed(2),
            };
          }
          return item;
        });
      });
      total += amount;
    });
    setAddDataHead({ ...addDataHead, inv_amount: Number(total).toFixed(2) });
  };

  const validation_check = (type) => {
    const arr = [];
    if (addData?.length > 0 || type == "add") {
      addData.find((v) => {
        if (!v?.p_code || (v?.p_code && v?.p_code?.length == 0)) {
          arr.push("Please Select a Product");
        }
        if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length == 0)) {
          arr.push("Please Enter Unit Rate");
        } else if (
          addDataHead?.po_number &&
          addDataHead?.po_number?.length > 0 &&
          Number(v?.unit_rate) > Number(v?.actual_unit_rate)
        ) {
          arr.push(
            `${v?.prod_name} Price Could Not Be Greater Than PO Set Price`
          );
        }
        if (!v?.quantity || (v?.quantity && v?.quantity == 0)) {
          arr.push("Please Select Quantity");
        }
        if (
          !v?.tax_amount ||
          !v?.gst_amount ||
          !v?.amount ||
          !v?.tcs_tds ||
          (v?.tax_amount && v?.tax_amount?.length == 0) ||
          (v?.gst_amount && v?.gst_amount?.length == 0) ||
          (v?.amount && v?.amount?.length == 0) ||
          (v?.tcs_tds && v?.tcs_tds?.length == 0)
          // ||
          // !addDataHead?.inv_amount ||
          // (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
        ) {
          arr.push("Please Click on Calculate first");
        }
        if (
          !v?.truck_number ||
          (v?.truck_number && v?.truck_number?.length == 0)
        ) {
          arr.push("Please Enter Truck Number");
        }
        if (!v?.emp_code || (v?.emp_code && v?.emp_code?.length == 0)) {
          arr.push("Please Select Received By");
        }
      });
    } else {
      arr.push("Please add Product First");
    }
    return arr;
  };

  const validation_check1 = () => {
    const arr = [];
    if (
      !addDataHead?.name ||
      (addDataHead?.name && addDataHead?.name?.length == 0)
    ) {
      arr.push("Please Select Vendor");
    }
    if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && addDataHead?.ref_no?.trim()?.length == 0)
    ) {
      arr.push("Please Enter Invoice Number");
    } else if (addDataHead?.ref_no?.trim()?.length < 3) {
      arr.push("Invoice Number must contain atleast 3 Characters");
    }
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      arr.push("Please Enter Invoice Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      arr.push(
        ...DateValidator2(
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Invoice Date cant be before as Day end Date ${day_end_date}`,
          "Invoice Date cant be a future date"
        )
      );
    } else if (addDataHead?.po_number && addDataHead?.po_number?.length > 0) {
      const data = DateValidator2(
        moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
        moment(addDataHead?.po_valid_upto).format("DD/MM/YYYY"),
        addDataHead?.po_date_2,
        `Invoice Date cant be before as PO Date ${addDataHead?.po_date}`,
        "Your Purchase Order has been Expired"
      );
      if (data != true) {
        arr.push(...data);
      }
    }

    if (
      !addDataHead?.stock_date ||
      (addDataHead?.stock_date && addDataHead?.stock_date?.length == 0)
    ) {
      arr.push("Please Enter Stock Receipt Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.stock_date).format("DD/MM/YYYY"),
        today,
        moment(addDataHead?.inv_date).format("DD/MM/YYYY")
      ) != true &&
      addDataHead?.inv_date?.length == 10
    ) {
      arr.push(
        ...DateValidator2(
          moment(addDataHead?.stock_date).format("DD/MM/YYYY"),
          today,
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          "INVOICE DATE must be earlier than STOCK RECEIPT DATE.",
          "STOCK RECEIPT DATE cant be a future date."
        )
      );
    }
    if (
      !addDataHead?.inv_amount ||
      (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
    ) {
      arr.push("Please Click on Calculate first");
    }
    return arr;
  };

  const add_items = async (type) => {
    const Data = [...validation_check1(), ...validation_check()];
    const errorData = [...new Set(Data)];
    if (
      errorData?.length == 0 &&
      Number(home_data?.bank[0]?.ACC_CB) < Number(addDataHead.inv_amount) &&
      !type
    ) {
      setOpen3(true);
      return;
    } else {
      setOpen3(false);
    }
    if (errorData?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("venid", addDataHead?.name);
        body.append("ref_no", addDataHead?.ref_no);
        body.append("inv_date", addDataHead?.inv_date);
        body.append("recp_date", addDataHead?.stock_date);
        body.append("connection_type", addDataHead?.c_type);
        body.append("rev_charge", addDataHead?.rev_charge);
        body.append("podate", addDataHead?.po_date);
        body.append("po_number", addDataHead?.po_number);
        addData.forEach((v) => {
          body.append("procode[]", v?.p_code);
          body.append("gst[]", v?.p_gst);
          body.append("unit_rate[]", v?.unit_rate);
          body.append("quantity[]", v?.quantity);
          body.append("tax_amount[]", v?.tax_amount);
          body.append("amount[]", v?.amount);
          body.append("gst_amount[]", v?.gst_amount);
          body.append("truck_number[]", v?.truck_number);
          body.append("driver_name[]", v?.driver_name || "");
          body.append("received_by[]", v?.emp_code);
          body.append("tds[]", v?.tcs_tds);
        });

        arb_purchase_api(body, setLoading).then((res) => {
          if (res.success) {
            setIsAdd(false);
            store.dispatch(
              get_arb_purchase_list({
                pageNo: pageNo,
                entries: entries,
                setTotalCount: setTotalCount,
                setSearchCount: setSearchCount,
                search: SerchInput,
              })
            );
            setAddData([]);
            setAddDataHead({});
            toast(res.message);
          } else {
            setMess(res.message);
            setOpen1(true);
          }
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={errorData} />);
      setOpen1(true);
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addData.filter((item, i) => i !== deleteId);
    setAddData(filterData);
  };

  return (
    <div className="container">
      <PageHeading
        title={
          (omc_data == 1 && "NFR PURCHASE INVOICES") ||
          (omc_data == 2 && "ARB PURCHASE INVOICES") ||
          (omc_data == 3 && "BLPG PURCHASE INVOICES")
        }
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData([{ id: addData?.length + 1 }]);
                // setAddData((prevData) => [...prevData, { id: 1 }]);
                setAddDataHead({});
                setIsAdd(true);
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/O9aHvKV5yiQ",
                " https://youtu.be/SRyl_1Te5GY",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        setSearchInput={setSearchInput}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {arb_purchase_loading ? (
          <PageLoading />
        ) : arb_purchase_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {arb_purchase_list.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.cvo_name?.toUpperCase()}
                  </div>
                  <RowTxt title={"Invoice No."} value={v.INV_REF_NO} />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v.Invoice_date).format("DD-MMM-YYYY")}
                  />
                  {v.PO_NUMBER && v?.PO_DATE && (
                    <>
                      <RowTxt title={"PO No."} value={v?.PO_NUMBER} />
                      <RowTxt
                        title={"PO Date"}
                        value={moment(v?.PO_DATE).format("DD-MMM-YYYY")}
                      />
                    </>
                  )}
                  <RowTxt
                    title={"Reverse Charges"}
                    value={v.REVERSE_CHARGE == "1" ? "YES" : "NO"}
                  />

                  <RowTxt
                    title={"Stock Recieve Date"}
                    value={moment(v.stock_receive_date).format("DD-MMM-YYYY")}
                  />
                </div>
                <div className="df jcsb mt10">
                  {v?.STATUS_FLAG == "0" ? (
                    <DeleteIcon
                      onClick={() => {
                        setId(v.ID);
                        setOpen(true);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"ARB"}
                onClick={() => setArbClick(!arbClick)}
                back
              />
            </div>
          }
          title={
            (omc_data == 1 && "NFR PURCHASE INVOICES") ||
            (omc_data == 2 && "ARB PURCHASE INVOICES") ||
            (omc_data == 3 && "BLPG PURCHASE INVOICES")
          }
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"INV REF NO"}
                  placeholder={"INV REF NO"}
                  value={addDataHead.ref_no}
                  onChange={(e) => {
                    const input = e.target.value?.toUpperCase();
                    const invRegex = /^[A-Z\d!@#$%^&*-=+.,/?><;:'"]{0,100}$/;
                    if (invRegex.test(input)) {
                      setAddDataHead({
                        ...addDataHead,
                        ref_no: input,
                      });
                    }
                  }}
                />

                <Input
                  label={"INV DATE"}
                  type={"date"}
                  value={addDataHead?.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                      });
                    }
                  }}
                  // onBlur={() => {
                  //   if (addDataHead?.inv_date?.length == 10) {

                  //       setOpen1(true);
                  //       setAddDataHead({ ...addDataHead, inv_date: "" });
                  //     }
                  //   }
                  // }}
                />
                <Input
                  label={"STOCK RECEIPT DATE"}
                  type={"date"}
                  value={addDataHead?.stock_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        stock_date: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    if (addDataHead.stock_date?.length == 10) {
                      if (
                        DateValidator2(
                          moment(addDataHead?.stock_date).format("DD/MM/YYYY"),
                          today,
                          moment(addDataHead?.inv_date).format("DD/MM/YYYY")
                        ) != true
                      ) {
                        setMess(
                          ...DateValidator2(
                            moment(addDataHead?.stock_date).format(
                              "DD/MM/YYYY"
                            ),
                            today,
                            moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
                            "INVOICE DATE must be earlier than STOCK RECEIVED DATE.",
                            "STOCK RECEIVED DATE cant be a future date."
                          )
                        );
                        setOpen1(true);
                        setAddDataHead({ ...addDataHead, stock_date: "" });
                      }
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  options={[
                    <option value={""}>{"SELECT"}</option>,
                    ...(transition_complete_list?.Vendor || [])
                      .concat(transition_complete_list?.OMC || [])
                      .map((v, i) => (
                        <option value={v?.ID} key={i}>
                          {v?.CVO_CAT + "-" + v?.cvo_name}
                        </option>
                      )),
                  ]}
                  label={"VENDOR NAME"}
                  value={addDataHead?.name}
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                    ]?.filter((v) => v?.ID == e.target.value)[0];
                    setAddDataHead({
                      ...addDataHead,
                      name: e.target.value,
                      c_type: e.target.value == "" ? "" : data?.connection_type,
                      type:
                        user_turnover < 3 && data?.ANNUAL_TURNOVER == 0
                          ? "NA"
                          : user_turnover >= 3 && data?.ANNUAL_TURNOVER == 1
                          ? "TDS"
                          : user_turnover >= 3 && data?.ANNUAL_TURNOVER == 0
                          ? "TDS"
                          : user_turnover < 3 && data?.ANNUAL_TURNOVER == 1
                          ? "TCS"
                          : e.target.value != ""
                          ? "NA"
                          : "",
                      rev_charge:
                        (e.target.value != "" && data?.IS_GST_REG == "YES") ||
                        data?.CVO_CAT == "OMC"
                          ? 2
                          : e.target.value != "" && data?.IS_GST_REG == "NO"
                          ? 1
                          : "",
                      dis: e.target.value != "" && data ? true : false,
                      po_date: "",
                      po_number: "",
                      po_date_dis: false,
                      inv_amount: "",
                    });
                    setAddData([addItem]);
                  }}
                />
                <Input
                  label={"INV AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead.inv_amount}
                />
                <Dropdown
                  width2=""
                  label={"REVERSE CHARGE"}
                  value={addDataHead?.rev_charge}
                  disabled={addDataHead?.dis}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      rev_charge: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>YES</option>
                      <option value={"2"}>NO</option>
                    </>
                  }
                />
                <Dropdown
                  width2=""
                  label={"PO NUMBER"}
                  value={addDataHead?.po_number}
                  onChange={(e) => {
                    const data = vendor_purchase_list?.filter(
                      (v) => v?.PO_NUMBER == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      po_number: e.target.value,
                      po_date:
                        data?.length > 0
                          ? moment(data?.[0]?.PO_Date).format("YYYY-MM-DD")
                          : "",
                      po_date_2:
                        data?.length > 0
                          ? moment(data?.[0]?.PO_Date).format("DD/MM/YYYY")
                          : "",
                      po_date_dis: data?.length > 0 ? true : false,
                      po_valid_upto:
                        data?.length > 0 ? data?.[0]?.VALID_UPTO : "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {vendor_purchase_list
                        ?.filter((v) => v?.VENDOR == addDataHead?.name)
                        .map((v, i) => (
                          <option value={v?.PO_NUMBER} key={i}>
                            {v?.PO_NUMBER}
                          </option>
                        ))}
                    </>
                  }
                />
                <Input
                  label={"PO DATE"}
                  type={"date"}
                  // disabled={addDataHead?.po_date_dis }
                  disabled={true}
                  value={addDataHead?.po_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        po_date: e.target.value,
                      });
                    }
                  }}
                  // onBlur={() => {
                  //   if (addDataHead?.po_date?.length == 10) {
                  //     if (
                  //       DateValidator2(
                  //         moment(addDataHead?.po_date).format("DD/MM/YYYY"),
                  //         today,
                  //         eff_date,
                  //         `Effective Date cant be before ${eff_date} `,
                  //         `Effective Date cant be a future date`
                  //       ) != true
                  //     ) {
                  //       setMess(
                  //         ...DateValidator2(
                  //           moment(addDataHead?.po_date).format("DD/MM/YYYY"),
                  //           today,
                  //           eff_date,
                  //           `Effective Date cant be before ${eff_date} `,
                  //           `Effective Date cant be a future date`
                  //         )
                  //       );
                  //       setOpen1(true);
                  //       setAddDataHead({ ...addDataHead, po_date: "" });
                  //     }
                  //   }
                  // }}
                />
              </div>
              <div className="flex aic gap10 mt10">
                <RadioBtn label={"PURCHASE TYPE"} />
                <Radio
                  disabled={addDataHead?.dis}
                  title={"LOCAL PURCHASE"}
                  show={addDataHead.c_type == "lp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "lp" })
                  }
                />
                <Radio
                  disabled={addDataHead?.dis}
                  title={"INTER-STATE PURCHASE"}
                  show={addDataHead.c_type == "isp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "isp" })
                  }
                />
              </div>
              {/* <div className="flex aic gap10 credit-limit"></div> */}
              <div
                className="flex aic gap10"
                style={{
                  width: "600px",
                  flexWrap: "wrap",
                  rowGap: "0",
                  margin: "1rem 0",
                }}
              >
                <RadioBtn label={"TDS/TCS"} />
                <Radio
                  title={"TDS"}
                  show={addDataHead?.type == "TDS" ? "TDS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TDS");
                  }}
                />
                <Radio
                  title={"TCS"}
                  show={addDataHead?.type == "TCS" ? "TCS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TCS");
                  }}
                />
                <Radio
                  title={"NA"}
                  show={addDataHead?.type == "NA" ? "NA" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("NA");
                  }}
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={table_header}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            options={
                              addDataHead?.name?.length > 0 &&
                              vendor_purchase_list
                                ?.filter((v) => v?.VENDOR == addDataHead?.name)
                                ?.filter(
                                  (s) => s.PO_NUMBER == addDataHead?.po_number
                                )?.length > 0 ? (
                                <>
                                  <option value={""}>{"SELECT"}</option>
                                  {vendor_purchase_list
                                    ?.filter(
                                      (v) => v?.VENDOR == addDataHead?.name
                                    )
                                    ?.filter(
                                      (s) =>
                                        s.PO_NUMBER == addDataHead?.po_number
                                    )?.[0]
                                    ?.products?.map((v, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (s) => s?.p_code == v?.PROD_CODE
                                          )?.length > 0
                                        }
                                        key={i}
                                        value={v?.PROD_CODE}
                                      >
                                        {(() => {
                                          const data = arb_list?.filter(
                                            (d) => d?.ID == v?.PROD_CODE
                                          );
                                          if (data?.length > 0) {
                                            return `${data[0]?.category_description} - ${data[0]?.PROD_BRAND} - ${data[0]?.PROD_NAME}`;
                                          } else {
                                            return "";
                                          }
                                        })()}
                                      </option>
                                    ))}
                                </>
                              ) : (
                                [<option value={""}>{"SELECT"}</option>].concat(
                                  arb_list?.map((val, i) => (
                                    <>
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (s) => s?.p_code == val?.ID
                                          )?.length > 0
                                        }
                                        key={i}
                                        value={val?.ID}
                                      >
                                        {val?.category_description +
                                          " - " +
                                          val?.PROD_BRAND +
                                          " - " +
                                          val?.PROD_NAME}
                                      </option>
                                    </>
                                  ))
                                )
                              )
                            }
                            onChange={(e) => {
                              const data = arb_list?.filter(
                                (v) => v?.ID == e.target.value
                              );
                              const data1 = vendor_purchase_list
                                ?.filter((v) => v?.VENDOR == addDataHead?.name)
                                ?.filter(
                                  (s) => s.PO_NUMBER == addDataHead?.po_number
                                )?.[0]
                                ?.products?.filter(
                                  (valu) => valu?.PROD_CODE == e.target.value
                                );

                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        p_code: e.target.value,
                                        p_gst: data[0]?.GSTP || "",
                                        unit_rate:
                                          data1?.length > 0
                                            ? data1[0]?.UNIT_RATE
                                            : "",
                                        actual_unit_rate:
                                          data1?.length > 0
                                            ? data1[0]?.UNIT_RATE
                                            : "",
                                        prod_name:
                                          data1?.length > 0
                                            ? `${data?.[0]?.category_description} - ${data?.[0]?.PROD_BRAND} - ${data?.[0]?.PROD_NAME}`
                                            : "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val?.p_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.p_gst}
                            placeholder={"GST%"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"UNIT RATE"}
                            value={val?.unit_rate}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.unit_rate
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          unit_rate:
                                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tax_amount: "",
                                          gst_amount: "",
                                          amount: "",
                                          tcs_tds: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"QUANTITY"}
                            value={val?.quantity}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length < 5 &&
                                  /^\d+$/.test(inputValue)) ||
                                inputValue == 0 ||
                                !val?.quantity
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          quantity: /^\d+$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          tax_amount: "",
                                          gst_amount: "",
                                          amount: "",
                                          tcs_tds: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TAXABLE AMOUNT"}
                            value={val?.tax_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TCS/TDS"}
                            value={val?.tcs_tds}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"GST AMOUNT"}
                            value={val?.gst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"AMOUNT"}
                            value={val?.amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        <DropInput
                          placeholder={"TRUCK NUMBER"}
                          value={val?.truck_number}
                          id={"truck"}
                          onChange={(event) => {
                            const input = event.target.value.toUpperCase();
                            if (
                              /^[A-Z]{0,2}\d{0,2}[A-Z]{0,2}\d{0,4}$/.test(
                                input
                              ) ||
                              /^[0-9]{0,4}$/.test(input) ||
                              input === ""
                            ) {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, truck_number: input }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          list={vehical_list?.map((v, i) => (
                            <option key={i} value={v?.VEHICLE_NO}>
                              {v?.VEHICLE_NO}
                            </option>
                          ))}
                        />
                      </td>
                      <td>
                        <DropInput
                          id={"driver"}
                          placeholder={"DRIVER NAME"}
                          value={val?.driver_name}
                          onChange={(event) => {
                            const input = event.target.value;
                            if (/^[a-zA-Z0-9 ]*$/.test(input) || input === "") {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, driver_name: input }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          list={staff_list?.map((v, i) => (
                            <option key={i} value={v?.EMP_NAME}>
                              {v?.EMP_NAME}
                            </option>
                          ))}
                        />
                      </td>{" "}
                      <td>
                        {
                          <Dropdown
                            options={[
                              <option value={""}>{"SELECT"}</option>,
                            ].concat(
                              staff_list?.map((v, i) => (
                                <option key={i} value={v?.ID}>
                                  {v?.EMP_NAME}
                                </option>
                              ))
                            )}
                            value={val?.emp_code}
                            onChange={(event) => {
                              const input = event.target.value.toUpperCase();
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, emp_code: input }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              // const data = addData?.filter(
                              //   (v, i) => v?.id != val?.id
                              // );
                              // // setAddData((pre) =>
                              // //   pre.filter((v, i) => i != ind)
                              // // );
                              // setAddData([...data]);
                              // // fetchData();
                              handleAddDelete(ind);
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    const data = validation_check("add");
                    if (addData?.length <= 3) {
                      data?.length > 0 && setMess(<Alerts data={data} />);
                      data?.length > 0
                        ? setOpen1(true)
                        : setAddData((pre) => [...pre, {}]);
                    } else {
                      setOpen1(true);
                      setMess("you can add only 4 product");
                    }
                  }}
                />
                {/* <Button
                  title={"FETCH GST"}
                  back
                  onClick={() => {
                    !addData[addData.length - 1]?.p_code &&
                      setMess("Please Select PRODUCT and Click FETCH GST");
                    !addData[addData.length - 1]?.p_code
                      ? setOpen1(true)
                      : setAddData((pre) =>
                          pre.map((v, i) =>
                            i == addData.length - 1
                              ? {
                                  ...v,
                                  p_gst: arb_list.filter(
                                    (val, ind) =>
                                      val.ID ==
                                      addData[addData.length - 1]?.p_code
                                  )[0]?.GSTP,
                                }
                              : { ...v }
                          )
                        );
                  }}
                /> */}
                <Button
                  title={"CALCULATE"}
                  back
                  disable={
                    !addData[addData.length - 1]?.p_code ||
                    !addData[addData.length - 1].p_gst ||
                    !addData[addData.length - 1].unit_rate ||
                    !addData[addData.length - 1].quantity
                      ? true
                      : false
                  }
                  onClick={() =>
                    !addData[addData.length - 1]?.p_code ||
                    !addData[addData.length - 1]?.p_gst ||
                    !addData[addData.length - 1].unit_rate ||
                    !addData[addData.length - 1].quantity
                      ? ""
                      : calculate()
                  }
                />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are You Sure you want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}

      {arbClick && <ARBList arbClick={arbClick} setArbClick={setArbClick} />}

      {open2 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={"Are You Sure you want to Change financial Status ?"}
          // onClick={() => onDelete_item()}
          bname={"SUBMIT"}
          onClick={() => {
            setAddDataHead({ ...addDataHead, type: open2data });
            setOpen2(false);
            setOpen2data("");
            addData.forEach((data, index) => {
              setAddData((prevData) => {
                return prevData.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      tax_amount: "",
                      gst_amount: "",
                      amount: "",
                      tcs_tds: "",
                    };
                  }
                  return item;
                });
              });
            });
          }}
        />
      )}

      {openView && (
        <Modal
          isOpen={openView}
          title={viewItem?.cvo_name?.toUpperCase()}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "TRUCK NUMBER",
                  "DRIVER NAME",
                  "RECIEVED BY",
                  "GST%",
                  "UNIT PRICE	",
                  "QUANTITY",
                  "TAXABLE AMOUNT	",
                  "TDS/TCS",
                  "IGST AMOUNT",
                  "SGST AMOUNT",
                  "CGST AMOUNT",
                  "TOTAL AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>
                          {`${v?.CAT_DESC} - ${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                        </td>

                        <td>{v?.TRUCK_NUMBER}</td>
                        <td>{v?.DRIVER_NAME}</td>
                        <td>{v?.EMP_NAME}</td>
                        <td>{v?.GST}</td>
                        <td>{Number(v?.UNIT_PRICE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{Number(v?.BASIC_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.TDS).toFixed(2)}</td>
                        <td>
                          {Number(v?.IGST_AMOUNT.replace(/,/g, "")).toFixed(2)}
                        </td>
                        <td>
                          {Number(v?.SGST_AMOUNT.replace(/,/g, "")).toFixed(2)}
                        </td>
                        <td>
                          {Number(v?.CGST_AMOUNT.replace(/,/g, "")).toFixed(2)}
                        </td>
                        <td>{v?.C_AMOUNT}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.BASIC_AMOUNT.replace(/,/g, ""))
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.TDS)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT.replace(/,/g, ""))
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT.replace(/,/g, ""))
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT.replace(/,/g, ""))
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {Number(viewItem?.TOTAL_AMOUNT)?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

{open3 && (
        <SmallModal
          bname={"SUBMIT"}
          title={"Confirm"}
          isOpen={open3}
          setIsOpen={setOpen3}
          mess={
            "YOUR LOAD ACCOUNT HAVE NO SUFFICIENT BALANCE TO COMPLETE THIS TRANSACTION. DO YOU WANT TO CONTINUE?"
          }
          onClick={() => {
            add_items(true);
          }}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default BANPurchases;
