import { toast } from "react-toastify";
import store from "../redux/store";
import {
  get_arb_list,
  get_areacode_master_list,
  get_equipment_list,
} from "../screens/MasterData/masterdataSlice";
import { base_url, userId } from "./utils";

export const equipments_action = async (body, setLoading) => {
  try {
    setLoading(true);

    const responce = await fetch(base_url + "equipment_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);

    return res;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const arb_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "arb_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    if (res.success) {
      store.dispatch(get_arb_list());
      alert(res.message);
    } else {
      alert(res.message);
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const area_code_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "area_code_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    if (res.success) {
      // store.dispatch(get_areacode_master_list());
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const fetch_gst = async (code) => {
  try {
    const body = new FormData();
    body.append("action", "fetchgst");
    body.append("agencyid", userId);
    body.append("pcode", code);

    const responce = await fetch(base_url + "cylinder.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res.GSTP;
    } else {
      alert(res.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const cylinder_purchase_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "cylinder.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const arb_purchase_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "arb_purchase.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const vendor_purchase_api = async (body) => {
  try {
    const responce = await fetch(base_url + "vendor_po.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const quotation_sales_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "quotations_sales.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const hsncode_api = async (code, type = "hsn") => {
  try {
    const body = new FormData();
    body.append("code", code);
    body.append("type", type);
    const responce = await fetch(base_url + "hsncode.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

// API Intergration by Naveen Patidar

export const master_data_api = async (body) => {
  try {
    const responce = await fetch(base_url + "masterdata_completelist_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};
export const delivery_challan_api = async (data) => {
  try {
    const response = await fetch(base_url + "Delivery_Challans.php", {
      method: "POST",
      body: data,
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const sales_return_api = async (data) => {
  try {
    const response = await fetch(base_url + "sales_return.php", {
      method: "POST",
      body: data,
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const Equipment_master_API = `${base_url}equipment_master_api.php`;
export const ARB_master_API = `${base_url}arb_api.php`;

export const createDBCAPI = `${base_url}dbc_api.php`;
export const priceRefillAPI = `${base_url}refill_price_api.php`;
export const BLPGPriceAPI = `${base_url}blpg_price_api.php`;

export const pinverifyAPI = `${base_url}profile.php`;

export const cylinderAPI = `${base_url}cylinder.php`;
export const custVendMasterAPI = `${base_url}vend_master_api.php`;
export const vehicalMasterAPI = `${base_url}vechical_master.php`;
export const staffMasterAPI = `${base_url}staff_master.php`;

// IS FTL APIs

export const is_ftl_api = async (body) => {
  try {
    const responce = await fetch(base_url + "firstlogin.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Status Update API

export const service_master_api = async (body) => {
  try {
    const responce = await fetch(base_url + "service_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const service_master_api_second = async (body) => {
  try {
    const responce = await fetch(base_url + "services.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Status Update API

export const agency_api = async (body) => {
  try {
    const responce = await fetch(base_url + "agency.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Email Update

export const email_update_api = async (body) => {
  try {
    const responce = await fetch(base_url + "email_update.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Profile Invoice

export const profile_update_api = async (body) => {
  try {
    const responce = await fetch(base_url + "profile.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// PINCODE verify

export const pincode_verify_api = async (body) => {
  try {
    const responce = await fetch(base_url + "pincode_verify.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Forgot Pin email Send API

export const forgot_pin_email_api = async (body) => {
  try {
    const responce = await fetch(base_url + "security_pin_forgot.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Forgot Password email Send API

export const forgot_password_email_api = async (body) => {
  try {
    const responce = await fetch(base_url + "forgot_pwd.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const vehical_master_api = async (body) => {
  try {
    const responce = await fetch(base_url + "vechical_master.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const staff_master_api = async (body) => {
  try {
    const responce = await fetch(base_url + "staff_master.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const bank_master_API = async (body) => {
  try {
    const responce = await fetch(base_url + "bank_master.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      toast.error("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const stock_adjustment_API = async (body) => {
  try {
    const responce = await fetch(base_url + "stock_adjustment.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const distrct_data_api = async (body) => {
  try {
    const responce = await fetch(base_url + "completelist.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const distrct_data_api2 = async (id) => {
  try {
    const body = new FormData();
    body.append("stateid", id);
    const responce = await fetch(base_url + "completelist.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res.data.reponselist.districts;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const GST_data_api = async (body) => {
  try {
    const responce = await fetch(base_url + "gstfetch.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (!res.error) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const cust_vend_master_api = async (body) => {
  try {
    const responce = await fetch(base_url + "vend_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const set_credit_limit_API = async (body) => {
  try {
    const responce = await fetch(base_url + "credit_limit.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const defective_pur_return_API = async (body) => {
  try {
    const responce = await fetch(base_url + "defective_purchase.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const refill_price_api = async (body) => {
  try {
    const responce = await fetch(base_url + "refill_price_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const purchase_order_sales_API = async (body) => {
  try {
    const responce = await fetch(base_url + "purcahse_sale.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const other_purchase_sales_APi = async (body) => {
  try {
    const responce = await fetch(base_url + "other.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const domestic_cylinder_sales_API = async (body) => {
  try {
    const responce = await fetch(base_url + "domestic_sale.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const domestic_cylinder_sales_API_2 = async (body) => {
  try {
    const response = await fetch(base_url + "DOMSALES/API.php", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

// export const domestic_cylinder_sales_API_2 = async (body) => {
//   try {
//     const response = await fetch(base_url + "DOMSALES/API.php", {
//       method: "POST",
//       body: JSON.stringify(body),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const commercial_cylinder_sales_API = async (body) => {
  try {
    const responce = await fetch(base_url + "commercial_sales.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const commercial_cylinder_sales_API_2 = async (body) => {
  try {
    const response = await fetch(base_url + "DOMSALES/COMAPI.php", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

// ARB Sales API

export const arb_sales_api = async (body) => {
  try {
    const responce = await fetch(base_url + "blpg_sales.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// NC DBC Data

export const nc_dbc_data_API = async (body) => {
  try {
    const responce = await fetch(base_url + "ncdata_api.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// ITV RC Data

export const itv_rc_data_API = async (body) => {
  try {
    const responce = await fetch(base_url + "itv_rc.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// OTV TTV Data

export const otv_ttv_data_API = async (body) => {
  try {
    const responce = await fetch(base_url + "otv_ttv_tv.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Cash and Bank

// Reciept API

export const reciept_API = async (body) => {
  try {
    const responce = await fetch(base_url + "cash_bank_receipts.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Payment API

export const payment_API = async (body) => {
  try {
    const responce = await fetch(base_url + "payment.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Bank Transaction Note API

export const bank_transaction_API = async (body) => {
  try {
    const responce = await fetch(base_url + "Bank_transaction.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Credit Note API

export const credit_note_API = async (body) => {
  try {
    const responce = await fetch(base_url + "credit_notes.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Debit Note API

export const debit_note_API = async (body) => {
  try {
    const responce = await fetch(base_url + "debit_notes.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Cheque List API

export const cheque_list_API = async (body) => {
  try {
    const responce = await fetch(base_url + "cheque_clearance.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};


// transaction report

// Purchase Report

export const purchase_report_api = async (body) => {
  try {
    const responce = await fetch(base_url + "reports/purchase_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Sales Report

export const sales_report_api = async (body) => {
  try {
    const responce = await fetch(base_url + "reports/sales_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// NC DBC Reports

export const nc_dbc_report_api = async (body) => {
  try {
    const responce = await fetch(base_url + "reports/ncdbc_rctv_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Stock Reports

export const stock_report_api = async (body) => {
  try {
    const responce = await fetch(base_url + "reports/stock_report.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// RECEIVABLES Reports

export const receivables_report_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/agency_receivables_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Payables Reports

export const payables_report_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/agency_payables_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Bank  Reports

export const bank_report_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/agency_payables_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const bank_report_api2 = async (body) => {
  try {
    console.log(body);
    const response = await fetch(base_url + "reports/bank_reports.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log(res);

    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// Ledger Reports

export const ledger_report_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/agency_payables_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const ledger_report_api2 = async (body) => {
  try {
    const response = await fetch(base_url + "reports/ledger_reports.php", {
      method: "POST",
      body: body,
    });

    const res = response.json();

    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

//  Payments and Reciepts Reports

export const payment_and_reciepts_report_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/payments_receipts_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const staffMasterAPI2 = async (body) => {
  try {
    const response = await fetch(`${base_url}DOMSALES/staffData.php`, {
      method: "POST",
      body: JSON.stringify(body),
    });

    const resJson = await response.json();
    return resJson;
  } catch (error) {
    console.log(error.message);
  }
};

export const handleLoansApi = async (body) => {
  try {
    const response = await fetch(`${base_url}DOMSALES/staffLoan.php`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.log("Working Done");
    }

    const resJson = await response.json();
    return resJson;
  } catch (error) {
    console.log(error.message);
  }
};

export const handlePayRunsApi = async (body) => {
  try {
    const response = await fetch(`${base_url}DOMSALES/PayRuns.php`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log("Working Done ");
    }
    const responseText = await response.text();
    const cleanedResponse = responseText.replace(/\r?\n|\r/g, "");
    const jsonResponse = cleanedResponse.substring(
      cleanedResponse.indexOf("{")
    );
    const resJson = await JSON.parse(jsonResponse);
    return resJson;
  } catch (error) {
    console.log(error.message);
  }
};

// const handleReports
// Payables Reports


// GSTR Reports APIs

// GSTR 1 Report 

export const gstr_1_API = async (body) => {
  try {
    const responce = await fetch(
      base_url + "gst1Report.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

// GSTR3 Report 

export const gstr_3_API = async (body) => {
  try {
    const responce = await fetch(
      base_url + "gst3Report.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};



// GSTR3 Report 

export const TDS_TCS_API = async (body) => {
  try {
    const responce = await fetch(
      base_url + "TDS.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};



// Day End Date APIs


export const day_end_date_api = async (body) => {
  try {
    const responce = await fetch(
      base_url + "reports/day_end_reports.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    if (res.success) {
      return res;
    } else if (!res.success) {
      return res;
    } else {
      alert("something wrong");
    }
  } catch (error) {
    console.log(error);
  }
};


